<!--<pre>{{ tax | json }}</pre>-->
<!--{{ typeStyle  | json }}-&#45;&#45;-->
<!--{{ hasOnlySubtotal}}-->
<!--{{ totalShippingSelected  | json }}-&#45;&#45;-->
<!--{{ giftAmount  | json }}-&#45;&#45;-->
<!--{{ sh  | json }}-->
@switch (typeStyle) {
    @case (2) {
        <div class="tw-tax-container tw-flex tw-row-reverse" style="font-size: 14px;padding: 20px 0">
            <div class="tw-column" style="width: 315px">
                @if (tax.subtotal > 0) {
                    <div class="tw-flex tw-row margin-20">
                        <div class="tw-tax-text">
                            {{ 'checkout.subtotal' | translate }}
                        </div>
                        <div class="tw-tax-number">
                            {{ (tax.subtotal) / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (tax.serviceCharge) {
                    <div class="tw-flex tw-row margin-20">
                        <div class="tw-tax-text">
                            {{ 'title.serviceCharge' | translate }}
                        </div>
                        <div class="tw-tax-number">
                            {{ tax.serviceCharge / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (tax.tax1 > 0) {
                    <div class="tw-flex tw-row margin-20">
                        <div class="tw-tax-text">
                            {{ tax.descriptionTax1 }}
                        </div>
                        <div class="tw-tax-number">
                            {{ tax.tax1 / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (tax.tax2 > 0) {
                    <div class="tw-flex tw-row margin-20">
                        <div class="tw-tax-text">
                            {{ tax.descriptionTax2 }}
                        </div>
                        <div class="tw-tax-number">
                            {{ tax.tax2 / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (tax.tax3 > 0) {
                    <div class="tw-flex tw-row margin-20">
                        <div class="tw-tax-text">
                            {{ tax.descriptionTax3 }}
                        </div>
                        <div class="tw-tax-number">
                            {{ tax.tax3 / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (tax.subtotal !== tax.totalAfterTaxes && tax.totalAfterTaxes >= 0) {
                    <div class="tw-flex tw-row margin-20">
                        <div class="tw-tax-text">
                            Total
                        </div>
                        <div class="tw-tax-number">
                            {{ tax.totalAfterTaxes / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (totalShippingSelected > 0) {
                    <div class="tw-flex tw-row margin-20">
                        <div class="tw-tax-text">
                            {{ 'shipping.shipping' | translate }}
                        </div>
                        <div class="tw-tax-number">
                            {{ totalShippingSelected / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (totalShippingSelected) {
                    <div class="tw-flex tw-row margin-20">
                        <div class="tw-tax-text">
                            Total
                        </div>
                        <div class="tw-tax-number">
                            {{ (tax.totalAfterTaxes + totalShippingSelected) / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
            </div>
        </div>
    }
    @case (3) {
        <div class=" tw-tax-container tw-flex tw-row-reverse">
            <div class="tw-column" style="width: 300px">
                <div class="tw-flex tw-row">
                    <div class="tw-tax-text">
                        Total
                    </div>
                    <div style="font-weight: bold;font-size: larger" class="tw-tax-number">
                        {{ (total) / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                    </div>
                </div>
            </div>
        </div>


    }
    @default {
        <div class=" tw-tax-container tw-flex tw-row-reverse">
            <div class="tw-column" style="width: 300px">
                @if (tax.subtotal > 0) {
                    <div class="tw-flex tw-row">
                        <div class="tw-tax-text">
                            {{ 'checkout.subtotal' | translate }}
                        </div>
                        <div class="tw-tax-number">
                            {{ (tax.subtotal) / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (tax.serviceCharge) {
                    <div class="tw-flex tw-row">
                        <div class="tw-tax-text">
                            {{ 'title.serviceCharge' | translate }}
                        </div>
                        <div class="tw-tax-number">
                            {{ tax.serviceCharge / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (tax.tax1 > 0) {
                    <div class="tw-flex tw-row">
                        <div class="tw-tax-text">
                            {{ tax.descriptionTax1 }}
                        </div>
                        <div class="tw-tax-number">
                            {{ tax.tax1 / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (tax.tax2 > 0) {
                    <div class="tw-flex tw-row">
                        <div class="tw-tax-text">
                            {{ tax.descriptionTax2 }}
                        </div>
                        <div class="tw-tax-number">
                            {{ tax.tax2 / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (tax.tax3 > 0) {
                    <div class="tw-flex tw-row">
                        <div class="tw-tax-text">
                            {{ tax.descriptionTax3 }}
                        </div>
                        <div class="tw-tax-number">
                            {{ tax.tax3 / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (tax.subtotal !== tax.totalAfterTaxes) {
                    <hr>
                }
                <!--                {{(tax.subtotal !== tax.totalAfterTaxes)}}-->
                @if ((hasShipping || (tax.subtotal !== tax.totalAfterTaxes)) && tax.totalAfterTaxes >= 0) {
                    <div class="tw-flex tw-row">
                        <div class="tw-tax-text">
                            Total
                        </div>

                        <div class="tw-tax-number" [ngClass]="hasShipping ? '' : 'tw-tax-container-total'">
                            {{ tax.totalAfterTaxes / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (hasShipping) {
                    <div class="tw-flex tw-row">
                        <div class="tw-tax-text">
                            {{ 'shipping.shipping' | translate }}
                        </div>
                        <div class="tw-tax-number">
                            {{ totalShippingSelected / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (giftAmount) {

                    <div class="tw-flex tw-row">
                        <div class="tw-tax-text">
                            {{ 'title.giftCertificate' | translate }}
                        </div>
                        <div class="tw-tax-number" style="color: green">
                            - {{ giftAmount / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
                @if (hasShipping) {
                    <hr>
                }
                @if (hasShipping) {
                    <div class="tw-flex tw-row">
                        <div class="tw-tax-text">
                            Total
                        </div>
                        <div style="font-weight: bold;font-size: larger" class="tw-tax-number" >
                            {{ (total) / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                        </div>
                    </div>
                }
            </div>
        </div>
    }
}




