import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SubdomainService} from '../../shared/services/subdomain.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Page, Pages} from '../../models/page';
import {catchError, map} from 'rxjs/operators';
import {HelpsService} from '../../shared/services/helps.service';
import {PerformanceResponseLight} from '../../models/performance-response-light';
import {ByocPricesResponse} from '../../models/byoc-prices-response';
import {ByocPriceRequest} from '../../models/byoc-price-request';
import {PriceWeb} from '../../../../projects/shared-lib/src/lib/models/price-web';
import {Parking} from '../../../../projects/shared-lib/src/lib/models/parking';
import {DatePipe} from '@angular/common';
import {SchemaSeo} from '../../../../projects/shared-lib/src/lib/models/schemaSeo';
import {Cart} from '../../../../projects/shared-lib/src/lib/models/cart';
import {CartPrice} from '../../../../projects/shared-lib/src/lib/models/cart-price';
import { WebsiteSaleService } from '../../shared/services/website-sale.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class PageSaleService {
    page: Page;
    dialog = new BehaviorSubject(null);
    schemas: any[] = [];

    constructor(private http: HttpClient,
                private subdomainService: SubdomainService,
                private helpsService: HelpsService, private datePipe: DatePipe,
                private websiteSaleService: WebsiteSaleService,
                private router: Router,
                public translate: TranslateService,
                ) {
    }

    getPage(id: string | number, httpParams ?: HttpParams,sameNbTicketForAllItems=false): Observable<Page> {
        //console.log(httpParams);
        return this.http.get<Page>('v1/pages/' + id, {params: httpParams})
            .pipe(
                map((pa: Page) => {
                    pa.imageUrlBig = this.helpsService.formatUrlImage(pa.imageUrlBig);
                    pa.imageUrlSmall = this.helpsService.formatUrlImage(pa.imageUrlSmall);
                    pa.sameNbTicketForAllItems = false;
                    pa.calendar_only = false;
                    pa.isDisplayDate = false;
                    if (pa.display === 'NONE') {
                        //do nothing
                    }
                    if (pa.display === 'CALENDAR_ONLY') {
                        pa.calendar_only = true;
                    }
                    if (pa.display === 'CALENDAR_ONLY' || pa.display === 'DATES') {
                        pa.isDisplayDate = true;
                    }
                    if (pa.display === 'ITEMS') {
                        if (pa.subscriptions && pa.subscriptions.length > 1 && this.websiteSaleService.getLocalConfig().accountId===1367995602 ) {
                            pa.sameNbTicketForAllItems = true;
                        }
                        pa.sameNbTicketForAllItems = sameNbTicketForAllItems;
                    }


                    if (pa.combos && pa.combos.length > 0) {
                        pa?.combos[0]?.mandatoryGroups.forEach(value => {
                            value?.details?.forEach(per => {
                                if (per?.itemHours) {
                                    per.itemHour = per.itemHours;
                                }
                            });
                        });
                        pa?.combos[0]?.optionalGroups.forEach(value => {
                            value?.details?.forEach(per => {
                                if (per?.itemHours) {
                                    per.itemHour = per.itemHours;
                                }
                            });
                        });
                        pa?.combos[0]?.pseudoMandatoryGroups.forEach(value => {
                            value?.details?.forEach(per => {
                                if (per?.itemHours) {
                                    per.itemHour = per.itemHours;
                                }
                            });
                        });
                    }
                    this.page = pa;
                    return pa;
                })
            );
    }

    getPages(httpParams = new HttpParams()): Observable<Pages> {

        httpParams = httpParams.set('subdomain', this.subdomainService.getSubdomain());
        const option = {params: httpParams};
        return this.http.get<Pages>('v1/pages', option)
            .pipe(
                map(p => {
                    p.pages.forEach(
                        pa => {
                            pa.imageUrlBig = this.helpsService.formatUrlImage(pa.imageUrlBig);
                            pa.imageUrlSmall = this.helpsService.formatUrlImage(pa.imageUrlSmall);
                            return pa;
                        }
                    );
                    return p;
                })
                // ,
                // catchError(err => {
                //     console.log(err)
                //     //this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: 'page.notAccessible', translate: true });
                //     return of(<Pages>{});
                // })
            );
    }

    getEventPrice(pageId: number, byocId: number, eventIds: any[]): Observable<Map<number, PriceWeb[]>> {

        return this.http.post<Map<number, PriceWeb[]>>(`v1/pages/${pageId}/byoc/${byocId}/events/price`, {
            eventIds: eventIds
        }).pipe(
            map(p => {

                return p;
            })
        );
    }

    getPriceUpdate(pageId: number | string, byocId: number, byocPriceRequest: ByocPriceRequest): Observable<ByocPricesResponse> {

        return this.http.post<ByocPricesResponse>(`v1/pages/${pageId}/byoc/${byocId}/prices`, byocPriceRequest)
            .pipe(
                map(p => {

                    return p;
                })
            );
    }

    getParkingByListEvents(eventIds: any[] = []): Observable<Parking[]> {
        let httpParams = new HttpParams().set('eventIds', eventIds.join(','));
        httpParams = httpParams.set('cache', '600000');
        return this.http.get<Parking[]>(`v1/parking`, {params: httpParams});
    }

    getUrlPageSuggestion(_links, prefId, cart: Cart, displayPage: any | 'ITEMS' | 'DATES' | 'PRICES', prices?: CartPrice[] | PriceWeb[]) {
        let url = null;
        if (!_links?.suggestion) {
            return url;
        }
        let suggestions: any[] = [];
        if (!Array.isArray(_links.suggestion)) {
            suggestions.push(_links.suggestion);
        } else {
            suggestions = _links.suggestion;
        }
        suggestions.forEach((val) => {
            const params = this.getUrlParams(val.href);

            if (params['eventIds']) {
                const eventIds: string[] = params['eventIds'].split(',');
                let findElement;
                switch (displayPage) {
                    case 'ITEMS':
                        findElement = cart?.cartElements.find(
                            (value) =>
                                String(value.id) ===
                                eventIds.find((value1) => value1 === String(value.id))
                        );
                        break;
                    case 'DATES':
                        if (prefId) {
                            findElement = eventIds.find((value1) => value1 === String(prefId));
                        }
                        break;
                    case 'PRICES':
                        if (prices) {
                            findElement = prices.find(
                                (price) =>
                                    String(price.eventId) ===
                                    eventIds.find((value1) => value1 === String(price.eventId))
                            );

                        }
                        break;
                }
                if (findElement) {
                    url = val.href;
                }
            } else {
                url = suggestions[0].href;
            }
        });
        const params = this.getUrlParams(url);
        if (params['notEventIds']) {
            const notEventIds: string[] = params['notEventIds'].split(',');
            let findElement;
            switch (displayPage) {
                case 'ITEMS':
                    findElement = cart?.cartElements.find(
                        (value) =>
                            String(value.id) ===
                            notEventIds.find((value1) => value1 === String(value.id))
                    );
                    break;
                case 'DATES':
                    if (prefId) {
                        findElement = notEventIds.find((value1) => value1 === String(prefId));
                    }
                    break;
                case 'PRICES':
                    if (prices) {
                        findElement = prices.find(
                            (price) =>
                                String(price.eventId) ===
                                notEventIds.find((value1) => value1 === String(price.eventId))
                        );

                    }
                    break;
            }
            if (findElement) {
                return null;
            }
        }
        return url;
    }

    getUrlParams(url) {
        const params = {};
        if (!url) {
            return params;
        }
        if (url.indexOf('?') !== -1) {
            url = url?.split('?').map((s) => s.trim())[1];
        }
        url.split('&').filter(str => str.length > 0).forEach(paramString => {
            const equalSignIndex = paramString.indexOf('=');
            params[paramString.substring(0, equalSignIndex)] = paramString.substring(equalSignIndex + 1);
        });
        return params;
    }

    initSchema(value: PerformanceResponseLight): any {
        if (this.page.shows.length > 20) {
            return;
        }
        let offers: any[] = [];
        let eventImage: String[] = [];
        try {
            value = JSON.parse(JSON.stringify(value));
            //console.log('value:', value);
            if (value?.images !== null) {
                value?.images?.forEach(img => {
                    eventImage.push(img.url);
                });
            }
            value.itemHours.forEach(ith => {
                ith.priceLevels.forEach(pl => {
                    pl.prices.forEach(price => {
                        offers.push({
                            '@type': 'Offer',
                            'price': price.price / 100,
                            'name': price.name,
                            'priceCurrency': 'CAD',
                            'availability': price.soldout ?  'https://schema.org/SoldOut' : 'https://schema.org/InStock',
                            'validFrom': this.datePipe.transform(price.onsale, 'yyyy-MM-dd'),
                            'url': window.location.href
                        });
                    });
                });
                let schema = {
                    '@context': 'https://schema.org',
                    '@type': 'Event',
                    '@id': 'https://ticketpro.ca/event/' + value?.id || '',
                    'name': value.title,
                    'startDate': ith.formattedDatetime,
                    'eventAttendanceMode': 'https://schema.org/OfflineEventAttendanceMode',
                    'eventStatus': value.saleState === 'CANCELLED' ? 'https://schema.org/EventCancelled' : 'https://schema.org/EventScheduled',
                    'location': {
                        '@type': 'Place',
                        '@id': 'https://ticketpro.ca/place/' + value?.location?.id || '',
                        'name': value?.location?.name,
                        'address': {
                            '@type': 'PostalAddress',
                            'streetAddress': value?.location?.addressLine1,
                            'addressLocality': value?.location?.city,
                            'postalCode': value?.location?.postalCode,
                            'addressRegion': value?.location?.provinceState,
                            'addressCountry': value?.location?.country
                        }
                    },
                    'image': eventImage.length > 0 ? eventImage[0] : this.page.imageUrlBig,
                    //'description': this.page.description?.replace(/<[^>]*>/g, '').replace(/\n/g, ' '),
                    'offers': offers
                };
                if (offers.length > 0) {
                    this.schemas.push(schema);
                }
            });
            return this.schemas;
        } catch (e) {
            console.log(e);
        }

    }
    redirectToPage(page: Page) {
        let url = page.redirectUrl
        if (url && url !== ''){
            if (url.search('{}') !== -1) {
                url = url.replace('{}', this.translate.currentLang);
                console.log(url)
                this.router.navigate([url],
                    {queryParamsHandling: 'merge'})
                    .then(value => {
                    }).catch(error => {
                });
                return;
            } else {
                window.open(url, '_self');
            }
        }
    }
}
