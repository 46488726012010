import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'libTranslateLang'
})
export class TranslateLangPipe implements PipeTransform {
    constructor(public translate: TranslateService) {

    }

    transform(val?: any, en?: string, fr?: string): string {
        if(!fr){
            fr='TEXT'
        }
        if(!en){
            en='TEXT'
        }
        console.log(this.translate.currentLang, en, fr);
        return this.translate.currentLang === 'fr' ? fr : en;
    }

}
