@if (items.length> 0) {
  @if (!showPoint) {
    @for (h of items; track h) {
      @if ( h.itemHours && h.itemHours.length> 0) {
        <div class="tw-flex tw-mobile-not tw-desktop tw-margin-5" style="position: relative">
          @if (h.saleState !=='ONSALE') {
<button mat-icon-button color="primary" (click)="showNote(h)" aria-label="" style="position: absolute;
top: -21px;
    right: -10px;">
              <mat-icon>report</mat-icon>
            </button>
          }
          @if (h.itemHours.length === 1 && h.itemHours[0].formattedHour) {
            <button class=" bt-calendar-select-hour bt-calendar-hour-show-id-{{h.showId}}"
                            style="width: 100%;
                        padding: 0!important;height: auto;min-height: 30px"
              mat-flat-button
              color="primary"
              twColorBySaleState [saleState]="h.saleState"
              [disabled]="h.saleState !=='ONSALE'"
              (click)="goToEvent(h.code)"
              matTooltip="{{h.title}}"
              matTooltipClass="bt-calendar-tooltip-hour bt-calendar-tooltip-hour-one-f">
              {{h.itemHours[0].formattedHour}}
            </button>
          }
          @if (h.itemHours.length === 1 && !h.itemHours[0].formattedHour) {
            <button class="bt-calendar-select-one-hour bt-calendar-one-hour-show-id-{{h.showId}}"
              twColorBySaleState
              colorOnsale="green"
              [saleState]="h.saleState"
              mat-icon-button
              (click)="gotoPage(h)"
              matTooltip="{{h.title}}"
              matTooltipClass="bt-calendar-tooltip-hour bt-calendar-tooltip-hour-one"
              matTooltipHideDelay="100000">
              <mat-icon class="bt-calendar-icon-temp-{{h.showId}}">fiber_manual_record</mat-icon>
              <span class="bt-calendar-text-override-{{h.showId}}-{{translate.currentLang}}"></span>
            </button>
          }
          @if (h.itemHours.length > 1) {
            <button
              matTooltip="{{h.title}}"
              matTooltipClass="bt-calendar-tooltip-hour bt-calendar-tooltip-hour-more"
              class="item-inf-4 item-inf-4-temp bt-calendar-show-id-{{h.showId}}"
              twColorBySaleState
              colorOnsale="green"
              [saleState]="h.saleState"
              mat-icon-button
              (click)="gotoPage(h)">
              <mat-icon class="bt-calendar-icon-temp-{{h.showId}}">fiber_manual_record</mat-icon>
              <span class="bt-calendar-text-override-{{h.showId}}-{{translate.currentLang}}"></span>
            </button>
          }
        </div>
      }
    }
  }
  @if (showPoint) {
    <div class="tw-mobile-not tw-desktop item-more-4">
      @if (hasONSALE) {
        <button class="" style="color: green" mat-icon-button aria-label="keyboard_backspace" (click)="showListEvent()">
          <mat-icon class="icon-item-temp">fiber_manual_record</mat-icon>
        </button>
      }
    </div>
  }
  <div class="tw-mobile tem-more-1">
    @if ( items.length === 1) {
      <button
        class="item-inf-4 item-inf-4-temp bt-calendar-show-id-{{items[0].showId}}"
        twColorBySaleState
        colorOnsale="green"
        [saleState]="items[0].saleState"
        mat-icon-button
        (click)="showListEvent()">
        <mat-icon class="bt-calendar-icon-temp-{{items[0].showId}}">fiber_manual_record</mat-icon>
        <span class="bt-calendar-text-override-{{items[0].showId}}-{{translate.currentLang}}"></span>
      </button>
    }
    @if (hasONSALE && items.length !== 1) {
      <button style="color: green" mat-icon-button aria-label="keyboard_backspace" (click)="showListEvent()">
        <mat-icon class="icon-item-temp">fiber_manual_record</mat-icon>
      </button>
    }
  </div>
}
