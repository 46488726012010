<!--{{pr | json}}-->
@if (prices && prices.length > 0) {

    @switch (stylePrice) {

        @case ('priceAndLevel') {
            <div
                class="tw-flex tw-row pl-detail pl-detail-temp mat-elevation-z1"
                style="color:black;height: 91%"


                [ngStyle]="{
                    'border-color': showPriceLevelAndPrice ? prices[0].priceLevelColor : 'white'


                }"
            >
                <div
                    class="tw-flex"
                    style="justify-content: space-between; width: 100%;"
                    [ngClass]="
                        !(prices.length >= 1 && (showQty))
                            ? 'tw-row'
                            : 'tw-column'
                    "
                >
                    <ng-content select="[checkbox]"></ng-content>
                    <div
                        class="header-title"
                        style="
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;
                            box-sizing: border-box;
                            max-width: inherit;
                            font-weight: bold;
                        "
                        [matTooltip]="prices[0].priceLevel"
                    >
                        @if (showPriceLevelAndPrice) {
                            <div class="tw-flex tw-row priceLevel-title-temp">
                                {{ prices[0].priceLevel }}
                                @if (prices.length === 1) {
                                    - {{ prices[0].name }}
                                }
                            </div>
                            <div
                                class="priceLevel-title-temp-override-{{
                                    translate.currentLang
                                }}"
                            ></div>
                        } @else {
                            <div>
                                {{ prices[0].name }}
                            </div>
                        }
                    </div>
                    <div class="tw-flex tw-column" [ngStyle]="showQty?'':{textAlign: 'right'}">
                        @if (prices.length === 1) {
                            <span
                                class="price-color pricelevel-price-temp-{{
                                    getPageId()
                                }}-{{ prices[0].priceLevelId }}"
                            >
                                {{
                                    prices[0].price / 100
                                        | currency
                                            : ''
                                            : 'symbol-narrow'
                                            : ''
                                            : translate.currentLang
                                }}
                            </span>
                        } @else {
                            <span
                                class="price-color pricelevel-price-temp-{{
                                    getPageId()
                                }}-{{ prices[0].priceLevelId }}"
                            >
                                {{
                                    price_min / 100
                                        | currency
                                            : ''
                                            : 'symbol-narrow'
                                            : ''
                                            : translate.currentLang
                                }}
                                -
                                {{
                                    price_max / 100
                                        | currency
                                            : ''
                                            : 'symbol-narrow'
                                            : ''
                                            : translate.currentLang
                                }}
                            </span>
                            <span
                                style="display: none"
                                class="price-color pricelevel-price-temp-startingat-{{
                                    getPageId()
                                }}-{{ prices[0].priceLevelId }}-fr"
                            >
                                À partir de
                                {{
                                    price_min / 100
                                        | currency
                                            : ''
                                            : 'symbol-narrow'
                                            : ''
                                            : translate.currentLang
                                }}
                            </span>
                            <span
                                style="display: none"
                                class="price-color pricelevel-price-temp-startingat-{{
                                    getPageId()
                                }}-{{ prices[0].priceLevelId }}-en"
                            >
                                Starting at
                                {{
                                    price_min / 100
                                        | currency
                                            : ''
                                            : 'symbol-narrow'
                                            : ''
                                            : translate.currentLang
                                }}
                            </span>
                        }
                        <!--                            {{priceLevel.serviceCharge_min}} {{priceLevel.serviceCharge_max}}-->
                        @if (
                            serviceCharge_min > 0 &&
                            serviceCharge_max === serviceCharge_min
                        ) {
                            <span

                                class="service-fees-color pricelevel-fee-temp-{{ getPageId() }}"
                            >
                                {{
                                    'checkout.serviceCharge'
                                        | translate
                                            : {
                                                  value:
                                                      (prices[0]
                                                          .serviceCharges / 100
                                                      | currency
                                                          : ''
                                                          : 'symbol-narrow'
                                                          : ''
                                                          : translate.currentLang)
                                              }
                                }}
                            </span>
                        } @else if (serviceCharge_min > 0) {
                            <span
                                style="color: #00000091"
                                class="pricelevel-fee-temp-{{ getPageId() }}"
                            >
                                {{
                                    'checkout.serviceCharge'
                                        | translate
                                            : {
                                                  value:
                                                      (serviceCharge_min / 100
                                                          | currency
                                                              : ''
                                                              : 'symbol-narrow'
                                                              : ''
                                                              : translate.currentLang) +
                                                      '-' +
                                                      (serviceCharge_max / 100
                                                          | currency
                                                              : ''
                                                              : 'symbol-narrow'
                                                              : ''
                                                              : translate.currentLang)
                                              }
                                }}
                            </span>
                        }

                        @if (hasPromo) {
                            <span style="color: red" class="template-price-promo">
                                {{ 'page.promo' | translate }}
                            </span>
                        }

                        @if (soldout) {
                            <div class="mat-headline-6" style=" color: red">
                                <span>
                                    {{ 'checkout.soldOut' | translate }}
                                </span>
                            </div>
                        }
                    </div>
                </div>

                @if (!soldout && prices.length >= 1 ) {
                    <div class="tw-flex tw-column">
                        @if(showQty){
                            @if (useTemplateQty) {
                                <ng-container *ngTemplateOutlet="qty">
                                </ng-container>
                            } @else {
                                <ng-content select="[qty]"></ng-content>
                            }

                        }

                       @if(showQty && showTotal && prices.length === 1 && prices[0].total){
                           <mat-divider style="margin: 5px 0"></mat-divider>
                       }

                        @if (
                            prices.length === 1 && prices[0].total && showTotal
                        ) {


                            <div
                                class="tw-flex tw-row"
                                style="
                                    justify-content: space-between;
                                    align-items: center;
                                    font-weight: 700;
                                "
                            >
                                <span class="tw-margin-5"> Total </span>
                                <span class="tw-margin-5 price-color">
                                    {{
                                        prices[0].total / 100
                                            | currency
                                                : ''
                                                : 'symbol-narrow'
                                                : ''
                                                : translate.currentLang
                                    }}
                                </span>
                            </div>
                        }
                    </div>
                }
                @if (prices[0].donation ) {
                    <ng-content select="[donation]"></ng-content>
                }

            </div>
        }
    }
}
<ng-template #qty>
    <div style="text-align: center; padding: 10px 0; min-width: 165px" >
        <button class="bt-qty-minus-one-temp"
            style="min-width: 40px; width: 45px; height: 45px"
            color="primary"
            mat-stroked-button
            [disabled]="prices[0].qty ===0"
            (click)="qtyMinusOne(this.prices[0], priceLevel)"
        >
            <span>
                <mat-icon style="cursor: pointer">remove</mat-icon>
            </span>
        </button>
        @if (!this.prices[0].qty) {
            <input
                value="0"
                style="
                    width: 40px;
                    font-weight: bold;
                    text-align: center;
                    background: white;
                    border: none;
                    font-size: 17px;
                "
                disabled
            />
        }
        @if (this.prices[0].qty) {
            <input
                value="{{ this.prices[0].qty }}"
                style="
                    width: 40px;
                    font-weight: bold;
                    text-align: center;
                    background: white;
                    border: none;
                    font-size: 17px;
                "
                disabled
            />
        }
        <button
            class="bt-qty-plus-one-temp"
            style="min-width: 40px; width: 45px; height: 45px"
            color="primary"
            mat-stroked-button
            (click)="qtyPlusOne(this.prices[0], priceLevel)"
        >
            <span>
                <mat-icon style="cursor: pointer">add</mat-icon>
            </span>
        </button>
    </div>
</ng-template>
