import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';


export class Subdomain {

    constructor(public url: string, public subdomain: string,public apiUrl?:string) {
    }

}


const SUBDOMAIN: Subdomain[] = [
    new Subdomain('billetterieimperialbell.com', 'imperialbell','https://billetterieimperialbell.com/api/'),
    new Subdomain('www.billetterieimperialbell.com', 'imperialbell','https://www.billetterieimperialbell.com/api/'),
    new Subdomain('billetteriefeq.ca', 'billetterie', 'https://billetteriefeq.ca/api/'),
    new Subdomain('www.billetteriefeq.ca', 'billetterie','https://www.billetteriefeq.ca/api/'),
    new Subdomain('billetteriebleufeu.com', 'billetteriebleufeu', 'https://billetteriebleufeu.com/api/'),
    new Subdomain('billetteriestrochxp.com', 'billetteriestrochxp', 'https://billetteriestrochxp.com/api/'),
    new Subdomain('billetteriegrizzlyfuzz.com', 'grizzlyfuzz', 'https://billetteriegrizzlyfuzz.com/api/'),
];

@Injectable({
    providedIn: 'root'
})
export class SubdomainService {
    url: string;

    constructor() {
    }


    getSubdomainObserval(): Observable<string> {
        const host = window.location.hostname;
        if (!host) {
            return of(null);
        }
        const sub = SUBDOMAIN.find(subdomain => subdomain.url === host);
        if (!sub) {
            const temp = host.split('.');
            if (temp.length === 3) {
                return of(temp[0]);
            }
            return of(null);
        }
        return of(sub.subdomain);
    }

    setUrl(url: string) {
        if (!url) {
            return;
        }
        this.url = url;
    }

    getSubdomain(url?: string): string {
      //  return 'espacestdenis'


        let host = window.location.hostname;
        if (host.search('192.168') !== -1 || host.search('127.0') !== -1){
            return 'espacestdenis'
        }


        const urlTemp = new URL(window.location.href);
        const paramSubdomain = urlTemp.searchParams.get('subdomain');
        if (paramSubdomain) {
            return paramSubdomain;
        }


        if (url) {
            host = url;
        }
        if (!host) {
            return '';
        }
        const sub = SUBDOMAIN.find(subdomain => subdomain.url === host);
        //console.log(sub)
        if (!sub) {
            const temp = host.split('.');
            // console.log(temp)
            if (temp.length === 3) {

                return temp[0];
            }
            return '';
            //  return 'www';
            //return 'jungleisland';
        }
        return sub.subdomain;
    }


    getGwtUrl() {
        return `${environment.urlGwt.replace('%1', `${this.getSubdomain()}.`)}`;
    }

    getApiUrl(reqUrl) {
        let subdomain = this.getSubdomain();

        if (reqUrl.startsWith('/')) {
            reqUrl = reqUrl.substring(1);
        }

        if (environment.systemValues === 'mta') {
            if (subdomain === 'capitole') {
                return 'https://capitole.boutiquecomediha.com/api/' + reqUrl;
            }
        }

        if (window.location.hostname.search("checkout") !== -1) {
            subdomain = "checkout";
        }

        if (subdomain !== '') {
            subdomain = subdomain + '.';
        }
        let host = window.location.hostname;
        const sub = SUBDOMAIN.find(subdomain => subdomain.url === host);
        //console.log('***************************',sub)
        if(sub && sub.apiUrl){
            return sub.apiUrl + reqUrl;
        }


        if (environment.urlApi.search('127.0.0.1') !== -1) {
            return environment.urlApi + reqUrl;
        }

        //console.log(environment.urlApi, reqUrl);

        let t = (environment.urlApi + reqUrl).replace('http://', 'http://' + subdomain);
        t = t.replace('https://', 'https://' + subdomain);
        //console.log(t);

        return t;
    }

    getUrlWithSubdomain() {
        let subdomain = this.getSubdomain();

        if (subdomain !== '') {
            subdomain = subdomain + '.';
        }
        if (environment.urlApi.search('127.0.0.1') !== -1) {
            return environment.urlApi;
        }
        if (environment.systemValues === 'mta' && subdomain === 'capitole.') {
            return 'https://capitole.boutiquecomediha.com/api/';
        }
        const t = (environment.urlApi).replace('https://', 'https://' + subdomain);
        //console.log(environment.urlApi);
        return t;
    }
}
