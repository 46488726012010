<!--{{progress}}-->

@if (ev.systemValues === 'tkp') {
  @if (!progress) {

      @if(website && website.styleTkp){
          <div class="logo-tkp-loading" >
              <!--      <img   src="./assets/resources/svg/log-tkp-color.svg"  alt="cartlogo">-->
              <img
                  style="position: absolute"
                  width="120" src="./assets/resources/svg/logo-icon-couleur-RGB.svg"  alt="cartlogo">

              <mat-progress-spinner [diameter]="200" [strokeWidth]="3"
                                    mode="indeterminate">

              </mat-progress-spinner>
          </div>
      } @else {
          <div class="logo-tkp-loading logo-tkp-loading-1" >
                    <img class="img-load"  src="./assets/resources/svg/log-tkp-color.svg"  alt="cartlogo">



          </div>
      }



  }
}

