import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CartPrice } from '../../../../../projects/shared-lib/src/lib/models/cart-price';
import { CartService } from '../../../shared/services/cart.service';
import { ActionCartRequest, ActionCartService, EnumTypeActionCart } from '../../../shared/services/action-cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HelpsService } from '../../../shared/services/helps.service';
import { CartTaxInfoPriceComponent } from '../cart-tax-info-price/cart-tax-info-price.component';
import * as PDFObject from '../../../../../lib/PDFObject';
import { environment } from '../../../../../environments/environment';
import {WebsiteSaleService} from '../../../shared/services/website-sale.service';
import { CartDetailToken } from '../../../../../projects/shared-lib/src/lib/models/cart-detail-token';
import { SeatsSelected } from '../../../../../projects/shared-lib/src/lib/models/seats-selected';


@Component({
    selector: 'tw-cart-line',
    templateUrl: './cart-line.component.html',
    styleUrls: ['./cart-line.component.css']
})
export class CartLineComponent implements OnInit {
    hideEditButton = false;

    constructor(private cartService: CartService,
                private actionCart: ActionCartService,
                private route: ActivatedRoute,
                private router: Router,
                public translate: TranslateService,
                public websiteSaleService:WebsiteSaleService
               ) {

    }

    @ViewChild('viewInfoTaxPrice', { read: ViewContainerRef }) entryInfoTaxPrice: ViewContainerRef;
    @Input() cartPrice: CartPrice;
    @Input() location: any;
    hideEdit: boolean;
    componentRef: any;
    showDetailseat = false;
    environment = environment.systemValues;
    seatsSelectedList: SeatsSelected[]=[]
    ngOnInit() {
        this.hideEditButton = environment.systemValues === 'feq' || this.router.url.search('checkout-colts') !== -1;

        this.actionCart.watchActionCartResponse().subscribe(() => {

        });
        this.hideEdit = (this.router.url.search('cart') === -1);
        this.formatToSeastSelect(this.cartPrice.cartDetailToken);

    }

    qtyMinusOne() {
        this.actionCart.actionRequest(<ActionCartRequest>{ type: EnumTypeActionCart.lessOne, prices: [this.cartPrice] });
    }

    qtyPlusOne() {
        this.actionCart.actionRequest(<ActionCartRequest>{ type: EnumTypeActionCart.moreOne, prices: [this.cartPrice] });
    }

    deletedPrice() {
        this.actionCart.actionRequest(<ActionCartRequest>{ type: EnumTypeActionCart.deleteElement, prices: [this.cartPrice] });
    }

    infoPrice(idEvent: any, idSubscription: any, idMember: any, idPrice: any) {
        // get taxes from: https://www.ticketpro-test.com/api/v1/taxes/1302731618/1302827413

        this.componentRef = HelpsService.createComponent(CartTaxInfoPriceComponent, this.entryInfoTaxPrice);
        if (this.componentRef && this.componentRef.instance) {
            this.componentRef.instance.idEvent = idEvent;
            this.componentRef.instance.idSubscription = idSubscription;
            this.componentRef.instance.idPrice = idPrice;
            this.componentRef.instance.idMember = idMember;
        }

    }

    deleteInfoPrice() {
        this.entryInfoTaxPrice.clear();
        this.componentRef = null;

    }

    detailSeats(urlSeat) {
        //console.log('detailSeats');
      //  let url = 'https://' + window.location.host + environment.urlGwt + '/vs.pdf?perf=' + this.cartPrice.eventId;
        const url = environment.urlApi + urlSeat ;
        // this.cartPrice.cartDetailToken.forEach((value, index) => {
        //     if (value.seat) {
        //         //value.seat.id
        //         url = url + '&seat' + index + '=' + value.seat.dispositionDetailId;
        //     }
        // });

        if (url.search('null') !== -1) {
            return;
        }
        this.showDetailseat = true;
        //console.log(url);
        setTimeout(() => {
            //exp:'https://secure.ticketpro-test.com/vs.pdf?perf=1302731618&seat0=1278&seat1=1279&'
            PDFObject.embed(url, '#pdf-output');
        }, 200);
    }

    hideDetailSeats() {
        this.showDetailseat = false;
    }

    goToPage(pageUrl: string) {
        // http://espacestdenis.local.com:4200/en/pages/ELVISBLUE/performances/EE14LS23
        //pageUrl='http://espacestdenis.local.com:4200/en/pages/ELVISBLUE/performances/EE14LS23'
        window.open(pageUrl, '_self');
        // pageUrl = pageUrl.replace(window.location.origin,"")
        // this.router.navigate([pageUrl], {queryParamsHandling: 'merge'})
        //     .then(() => {
        //     }).catch(error => {
        //     //console.error(error);
        // });
    }

    formatToSeastSelect(cartDetailToken: CartDetailToken[]) {

        const seatsSelected: any|SeatsSelected = {
              data: [],
              prices: [],
        }
        cartDetailToken.forEach((value, index) => {
            if (value.seat) {
                seatsSelected.data.push(<any>{
                    name: value.seat.seat,
                    rowName: value.seat.row,
                    sectionName: value.seat.section,
                    levelName: value.seat.level,
                });
            }

        });
        this.seatsSelectedList.push(seatsSelected);

    }
}
