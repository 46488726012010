import { Component, Input, OnInit } from '@angular/core';
import { ConfigWebsite } from '../../modules/models/config-website';
import { BannersService } from '../banners.service';
import { lastValueFrom } from 'rxjs';
import { ImageSizeEnum } from '../../../projects/shared-lib/src/lib/models/image-size-enum';
import { TranslateService } from '@ngx-translate/core';
import { ImageDetail } from '../../../projects/shared-lib/src/lib/models/image-detail';
import { Router } from '@angular/router';
import { ClientService } from '../../modules/client/client.service';
import { AnalyticsService } from '../../modules/shared/services/analytics.service';
import { Sta } from '../../../projects/shared-lib/src/lib/models/address';
import { constSystem } from '../../../projects/shared-lib/src/lib/utils/const-system';
import { HelpsService } from '../../../projects/shared-lib/src/lib/utils/helps.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'tw-tkp-template',
    templateUrl: './tkp-template.component.html',
    styleUrls: ['./tkp-template.component.css']
})
export class TkpTemplateComponent implements OnInit {
    @Input() loggedIn;
    @Input() configWeb: ConfigWebsite;
    @Input() showCart: any;

    provinces = <Sta[]>constSystem.world.provinces;
    provinceSelected: Sta;
    menus = [
        {
            url: '',
            translate: 'title.sport',
            auth: false,
            public: true

        },
        {
            url: '',
            translate: 'title.music',
            auth: false,
            public: true

        },
        {
            url: '',
            translate: 'title.comedy',
            auth: false,
            public: true

        },
        {
            url: '',
            translate: 'title.festival',
            auth: false,
            public: true

        },
        {
            url: '',
            translate: 'title.art',
            auth: false,
            public: true

        },
        {
            url: '',
            translate: 'title.exhibition',
            auth: false,
            public: true

        },
        {
            url: '/locations',
            translate: 'title.venue',
            auth: false,
            public: true

        }
        ,
        {
            url: '/client/login',
            translate: 'title.login',
            auth: false,
            reverse: true
        }
        ,
        {
            url: '/client/signup',
            translate: 'title.signUp',
            auth: false,
            reverse: true
        }
        ,
        {
            url: '/static/faq',
            translate: 'title.faq',
            auth: false,
            public: true,
            reverse: true
        }
        ,
        {
            url: '/static/contact',
            translate: 'title.contact',
            auth: false,
            public: true,
            reverse: true
        }
        ,
        {
            url: 'https://ticketprocanada.ca/fr/index.html',
            translate: 'title.solution',
            auth: false,
            public: true,
            reverse: true
        }
        ,
        {
            url: '/client/client-forgot-password',
            translate: 'client.forgotPassword',
            auth: false,
            reverse: true
        }
        ,
        // {
        //     url: 'profile',
        //     translate: 'title.profile',
        //     auth: true,
        //     reverse: true
        // }
        // ,
        // {
        //     url: 'invoices',
        //     translate: 'title.invoices',
        //     auth: true,
        //     reverse: true
        // }
        // ,
        // {
        //     url: 'tickets',
        //     translate: 'title.myTickets',
        //     auth: true,
        //     reverse: true
        // }
        // ,
        // {
        //     url: 'flex-pass-list',
        //     translate: 'title.flex-pass',
        //     auth: true,
        //     reverse: true
        // },
        // {
        //     url: 'change-password',
        //     translate: 'title.changePassword',
        //     auth: true,
        //     reverse: true
        // },

        {
            url: 'logout',
            translate: 'title.logout',
            auth: true,
            reverse: true
        }
        // ,
        // {
        //     url: '',
        //     translate: 'title.lang',
        //     auth: false,
        //     public: true,
        //     reverse: true
        // }
    ];
    menusAccount = [
        {
            url: '/client/login',
            translate: 'title.login',
            auth: false,
            reverse: true
        }
        ,
        {
            url: '/client/signup',
            translate: 'title.signUp',
            auth: false,
            reverse: true
        },
        {
            url: '/client/client-forgot-password',
            translate: 'client.forgotPassword',
            auth: false,
            reverse: true
        }
        ,
        {
            url: '/client/auth/profile',
            translate: 'title.profile',
            auth: true,
            reverse: true
        }
        ,
        {
            url: '/client/auth/invoices',
            translate: 'title.invoices',
            auth: true,
            reverse: true
        }
        ,
        {
            url: '/client/auth/tickets',
            translate: 'title.myTickets',
            auth: true,
            reverse: true
        }
        ,
        {
            url: '/client/auth/flex-pass-list',
            translate: 'title.flex-pass',
            auth: true,
            reverse: true
        },
        {
            url: '/client/auth/change-password',
            translate: 'title.changePassword',
            auth: true,
            reverse: true
        },

        {
            url: 'logout',
            translate: 'title.logout',
            auth: true,
            reverse: true
        }
    ];
    menusFilter;
    menusAccountFilter;
    listImages1920 = [];
    listImages728 = [];
    carousels = [];


    constructor(public bannersService: BannersService, public translate: TranslateService,
                private router: Router, private clientService: ClientService,
                private analyticsService: AnalyticsService,
                public helpsService: HelpsService,
                public breakpointObserver: BreakpointObserver
    ) {
    }

    async ngOnInit() {
        this.clientService.subLogin.asObservable().subscribe(logging => {
            this.loggedIn = logging;
            if (this.loggedIn) {
                this.menusFilter = this.menus.filter(value => value.auth || value.public);
                this.menusAccountFilter = this.menusAccount.filter(value => value.auth);
            } else {
                this.menusFilter = this.menus.filter(value => !value.auth || value.public);
                this.menusAccountFilter = this.menusAccount.filter(value => !value.auth);
            }
        });

        if (this.loggedIn) {
            this.menusFilter = this.menus.filter(value => value.auth || value.public);
            this.menusAccountFilter = this.menusAccount.filter(value => value.auth);
        } else {
            this.menusFilter = this.menus.filter(value => !value.auth || value.public);
            this.menusAccountFilter = this.menusAccount.filter(value => !value.auth);
        }
        this.breakpointObserver.observe([
            Breakpoints.HandsetLandscape,
            Breakpoints.HandsetPortrait
        ]).subscribe(result => {
            if (result.matches) {
                console.log(result);
            }
        });
    }


    goClientRoute(login: string) {

    }

    goToRoute(val: string) {
        console.log(this.translate.currentLang + val);
        if (val.search('http') !== -1) {
            window.open(val, '_blank').focus();
            return;
        }

        if (val === 'logout') {
            this.analyticsService.trackLogout();
            this.clientService.logoutClient(this.configWeb.accountCode).subscribe(value => {
                //this.router.navigate([this.translate.currentLang + '/client/login']);

                if (this.router.url.search('/checkout/mode') !== -1) {
                    /*** dans la page checkout/mode il faut le rediriger  **/

                    return;
                }
                this.goToRoute('/client/login');
                this.loggedIn = false;
            });
            return;
        }
        if (this.translate && this.translate.currentLang) {
            this.router.navigate([this.translate.currentLang + val]);
        } else {
            this.router.navigate([val]);
        }
    }

    selectProvince(st: Sta) {
        this.provinceSelected = st;
    }
}
