import {Component, Input, OnInit} from '@angular/core';
import {Tax} from '../../../models/tax';
import {ShippingService} from '../../../shipping/services/shipping.service';
import {ShippingModeRest} from '../../../models/shipping-mode-rest';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'tw-cart-tax-view',
    templateUrl: './cart-tax-view.component.html',
    styleUrls: ['./cart-tax-view.component.css']
})
export class CartTaxViewComponent implements OnInit {
    @Input() tax: Tax | any;
    @Input() typeStyle = 1;
    @Input() giftAmount = 0;
    @Input() totalToPay = 0;

    totalShippingSelected = 0;
    total = 0;
    hasShipping = false;
    hasOnlySubtotal = false;


    constructor(private shippingService: ShippingService, public translate: TranslateService) {
    }

    ngOnInit() {
        if(!this.giftAmount){
            this.giftAmount = 0;
        }
        this.totalSipping();
        this.shippingService.watchShippingSelect().subscribe(() => {
            this.totalSipping();
        });

    }

    totalSipping() {
        this.totalShippingSelected = 0;
        const temp = this.shippingService.getShippingSelectedLocal();
        if (temp) {
            this.hasShipping = true;
            Object.keys(temp)?.forEach(value => {
                //console.log(temp[value].price);
                try {
                    this.totalShippingSelected = this.totalShippingSelected + temp[value].price;
                }catch (e) {
                    console.log(e)
                }

            });
        }
        this.hasOnlySubtotal = this.tax.totalAfterTaxes === this.tax.totalBeforeTaxes && this.tax.totalBeforeTaxes === this.tax.subtotal;        this.setTotal();
        if(this.hasOnlySubtotal && !this.hasShipping){
            this.typeStyle = 3;
        }


    }

    setTotal() {
        if(!this.giftAmount){

        }
        this.total = this.tax.totalAfterTaxes + this.totalShippingSelected - this.giftAmount;
        console.log(this.tax.totalAfterTaxes , this.totalShippingSelected , this.giftAmount)
        console.log(this.total)
    }

}
