import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {TicketPrice} from '../../../../models/ticket-price';
import {Location} from '../../../../models/location';
import {TranslateService} from '@ngx-translate/core';
import {MailCategory, TicketDetailToken} from '../../../../models/ticket-detail-token';
import {HttpClient} from '@angular/common/http';
import {TicketsService} from '../../../tickets.service';
import {GiftTicketDialogComponent} from '../client-gift-ticket/client-gift-ticket-dialog/gift-ticket-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SendTicketAsGiftResponse} from '../../../../../../projects/shared-lib/src/lib/models/send-ticket-as-gift-response';


@Component({
    selector: 'tw-client-ticket-line',
    templateUrl: './client-ticket-line.component.html',
    styleUrls: ['./client-ticket-line.component.css']
})
export class ClientTicketLineComponent implements OnInit, OnDestroy {
    @ViewChild('viewInfoTaxPrice', { read: ViewContainerRef }) entryInfoTaxPrice: ViewContainerRef;
    @Input() ticketPrice: TicketPrice;
    @Input() location: Location;
    @Output() displayPdf: EventEmitter<string> = new EventEmitter<string>();
    @Input()
    showDetailSeat = false;
    ticketsFileUrl: string;
    seatsFileUrl: string;
    ticketFileUrl: string[] = [];
    mail: MailCategory;
    ticketId: number;
    sendTicketAsGiftResponse: SendTicketAsGiftResponse;

    constructor(public translate: TranslateService,
                public http: HttpClient,
                private ticketService: TicketsService,
                private dialog: MatDialog
                ) {
    }

    ngOnInit() {
        this.mail = this.ticketPrice.upcomingTicketDetailTokens[0].mailCategory;
        this.ticketId = this.ticketPrice.upcomingTicketDetailTokens[0].sodId;
    }

    ngOnDestroy(): void {
        if (this.ticketsFileUrl) {
            URL.revokeObjectURL(this.ticketsFileUrl);
        }
        if (this.seatsFileUrl) {
            URL.revokeObjectURL(this.seatsFileUrl);
        }
    }

    isPrintableCategory(category: MailCategory) {
        return category === MailCategory.E_TICKETS || category === MailCategory.SMARTPHONE;
    }

    printTicket(mail: MailCategory, tokenIndex: number) {
        this.showDetailSeat = true;
        if (!this.ticketFileUrl[tokenIndex]) {
            this.ticketService.getTicketPdfForPrice(this.ticketPrice.upcomingTicketDetailTokens[tokenIndex]).subscribe((pdf) => {
                this.ticketFileUrl[tokenIndex] = URL.createObjectURL(pdf);
                this.displayPdf.emit(this.ticketFileUrl[tokenIndex]);
            });
        } else {
            this.displayPdf.emit(this.ticketFileUrl[tokenIndex]);
        }

    }

    openSendTicketDialog(recipient?: string, sodId?: number) {
        const dialogRef = this.dialog.open(GiftTicketDialogComponent, {
            data: {
                sodId: sodId,
                email: recipient
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.sendTicketAsGiftResponse = result;
            console.log('Dialog closed with result:', result);
        });
    }
}
