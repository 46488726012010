import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    ActionButtonFloatingOrder,
    ActionButtonFloatingOrderEnum,
    ButtonFloatingOrderService,
} from '../button-floating-order.service';
import { NavigationEnd, Router } from '@angular/router';
import {
    Combo2State,
    PageCombo2Service,
} from '../../page/component/page-combo2/page-combo2.service';
import { PageSaleService } from '../../page/services/page-sale.service';
import { CartService } from '../../shared/services/cart.service';
import { PriceManagerService } from '../../page/services/price-manager.service';

@Component({
    selector: 'tw-button-floating-order',
    templateUrl: './button-floating-order.component.html',
    styleUrls: ['./button-floating-order.component.css'],
})
export class ButtonFloatingOrderComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    @Input() nameUnique;
    @Input() isDialog = false;
    qtyReserved = 0;
    load = true;
    splitPrices = true;
    bestsSelect = true;
    total: number;
    _sub = [];
    combo2State: Combo2State;
    activeBtn = false;
    onlyGiftCertificates = false;

    constructor(
        public translate: TranslateService,
        public pageCombo2Service: PageCombo2Service,
        public buttonFloatingOrderService: ButtonFloatingOrderService,
        public router: Router,
        public pageSaleService: PageSaleService,
        public priceManagerService: PriceManagerService,
        public cartService:CartService ){
    }

    ngOnDestroy(): void {
        this._sub.forEach((val) => {
            val.unsubscribe();
        });
    }

    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    const t = document.querySelectorAll('.btn-floating-order');
                    if (t && t.length < 2) {
                        const btFooterElm = document.getElementById(
                            'main-app-footer-button',
                        );
                        if (btFooterElm) {
                            btFooterElm.classList.add(
                                'main-app-footer-button-hide',
                            );
                            //this.qtyReserved = 0;
                            this.setQty(0);
                        }
                    }

                    ////console.log(t.length, t);
                }, 2000);
            }
        });
        const a = this.buttonFloatingOrderService.action
            .asObservable()
            .subscribe({
                next: (value) => {
                    ////console.log('=============================', value);
                    this.managerAction(value);
                },
            });
        const combo = this.pageCombo2Service.combo2StateSubject
            .asObservable()
            .subscribe((value) => {
                this.combo2State = value;
                this.setQty(this.qtyReserved);
            });
        this._sub.push(combo);

        this._sub.push(a);
        const actionButtonFloatingOrder =
            this.buttonFloatingOrderService.action.value;
        if (actionButtonFloatingOrder) {
            this.managerAction(actionButtonFloatingOrder);
        }
    }

    managerAction(value: ActionButtonFloatingOrder) {
        //console.log(value);
        if (value && value.name === ActionButtonFloatingOrderEnum.DATA) {
            const temp = this.priceManagerService.getDetailReserved()

            this.onlyGiftCertificates = temp.prices.filter(value1 => value1.giftCertificatesId === undefined).length === 0;
            // this.setQty(temp.qty);
            // this.total =temp.total;
            if (value.data.qtyReserved !== undefined) {
                // this.qtyReserved = value.data.qtyReserved;
                this.setQty(value.data.qtyReserved);
            }
            if (value.data.total !== undefined) {
                this.total = value.data.total;
            }
            if (value.data.load !== undefined) {
                this.load = value.data.load;
            }
            if (value.data.splitPrices !== undefined) {
                this.splitPrices = value.data.splitPrices;
            }
            if (value.data.bestsSelect !== undefined) {
                this.bestsSelect = value.data.bestsSelect;
            }
            if(value.data.update){
               const a = this.priceManagerService.getDetailReserved();
              this.total = a.total;
              this.setQty(a.qty);
            }
        }
    }

    ngAfterViewInit(): void {
        const pageCon = document.querySelector(
            'tw-page-prices-leves-container',
        );
        ////console.log('======================================', pageCon);
        if (!pageCon) {
            const btFooterElm = document.getElementById(
                'main-app-footer-button',
            );
            btFooterElm.classList.add('main-app-footer-button-hide');
        }
    }

    setQty(qty) {
        this.qtyReserved = qty;
        this.activeBtn = this.qtyReserved > 0;
        if (this.combo2State && !this.combo2State.hasRightToGoOptional) {
            this.activeBtn = false;
        }
    }

    createHoldPage() {
        this.buttonFloatingOrderService.action.next({
            name: ActionButtonFloatingOrderEnum.HOLD,
            isDialog: this.isDialog,
        });
    }

    closeDialog() {
        this.buttonFloatingOrderService.action.next({
            name: ActionButtonFloatingOrderEnum.CLOSE,
        });
    }
    goToCart() {

        const page = this.pageSaleService.page;
        let url = this.translate.currentLang + '/cart'
        const queryParams={};
        if(page?.display === 'ITEMS'){
            const urlSuggestion = this.pageSaleService.getUrlPageSuggestion(page._links, null, this.cartService.cartSub.value, page.display, page.prices);

            if(urlSuggestion) {
                url = this.translate.currentLang + '/pages/' +urlSuggestion;
                const params = (urlSuggestion.split('?'))[1];
                queryParams['suggestion'] =true
                if (params?.search('promo') === -1) {
                    queryParams['promo'] =''
                }
            }
            console.log(urlSuggestion,queryParams,url)
        }
        this.router.navigate([url], {queryParamsHandling: 'merge', queryParams: queryParams})
            .then(() => {
            }).catch(error => {
            // console.error(error);
        });
    }
}
