import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { PageLoadComponent } from './page-load/page-load.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';



@NgModule({
    declarations: [PageLoadComponent],
    exports: [PageLoadComponent],
    imports: [CommonModule, NgOptimizedImage, MatProgressSpinner],
})
export class PageLoadModule {}
