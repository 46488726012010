/**
 * @deprecated utilse l'interface  le model de shared-lib/src/lib/models **/
export interface GtmDataLayer {
    event: GtmEvent;
    pageLanguage?: string;
    pageCategory?: string;
    ecommerce?: any;
    pageTitle?: string;
    pageURL?: string;
    userId?: number;
    eventLabel?: string;
    eventAction?: string;
    eventCategory?: string;
}

// When adding a new field, also add it to sanitizeDataLayer() in AnalyticsService

/**
 * @deprecated utilse l'interface  le model de shared-lib/src/lib/models **/
export enum GtmEvent {
    ADD_TO_CART = 'addToCart',
    REMOVE_FROM_CART = 'removeFromCart',
    CART = 'cart',
    PRODUCT_DETAILS = 'product_details',
    CLICK = 'click',
    CHECKOUT = 'checkout',
    PURCHASE = 'purchase',
    VIRTUAL_PAGEVIEW = 'virtual_pageview',
    PAGE_VIEW = 'page_view',
    BEGIN_CHECKOUT = 'beginCheckout',
    ADD_PAYMENT_INFO = 'addPaymentInfo',
    ADD_SHIPPING_INFO = 'addShippingInfo',
    SELECT_ITEM = 'selectItem',
    SELECT_PROMOTION = 'selectPromotion',
    VIEW_PROMOTION = 'viewPromotion',
}
/**
 * @deprecated utilse l'interface  le model de shared-lib/src/lib/models **/
export enum GtmEcommerceEvent {
    ADD = 'add',
    REMOVE = 'remove',
    CHECKOUT = 'checkout',
    PURCHASE = 'purchase',
    DETAIL = 'detail',
}
