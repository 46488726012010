import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SessionLocaleService} from '../../shared/services/session-locale.service';
import {CheckoutCartRequest} from '../../models/checkout-cart-request';
import {forkJoin, lastValueFrom, Observable, of, Subject} from 'rxjs';
import {CreditCardPaiementRequest} from '../../models/credit-card-paiement-request';
import {CardStripeRequest} from '../../models/card-stripe-request';
import {InvoiceConfirmation} from '../../models/invoice-confirmation';
import {flatMap, map} from 'rxjs/operators';
import {CartService} from '../../shared/services/cart.service';
import {PaymentResponse} from '../../models/payment-response';
import {Cart} from '../../../../projects/shared-lib/src/lib/models/cart';
import {ClientDetails} from '../../models/client-details';
import {ClientService} from '../../client/client.service';
import {CartInvoice} from '../../models/cart-invoice';
import {ConfigWebsite} from '../../models/config-website';
import {CreditCardProcessorTypeEnum} from '../../models/credit-card-processor-type-enum.enum';
import {TranslateService} from '@ngx-translate/core';
import {ThreeDSecureAuthentications} from './3d-secure';
import {D3SecureService} from './d3-secure.service';
import {ActionCartRequest, ActionCartService, EnumTypeActionCart} from '../../shared/services/action-cart.service';
import {ShippingModeRest} from '../../models/shipping-mode-rest';
import {ShippingService} from '../../shipping/services/shipping.service';
import {AnalyticsService} from '../../shared/services/analytics.service';
import {ActivatedRoute} from '@angular/router';
import {TokensPaymentEnum} from '../../../../projects/shared-lib/src/lib/models/tokens-payment-enum';
import {RenewSubscriptionRequest} from '../../models/renewSubscriptionRequest';
import { SubdomainService } from '../../shared/services/subdomain.service';
import { GiftCertificateResponse } from '../../models/gift-certificate-response';
import { cloneObjectHelps } from '../../../../projects/shared-lib/src/lib/utils/utilsShared';



export interface Stay22 {
    url: string;
    aid: any;
    lat: any;
    lng: any;
    address: any;
    checkin: any;
    checkout: any;
    maincolor: any;
    markerimage: any;
    eventStart: any;
    eventend: any;
    campaign: any;
    venue: any;
    disableservices: any;
    hidemodeswitcher: any;
    invmode: any;
    adults: any;
    children: any;
    chpincolor: any;
    chpinfontcolor: any;
    currency: any;
    customfontlink: any;
    customfont: any;
    disableautohover: any;
    disabledirections: any;
    disablehotels: any;
    disablerentals: any;
    dotlistings: any;
    featuredhcids: any;
    featuredabids: any;
    fitmaptopoi: any;
    fontcolor: any;
    freezeviewport: any;
    hideadults: any;
    hidecheckinout: any;
    hidechildren: any;
    hidecurrency: any;
    hideenlargemap: any;
    hidefilters: any;
    hideguestpicker: any;
    hideguestrating: any;
    hideheatmap: any;
    hideinfomenu: any;
    hidelanguage: any;
    hidemainmarkercover: any;
    hidemappanels: any;
    hideppn: any;
    hidepricefilter: any;
    hidepriceper: any;
    hiderooms: any;
    hideroomtypefilter: any;
    hidesearchbar: any;
    hidesettings: any;
    hideshare: any;
    hidestarrating: any;
    hotelapi: any;
    isnear: any;
    ljs: any;
    loadingbarcolor: any;
    mapstyle: any;
    max: any;
    min: any;
    minguestrating: any;
    minstarrating: any;
    navimage: any;
    nelat: any;
    nelng: any;
    nopop: any;
    onlyabids: any;
    onlyhcids: any;
    openmenu: any;
    poibgcolor: any;
    priceper: any;
    priceslidercolor: any;
    rentalapi: any;
    rooms: any;
    scroll: any;
    showairbnbs: any;
    showgmapsicon: any;
    showhotels: any;
    showothers: any;
    skipabids: any;
    skiphcids: any;
    skipotasrp: any;
    supportedcurrencies: any;
    supportedlang: any;
    swlat: any;
    swlng: any;
    zoom: any;
    refresh: any;
    resolution: any;
    width: any;
    height: any;


}

@Injectable({
    providedIn: 'root'
})
export class CheckoutService {
    subInterat = new Subject<any>();

    constructor(private http: HttpClient,
                private sessionLocaleService: SessionLocaleService,
                private cartService: CartService,
                private clientService: ClientService,
                private translate: TranslateService,
                private d3SecureService: D3SecureService,
                private actionCartService: ActionCartService,
                private shippingService: ShippingService,
                private subdomainService: SubdomainService,
                private route: ActivatedRoute
    ) {
    }

    addDetailInvoice(val) {
        SessionLocaleService.putSync('invoice', val);
    }

    deleteDetailInvoice() {
        SessionLocaleService.deleteByKey('invoice');
    }

    getDetailInvoice(): InvoiceConfirmation {
        return SessionLocaleService.getSync('invoice');
    }


    payment(checkoutCartRequest: CheckoutCartRequest): Observable<any> {
        return this.http.post<any>('backend/v1/cart/checkoutCreditCard', checkoutCartRequest);
    }

    paymentColts(checkoutCartRequest: CheckoutCartRequest | CreditCardPaiementRequest | any): Observable<any> {
        const subDomain = this.subdomainService.getSubdomain();
        const mapped = new Map<String, Object>();
        mapped.set("shipping",checkoutCartRequest.shippingCarts)
        mapped.set('token', checkoutCartRequest.saleToken);
        mapped.set('client', checkoutCartRequest.client);
        mapped.set('creditCard', checkoutCartRequest.creditCard);
        console.log(mapped)
        //return of()
        return this.http.post<any>('backend/v1/websites/' + subDomain + '/subscriptions/renew/multiplePaymentCheckout', checkoutCartRequest);
    }

    getColtsDetail(renewSubscriptionRequest: RenewSubscriptionRequest): Observable<PaymentResponse> {
        const subdomain = this.subdomainService.getSubdomain()
        return this.http.post<PaymentResponse>('backend/v1/websites/'+ subdomain+'/subscriptions/renew/details', renewSubscriptionRequest).pipe(
            map((response) => {

                response.shippings = this.getShippingForClots();
                return response;
            })
        );
    }
    getShippingForClots(): ShippingModeRest[] {
        const shipping = <ShippingModeRest[]>[];
        const territory = {
            AB: false,
            BC: false,
            HORS_CANADA: false,
            MB: false,
            NB: false,
            NL: false,
            NS: false,
            NT: false,
            NU: false,
            ON: false,
            PE: false,
            QC: false,
            SK: false,
            YT: false
        };
        let sessionBookList = <ShippingModeRest>{};
        sessionBookList.description = 'PRINTED TICKETS';
        sessionBookList.code = 'SeasonBookelts';
        sessionBookList.note = '<p>Printed tickets will be made available for pick up later in August from the Sadlon Arena when the schedule is finalized.</p>';
        sessionBookList.territory = territory;
        sessionBookList.price = 0;
        sessionBookList.invalidFromCartAmount = 0;
        sessionBookList.ppdId=1

        const eTickets = <ShippingModeRest>{};
        eTickets.description = 'E-TICKETS';
        eTickets.code = 'ETickets';
        eTickets.note = '<p>Your electronic tickets will be e-mailed to you in August when the schedule is finalized.</p>';
        eTickets.territory = territory;
        eTickets.price = 0;
        eTickets.invalidFromCartAmount = 0;
        eTickets.ppdId=2

        const smartPhoneTickets = <ShippingModeRest>{};
        smartPhoneTickets.description = 'SMARTPHONE TICKETS';
        smartPhoneTickets.code = 'Smartphone';
        smartPhoneTickets.note = '<p>Show your tickets on your smartphone at the door.Your smartphone tickets will be e-mailed to you in August when the schedule is finalized</p>';
        smartPhoneTickets.territory = territory;
        smartPhoneTickets.price = 0;
        smartPhoneTickets.invalidFromCartAmount = 0;
        smartPhoneTickets.ppdId=3

        shipping.push(sessionBookList);
        shipping.push(eTickets);
        shipping.push(smartPhoneTickets);
        return shipping;
    }

    fixPaymentDeferredError(creditCardPaiementRequest: CreditCardPaiementRequest): Observable<any> {
        return this.http.post<any>('backend/v1/ccpayment/process-paiement', creditCardPaiementRequest);
    }

    getTokenStripe(key: string, cardStripeRequest: CardStripeRequest) {

        const promise1 = new Promise((resolve, reject) => {
            // @ts-ignore
            const stripe = window.Stripe;
            if (!stripe) {
                reject({
                    message: 'missing api stripe'
                });
            }
            stripe.setPublishableKey(key);
            stripe.card.createToken(cardStripeRequest, (status, response) => {
                if (status === 200) {
                    resolve(response.token);
                } else {
                    const err = response.error.code;
                    let erMeg = '';
                    if (err) {
                        erMeg = 'checkout.stripe.error.' + err;
                    } else {
                        erMeg = 'checkout.stripe.error.processing_error';
                    }

                    this.translate.get(erMeg).subscribe(value => {
                        const error1 = {error: {error: {message: value}}};
                        reject(error1);
                    });

                }
            });
        });
        return promise1;

    }

    getReservation(token: string): Observable<[ClientDetails, Cart]> {
        let ht = new HttpParams().set('token', token);
        const presale = this.route.snapshot.queryParams['presale'];
        if (presale) {
            ht = ht.set('presale', presale);
        }

        return this.http.get<any>('v1/cart', {params: ht}).pipe(
            map(value => {
                return this.clientService.getClientSelf();
            }),
            flatMap(client => {
                    return forkJoin([
                        client,
                        this.cartService.getCart()]
                    );
                }
            )
        );
    }

    getDetailCheckoutDeferred(tokenDeferred: string): Observable<PaymentResponse> {
        return this.http.post<PaymentResponse>('v1/ccpayment/details', {saleToken: tokenDeferred});
    }

    getInvoiceConfirmation(invoiceId: any): Observable<CartInvoice> {
        return this.http.get<CartInvoice>('v1/invoice-confirmation/' + invoiceId);
    }

    getURLStay22(invoiceId: any, test = false): Observable<Stay22> {
        if (test) {
            return this.http.get<Stay22>('v1/stay22/object/test?performanceId=1501122251');
        }

        return this.http.get<Stay22>('v1/stay22/object/' + invoiceId);
    }

    kickout() {
        this.http.put('v1/kickout', null).subscribe();
    }

    addCheckoutCartRequestInSessionBackend(checkoutCartRequest: CheckoutCartRequest): Observable<any> {
        return this.http.put<any>('v1/session/create-sale', checkoutCartRequest);
    }

    getCheckoutCartRequestInSessionBackend(): Observable<CheckoutCartRequest> {
        return this.http.get<CheckoutCartRequest>('v1/session/sale');
    }

    paysafeThreeDSecure(checkoutCartRequest: CheckoutCartRequest, deviceFingerprintingId: string): Observable<ThreeDSecureAuthentications> {

        return this.http.post<ThreeDSecureAuthentications>('v1/threeDSecure/paysafe/authentications/' + deviceFingerprintingId, checkoutCartRequest);
    }

    async doPaymentColts(checkoutCartRequest: CheckoutCartRequest | CreditCardPaiementRequest,
                         renewSubscriptionRequest: RenewSubscriptionRequest,
                         configWebsite: ConfigWebsite) {


        const invoice = await this.paymentColts(<CheckoutCartRequest>checkoutCartRequest).toPromise();
        this.deleteCartColts(<CheckoutCartRequest>checkoutCartRequest);
        if ('amount' in checkoutCartRequest) {
            const amount = checkoutCartRequest.amount;
            AnalyticsService.zendataTrackPurchase(amount, renewSubscriptionRequest.token);
        }
        if (configWebsite.clientAccount === 'NO') {
            try {
                this.clientService.logoutClient(configWebsite.accountCode).subscribe(() => {
                });
            } catch (e) {

            }
        }


    }
    async doPayment(checkoutCartRequestRef: CheckoutCartRequest | CreditCardPaiementRequest,
                    configWebsite: ConfigWebsite,
                    pageFixPaymentDeferredError: boolean = false,
                    orderResponse: PaymentResponse = null) {
        const checkoutCartRequest = cloneObjectHelps(checkoutCartRequestRef);
        let stripeAuthentications = null;
        if (configWebsite.processorConfig && Object.keys(checkoutCartRequest.creditCard).length > 0
            && !('amount' in checkoutCartRequest && (<CheckoutCartRequest>checkoutCartRequest)?.amount === 0)
        ) {

            switch (configWebsite.processorConfig.type) {
                case CreditCardProcessorTypeEnum.STRIPE:
                    const cardStripe = <CardStripeRequest>{
                        number: checkoutCartRequest.creditCard.number,
                        // @ts-ignore
                        exp_month: checkoutCartRequest.creditCard.expiry.substring(0, 2),
                        // @ts-ignore
                        exp_year: checkoutCartRequest.creditCard.expiry.substring(2, 4),
                        cvc: checkoutCartRequest.creditCard.cvd
                    };
                    stripeAuthentications = await this.getTokenStripe(configWebsite.processorConfig.token, cardStripe);
                    break;
                case CreditCardProcessorTypeEnum.PAYSAFE:
                    if (configWebsite.processorConfig.threeDSecure && !pageFixPaymentDeferredError && !checkoutCartRequest.creditCard.tokensPayment) {
                        // console.log( configWebsite.processorConfig.token,configWebsite.processorConfig.accountId)
                        const deviceFingerprintingId = await this.d3SecureService.start(
                            checkoutCartRequest.creditCard.number.substring(0, 6),
                            configWebsite.processorConfig.threeDSecureToken, configWebsite.processorConfig.accountId);
                        const threeDSecureAuthentications = await this.paysafeThreeDSecure(<CheckoutCartRequest>checkoutCartRequest, deviceFingerprintingId).toPromise();
                        if (threeDSecureAuthentications && threeDSecureAuthentications.challenged) {
                            const id = await this.d3SecureService.challenge(threeDSecureAuthentications.sdkChallengePayload, configWebsite.processorConfig.threeDSecureToken);
                            checkoutCartRequest.creditCard.threeDSecureAuthenticationId = id;
                        } else {
                            checkoutCartRequest.creditCard.threeDSecureAuthenticationId = threeDSecureAuthentications.id;
                        }
                    }
                    break;
                case CreditCardProcessorTypeEnum.GLOBALPAYMENTS:
                    if (configWebsite.processorConfig.threeDSecure && !pageFixPaymentDeferredError && !checkoutCartRequest.creditCard.tokensPayment) {

                           checkoutCartRequest.creditCard.threeDSecureAuthenticationId = await this.d3SecureService.global(checkoutCartRequest.creditCard,(<CheckoutCartRequest>checkoutCartRequest)?.amount)

                    }
                    break;
            }
        }
        let invoice;
        checkoutCartRequest.creditCard.stripeToken = stripeAuthentications;
        if (stripeAuthentications) {
            checkoutCartRequest.creditCard.tokensPayment = {
                type: TokensPaymentEnum.STRIPE,
                token: stripeAuthentications

            };
        }
        if (pageFixPaymentDeferredError) {
            invoice = await this.fixPaymentDeferredError(<CreditCardPaiementRequest>checkoutCartRequest).toPromise();
            this.deleteCartDeferred(orderResponse);
            return invoice;
        } else {
            invoice = await this.payment(<CheckoutCartRequest>checkoutCartRequest).toPromise();
            this.deleteCart(<CheckoutCartRequest>checkoutCartRequest, invoice.saleId, invoice.deferred);
            if ('amount' in checkoutCartRequest) {
                const amount = (<CheckoutCartRequest>checkoutCartRequest).amount;
                AnalyticsService.zendataTrackPurchase(amount, invoice.crId);
            }
            if (configWebsite.clientAccount === 'NO') {
                try {
                    this.clientService.logoutClient(configWebsite.accountCode).subscribe(() => {
                    });
                } catch (e) {

                }
            }
            return invoice;
        }
    }

    deleteCart(checkoutCartRequest: CheckoutCartRequest, saleId?: string, deferred?: string) {
        try {
            this.actionCartService.actionRequest(<ActionCartRequest>{type: EnumTypeActionCart.callCart});
            const cart = this.cartService.cartSub.value;
            const shippingSelected = <ShippingModeRest>this.shippingService.getShippingSelectedLocal();
            const invoiceConfirmation = <InvoiceConfirmation>{
                client: checkoutCartRequest.client,
                shipping: shippingSelected,
                tax: cart.tax,
                cartElements: cart.cartElements,
                date: new Date(),
                saleId: saleId || null,
                deferred: deferred || false
            };
            this.addDetailInvoice(invoiceConfirmation);
            this.cartService.deleteAll(cart.cartElements).subscribe(() => {

            });
        } catch (e) {

        }

    }
    deleteCartColts(checkoutCartRequest: CheckoutCartRequest, saleId?: string, deferred?: string) {
        try {
            const cart = this.cartService.cartSub.value;
            const shippingSelected = <ShippingModeRest>this.shippingService.getShippingSelectedLocal();
            const invoiceConfirmation = <InvoiceConfirmation>{
                client: checkoutCartRequest.client,
                shipping: shippingSelected,
                tax: cart.tax,
                cartElements: cart.cartElements,
                date: new Date(),
                saleId: null,
                deferred: false,
                coltsConfirmation: true
            };
            this.addDetailInvoice(invoiceConfirmation);
            this.cartService.cartSub.next(null);

        } catch (e) {

        }

    }

    deleteCartDeferred(paymentResponse: PaymentResponse) {
        try {
            this.actionCartService.actionRequest(<ActionCartRequest>{type: EnumTypeActionCart.callCart});
            const cart = this.cartService.cartSub.value;
            const invoiceConfirmation = <InvoiceConfirmation>{
                client: paymentResponse.clientResponse,
                shipping: paymentResponse.shippings[0],
                tax: paymentResponse.cart.tax,
                cartElements: paymentResponse.cart.cartElements,
                date: new Date(),
                saleId: String(paymentResponse.saleId),
                deferred: false
            };
            this.addDetailInvoice(invoiceConfirmation);

            this.cartService.deleteAll(paymentResponse.cart.cartElements).subscribe(() => {

            });
        } catch (e) {

        }


    }

    isCreditCardPaiementRequest(pet: CheckoutCartRequest | CreditCardPaiementRequest): pet is CreditCardPaiementRequest {
        return (pet as CreditCardPaiementRequest).accessToken !== undefined;
    }

    getGift(barcode): Observable<GiftCertificateResponse> {
        const httpParams = new HttpParams().set('cache','0')
        return this.http.get<GiftCertificateResponse>('v1/certificates/' + barcode,{ params: httpParams });
    }

    getGoogleWalletLink(link: string) {
        return this.http.get(link, {responseType: 'text'});
    }

    getAppleWalletLink(link: string) {
        return this.http.get(link, {responseType: 'blob'});
    }

}
