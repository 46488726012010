import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Optional,
    Output,
    PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import { DrawCalendarService } from '../../services/draw-calendar.service';
import { Items } from '../../../models/items';
import { HelpsService } from '../../services/helps.service';
import { CalendarObserverService } from '../../services/calendar-observer.service';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import {
    DOCUMENT,
    isPlatformBrowser,
    registerLocaleData,
} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ItemHour } from '../../../models/item-hour';
import {
    MessageAction,
    MessageObserverService,
} from '../../services/message-observer.service';
import { Router } from '@angular/router';
import { Item } from '../../../models/item';
import { PageSaleService } from '../../../page/services/page-sale.service';
import { Page } from '../../../models/page';
import {
    animate,
    keyframes,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'tw-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css'],
    animations: [
        trigger('bounceDiv', [
            state('initial', style({})),
            state('active', style({})),
            transition('* => active', [
                animate(
                    '0.2s',
                    keyframes([
                        style({
                            transform: 'scale(0.3) translate3d(0,0,0)',
                            opacity: 0,
                        }),
                        style({
                            transform: 'scale(1) translate3d(0,0,0)',
                            opacity: 1,
                        }),
                    ]),
                ),
            ]),
        ]),
    ],
})
export class CalendarComponent implements OnInit, AfterViewInit {
    @Input() items: [Items];
    @Input() pageId: [Items];
    @Output() itemsSelected = new EventEmitter<Items[]>();
    @Input() daySelected: Date = new Date();
    dayActive: Date;
    month: any[];
    @Input() oldFlow = false;
    dayByItems: any[] = [];
    showPoint = false;
    itemsSelectWj: any;
    styleEventPopop: any;
    _eventSelected: {
        event: Item;
        week: any | { i: any; indexDay: any };
    };
    bounceDivState = 'initial';

    @ViewChild('dialog') templatesDialog;
    hourSelect: ItemHour;
    loading = false;

    constructor(
        private drawCalendarService: DrawCalendarService,
        private calendarObserverService: CalendarObserverService,
        public translate: TranslateService,
        private router: Router,
        private pageSaleService: PageSaleService,
        private messageObserverService: MessageObserverService,
        public dialog: MatDialog,
        @Optional() @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {
        registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
    }

    ngOnInit() {
        if (!this.daySelected) {
            this.daySelected = new Date();
        }
        //console.log('calendare =======================');
        //  this.daySelected = new Date();
        this.getMonthCurrent();
        this.monthCurrent();
        this.dayActive = null;
        this.calendarObserverService.pageResponse.subscribe((val) => {
            if (val?.page?.id === this.pageId) {
                //this.getMonthCurrent();
                this.items = (<Page>val?.page).itemsDates.items;
                this.monthCurrent();
                setTimeout(() => {
                    this.setLoading(false);
                },500)

            }
        });
    }

    nextMonth() {
        this.setLoading(true);
        this._eventSelected = null;
        this.incrementMonth(1);
        this.monthCurrent();


    }

    previousMonth() {
        this.setLoading(true);
        this._eventSelected = null;
        this.incrementMonth(-1);
        this.monthCurrent();


    }

    private incrementMonth(delta: number): void {
        //console.log(this.daySelected.getMonth(), this.daySelected.getDate() )
        this.daySelected = new Date(
            this.daySelected.getFullYear(),
            this.daySelected.getMonth() + delta,
            1,
        );
        const newDate = new Date(this.daySelected);

        this.calendarObserverService.addCalendarCurrent(newDate, this.pageId);

    }
    setLoading(val: boolean){
        this.loading = val;
    }

    monthCurrent() {
        this.month = this.drawCalendarService.getMonthDates(this.daySelected);
        this.dayByItems = [];
        //console.log(this.items.length);
        this.month.forEach((value) => {
            const d = [];
            value.forEach((day) => {
                // console.log('*******************====' + day);
                if (!day) {
                    d.push({
                        day: day,
                        items: [],
                    });
                } else {
                    let items = this.getEventsByDate(day);
                    items = items.filter(
                        (value1) => value1.saleState !== 'INVISIBLE',
                    );
                    if (!this.showPoint) {
                        // console.log(items.length, this.showPoint);
                        if (items && items.length >= 4) {
                            this.showPoint = true;
                        }
                    }

                    d.push({
                        day: day,
                        items: items,
                    });
                }

                // this.dayByItems.push({
                //     day: day, items: items
                // });
            });
            this.dayByItems.push(d);
        });
        //console.log(this.dayByItems);
    }

    selectDate(day) {
        const state = this.getState(this.finDate(day));
        // console.log(state);
        if (state !== 'ONSALE') {
            return;
        }

        this.daySelected = new Date(day);
        this.dayActive = new Date(day);
        const itemsSelected = this.finDate(this.dayActive);
        if (itemsSelected.length === 0) {
            this.daySelected = null;
            this.dayActive = null;
            this.itemsSelected.emit(<Items[]>{});
            return;
        }

        this.calendarObserverService.addDateSelected(
            new Date(day),
            this.pageId,
        );
        this.itemsSelected.emit(itemsSelected);
    }

    getEventsByDate(day): Item[] {
        this.dayActive = new Date(day);
        const itemsSelected = <Items[]>this.finDate(this.dayActive);
        if (itemsSelected && itemsSelected.length > 0) {
            return itemsSelected[0].dates;
        }
        return [];
    }

    getMonthCurrent() {
        if (!this.items) {
            return;
        }
        const listDate = this.items
            .filter((it) => {
                let t = it.dates.map((d) => {
                    if (d.saleState === 'ONSALE') {
                        return d;
                    }
                    return d;
                });
                if (!t[0]) {
                    t = it.dates.map((d) => {
                        return d;
                    });
                    if (!t[0]) {
                        return;
                    }

                    return true;
                }
                return true;
            })
            .map((it) => {
                return this.getFormatToDate(it);
            })
            .sort((a: Date, b: Date) => {
                return a.getTime() - b.getTime();
            });
        if (listDate.length > 0) {
            if (
                !this.daySelected ||
                listDate[0].getTime() > this.daySelected.getTime()
            ) {
                this.daySelected = listDate[0];
            }
        }
    }

    finDate(date: Date): Items[] {
        if (!this.items) {
            return [];
        }
        return this.items
            .map((it) => {
                const dt = this.getFormatToDate(it);
                const nedate = new Date(date);

                if (
                    nedate.getMonth() === 8 &&
                    nedate.getDate() > 6 &&
                    nedate.getDate() < 8
                ) {
                    // console.log(HelpsService.dateEqual(dt, nedate));
                    // console.log(dt, nedate, date, it);
                }

                if (HelpsService.dateEqual(dt, nedate)) {
                    return it;
                }
            })
            .filter((mt) => mt);
    }

    getFormatToDate(it) {
        const val = it.date.substring(0, 10);
        const temp = val.split('-');
        // @ts-ignore
        const dt = new Date(temp[0], temp[1] - 1, temp[2]);
        return dt;
    }

    getClassCssForState(day: Date): string {
        const state = this.getState(this.finDate(day));
        return this.getClassCss(state);
    }

    isActiveDay(day: Date) {
        if (!day || !this.dayActive) {
            return false;
        }
        return this.dayActive.toString() === day.toString();
    }

    getState(obj: Items[]) {
        if (!obj || obj.length === 0) {
            return;
        }
        const temp = [];
        const eventdate = obj[0].dates;
        // console.log(eventdate);
        eventdate.map((event) => {
            if (event.saleState === 'ONSALE') {
                temp[0] = event.saleState;
            }
            if (event.saleState === 'SOLDOUT') {
                temp[1] = event.saleState;
            }
            if (
                event.saleState === 'WASONSALE' ||
                event.saleState === 'NOTAVAILABLE' ||
                event.saleState === 'CANCELLED'
            ) {
                temp[2] = event.saleState;
            }
        });
        for (const val2 in temp) {
            if (temp[val2]) {
                return temp[val2];
            }
        }
    }

    getClassCss(state): string {
        if (!state) {
            return 'wlt-calendar-background';
        }
        switch (state) {
            case 'ONSALE':
                return 'wlt-calendar-onsale pointer';
            case 'SOLDOUT':
                return 'wlt-calendar-soldout';
            case 'WASONSALE':
                return 'wlt-calendar-wasonsale';
            case 'NOTAVAILABLE':
                return 'wlt-calendar-wasonsale';
            case 'CANCELLED':
                return 'wlt-calendar-wasonsale';
            default:
                return 'wlt-calendar-background';
        }
    }

    ngAfterViewInit(): void {

    }

    gotoPage(eventId) {
        //console.log('ssssssssssssssssssssssssss');
        if (this.pageSaleService.page) {
            const t = window.location.pathname;
            const ar = t.split('pages/');
            // console.log(t.split('pages/'));
            const u = ar[0] + 'pages/' + this.pageSaleService.page.id;
            this.router
                .navigate([`${u}/performances/${eventId}`], {
                    queryParamsHandling: 'merge',
                })
                .catch((error) => {
                    // console.error(error);
                });
        } else {
            this.router
                .navigate(
                    [`${window.location.pathname}/performances/${eventId}`],
                    { queryParamsHandling: 'merge' },
                )
                .catch((error) => {
                    // console.error(error);
                });
        }

        // this.router.navigate([`${window.location.pathname}/performances/${eventId}`], { queryParamsHandling: 'merge' })
        //     .catch(error => {
        //         console.error(error);
        //     });
    }

    getSelectCurrent(event: any) {
        this.itemsSelectWj = event;
        this.applayChange();
    }

    goBack() {
        this.itemsSelectWj.showListEvents = false;
        this.itemsSelectWj = null;
    }

    applayChange() {
        const el = document.querySelector('.cl-month-container');

        // console.log(document.querySelector(".cl-month-container").getBoundingClientRect())
        // @ts-ignore
        if (el) {
            const t = <DOMRect>el.getBoundingClientRect();
            const st = {
                width: t.width - 15 + 'px',
                height: t.height + 'px',
            };
            // console.log(st);
            this.styleEventPopop = st;
        }
    }

    eventIdSelect(_eventIdSelect: any) {
        this.bounceDivState = 'initial';
        this._eventSelected = _eventIdSelect;
        this.bounceDivState = 'active';
    }

    goToEvent(event: any) {
        this.hourSelect = null;
        this.goToEventOrHour(event);
    }

    selectHours(event, h: ItemHour) {
        console.log(event);
        this.hourSelect = null;
        if (h.saleState !== 'ONSALE') {
            return;
        }
        this.goToEventOrHour(event, h);
    }

    goToEventOrHour(event, h?: ItemHour) {
        if (isPlatformBrowser(this.platformId)) {
            /**
             * OASIS POPUP POUR LE VENTS HARDCODE CSS  = ALT
             *
             * */
            this.hourSelect = h;
            const cls = `bt-calendar-text-override-${event.showId}-${this.translate.currentLang}`;
            const elm = this.document.querySelector('.' + cls);
            if (elm) {
                const text: string = window.getComputedStyle(
                    elm,
                    ':after',
                ).content;
                if (text && text.search('Alt') !== -1) {
                    console.log(text); // Affiche "Texte injecté par CSS"
                    const daH = this.dialog.open(this.templatesDialog, {
                        width: '400px',
                    });
                    daH.afterClosed().subscribe((result) => {
                        console.log(`Dialog result: ${result}`);
                        if (result && result === 'ok') {
                            this.goHourOrEventPage(event, h);
                        }
                    });
                    return;
                }
            }
        }
        this.goHourOrEventPage(event, h);
    }

    goHourOrEventPage(event, h) {
        if (h) {
            this.router
                .navigate(
                    [`${window.location.pathname}/performances/${event.code}`],
                    {
                        queryParams: {
                            hour: h.expositionId,
                        },
                        queryParamsHandling: 'merge',
                    },
                )
                .catch((error) => {
                    //  console.error(error);
                });
        } else {
            this.router
                .navigate(
                    [`${window.location.pathname}/performances/${event.id}`],
                    { queryParamsHandling: 'merge' },
                )
                .catch((error) => {
                    //  console.error(error);
                });
        }
    }

    showNote(h: any) {
        this.messageObserverService.addMessage(<MessageAction>{
            dialog: true,
            message: h.note,

            translate: false,
        });
    }
}
