@if (ticketElement) {
  <tw-client-ticket-header [ticketElement]="ticketElement" (click)="displayDetails=!displayDetails"
  [displayDetails]="displayDetails"></tw-client-ticket-header>
}
@if (displayDetails) {
  <div>
    @if (ticketElement.location) {
<div twHideByEnvironment environment="feq mta" style="font-size: 16px;
                              padding-left: 11px;
                              padding-top: 8px;">
        @if (ticketElement.location.addressLine1) {
          <span> {{ticketElement.location.addressLine1}}</span>
        }
        @if (ticketElement.location.addressLine2) {
          <span> {{ticketElement.location.addressLine2}}</span>
        }
        @if (ticketElement.location.city) {
          <span> {{ticketElement.location.city}},</span>
        }
        @if (ticketElement.location.provinceState) {
          <span> {{ticketElement.location.provinceState}},</span>
        }
        @if (ticketElement.location.postalCode) {
          <span> {{ticketElement.location.postalCode}}</span>
        }
        &nbsp;
        <a href="{{ticketElement.location | twGoogleMaps}}" target="_blank"><img
          src="./assets/resources/svg/map_marker.svg"
        style="height: 16px;" alt="marker"></a>
      </div>
    }
    @for (mail of getMailTypes(); track mail) {
      <div>
        @if (ticketElement.pricesByMail[mail]) {
          <div>
            <strong>{{'client.tickets.' + mail | translate}}</strong>
            @for (pr of ticketElement.pricesByMail[mail]; track pr; let ip = $index) {
              <tw-ticket-details [ticketPrices]="[pr]" (displayPdf)="displayPdfFile($event)"></tw-ticket-details>
              @if (ticketElement.prices.length !== ip+1) {
                <hr>
                }
              }
              <div class="ticket-detail-buttons" align="center">
                @if (ticketElement.pricesByMail[mail][0].rs) {
                  <button class="tw-primary" mat-button color="primary"
                    (click)="detailSeats(mail)">
                    <mat-icon aria-label="view seats" style="vertical-align:middle;">visibility</mat-icon>
                    <span style="margin-left: 5px">{{"client.tickets.viewSeats" | translate}}</span>
                  </button>
                }
                @if (ticketElement.pricesByMail[mail][0].printable) {
                  <button class="tw-primary" mat-button color="primary"
                    (click)="printTickets(mail)">
                    <mat-icon aria-label="print tickets" style="vertical-align:middle;">print</mat-icon>
                    <span style="margin-left: 5px">{{"client.tickets.printTickets" | translate}}</span>
                  </button>
                }
                @if (ticketElement.pricesByMail[mail][0].addToGoogleWalletLink) {
                  <button mat-button [disableRipple]="true"
                    (click)="addToGoogleWallet(mail)"
                    >
                    <img [src]="'assets/resources/svg/add_to_google_wallet_' + getCurrentLanguage() + '.svg'"
                      alt="google wallet"
                      class="wallet-svg"
                      >
                  </button>
                }
                @if (ticketElement.pricesByMail[mail][0].addToAppleWalletLink) {
                  <button mat-button [disableRipple]="true"
                    (click)="addToAppleWallet(mail)"
                    >
                    <img [src]="'assets/resources/svg/add_to_apple_wallet_' + getCurrentLanguage() + '.svg'"
                      alt="apple wallet"
                      class="wallet-svg"
                      >
                  </button>
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  }
