import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonFloatingOrderComponent } from './button-floating-order/button-floating-order.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';
import { PipeModule } from '../../../projects/shared-lib/src/lib/pipe/pipe.module';



@NgModule({
    declarations: [ButtonFloatingOrderComponent],
    exports: [ButtonFloatingOrderComponent],
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule,
        MatButtonModule,
        MatProgressBar,
        PipeModule,
    ],
})
export class ButtonFloatingOrderModule {}
