import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IconEnregistreService {
    iconNames= ['seats3-24px','tickets-24px','seatSetting_24x24']
    url=`./assets/resources/svg/`
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private httpClient: HttpClient
    ) { }

    checkIfSvgExists(iconName: string): Observable<boolean> {
        return this.httpClient.get(`${this.url}${iconName}.svg`, { observe: 'response' ,responseType: 'text' })
            .pipe(
                map(response => response.status === 200)
            );
    }

    async registerSvgIcon(iconName: string): Promise<void> {
        const exists = await lastValueFrom(this.checkIfSvgExists(iconName)) ;
        if (exists) {
            this.matIconRegistry.addSvgIcon(
                iconName,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`./assets/resources/svg/${iconName}.svg`)
            );
            console.log(`L'icône ${iconName} existe .`);
        } else {
            console.error(`L'icône ${iconName} n'existe pas.`);
        }
    }

    async registerAllSvgIcons(url?: string, icons: any[] = []): Promise<void> {
        try {
            if (url) {
                this.url = url;
            }
            if (icons.length > 0) {
                this.iconNames = icons;
            }

            await Promise.all(this.iconNames.map(iconName => this.registerSvgIcon(iconName)));
        }catch (e) {
            console.error(e)
        }

    }
}
