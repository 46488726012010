import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SubdomainService} from './subdomain.service';
import {WebsiteSaleService} from './website-sale.service';
import {GtmDataLayer, GtmEcommerceEvent, GtmEvent} from '../../models/gtm-data-layer';
import {PriceWeb} from '../../../../projects/shared-lib/src/lib/models/price-web';
import {TranslateService} from '@ngx-translate/core';
import {ClientService} from '../../client/client.service';
import {CartPrice} from '../../../../projects/shared-lib/src/lib/models/cart-price';
import {TrackerPrice} from '../../models/tracker-price';
import {CartElement} from '../../../../projects/shared-lib/src/lib/models/cart-element';

const PRODUCTS_PER_PAYLOAD = 10;
@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    charset = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    constructor(private http: HttpClient,
                public subdomainService: SubdomainService,
                public websiteSaleService: WebsiteSaleService,
                private translate: TranslateService,
                private clientService: ClientService) {
    }

    static zendataTrackPurchase(amount: number, c_rid: string) {
        try {
            if (c_rid) {
                const zendataImage = document.createElement('img');
                zendataImage.setAttribute('width', '0');
                zendataImage.setAttribute('height', '0');
                zendataImage.setAttribute('id', 'carmaRoi');
                zendataImage.setAttribute('src', `https://www5.carmamail.com/mail/roi?ts=${Math.floor(Math.random() * 1000000)}&rid=${c_rid}&amount=${amount / 100}`);
                document.getElementsByTagName('body')[0].appendChild(zendataImage);
            }
        } catch (e) {
        }
    }

    static sanitizeDataLayer() {
        window['dataLayer'] = window['dataLayer'] || [];
        const dl: GtmDataLayer = {
            event: undefined,
            eventAction: undefined,
            eventLabel: undefined,
            eventCategory: undefined,
            userId: undefined,
            ecommerce: undefined,
            pageTitle: undefined,
            pageLanguage: undefined,
            pageCategory: undefined
        };

        if (!AnalyticsService.hasInitEvent()) {
            window['dataLayer'].push(dl);
        }
    }

    static pushWithPreviousDataLayer(dataLayer: GtmDataLayer) {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push(dataLayer);
    }

    static initWithPreviousDataLayer() {
        window['dataLayer'] = [];
    }

    // Prevents previously pushed values from being found in the following datalayer push
    static safePushToDataLayer(dataLayer: GtmDataLayer) {
        AnalyticsService.sanitizeDataLayer();
        if (!AnalyticsService.hasPageTitleAndUrl(dataLayer)) {
            window['dataLayer'].push(dataLayer);
        }
    }

    static hasPageTitleAndUrl(dataLayer) {
        let bool = false;
        window['dataLayer'].forEach(value => {
            if (value?.pageTitle === dataLayer.pageTitle && value?.pageUrl === dataLayer.pageUrl && value?.event === dataLayer.event) {
                bool = true;
            }
        });
        return bool;
    }

    static hasInitEvent() {
        let bool = false;
        window['dataLayer'].forEach(value => {
            if (value?.event === undefined) {
                bool = true;
            }
        });
        return bool;
    }

    trackProductViewFromPriceWeb(pricesSelected: PriceWeb[]) {
        this.trackProductView(this.priceWebToTrackerPrice(pricesSelected));
    }

    getSystemGtms() {
        const url = `v1/tracker/systemgtm`;
        return this.http.get<string[]>(url, {});
    }

    trackCurrentProductPage(ecommerceEvent: GtmEcommerceEvent, saleId?) {
        this.getTrackersFromApiForCurrentPage(saleId).subscribe(dl => {
            const tmp: GtmDataLayer = JSON.parse(JSON.stringify(dl));
            if(tmp){
                tmp.ecommerce = {};
                tmp.ecommerce[ecommerceEvent] = dl.ecommerce;
                this.safePushProductDataLayer(tmp)
            }
        });
    }

    trackAddToCart(pricesSelected: PriceWeb[]) {
        this.trackCartEvent(this.priceWebToTrackerPrice(pricesSelected), GtmEvent.ADD_TO_CART, GtmEcommerceEvent.ADD, 'addToCart');
    }

    trackRemoveFromCartFromCartPrice(pricesSelected: CartPrice[]) {
        this.trackRemoveFromCart(this.cartPriceToTrackerPrice(pricesSelected));
    }

    trackCartTimeOutFromCartPrice(pricesSelected: CartPrice[]) {
        this.trackCartTimeOut(this.cartPriceToTrackerPrice(pricesSelected));
    }

    trackBeginCheckout(cartElements: CartElement[]) {
        this.trackCheckoutStep(this.toCartPrice(cartElements), GtmEvent.BEGIN_CHECKOUT);
    }

    toCartPrice(cartElements: CartElement[]) : CartPrice[] {
        const prices : CartPrice[] = [];
        cartElements?.forEach(cartElement => {
            cartElement.prices.forEach(price => {
                    prices.push(price)
                }
            )
        });
        return prices
    }

    trackAddShippingInfo = (cartElements: CartElement[], shipping : string) => {
        this.trackCheckoutStep(this.toCartPrice(cartElements), GtmEvent.ADD_SHIPPING_INFO, 'shipping_tier', shipping);
    };

    trackAddPaymentInfo(cartElements: CartElement[], cardBrand : string) {
        this.trackCheckoutStep(this.toCartPrice(cartElements), GtmEvent.ADD_PAYMENT_INFO, 'payment_type', cardBrand);
    }

    trackCheckoutStep(pricesSelected: CartPrice[], gtmEvent: GtmEvent, key?: string, value? : string) {
        const url = `v1/tracker/productforperformance?subdomain=${this.subdomainService.getSubdomain()}&isfullproducts=true`;
        this.http.post(url, pricesSelected).subscribe(products => {
            const ecommerce = {};
            ecommerce['checkout'] = {};
            ecommerce['checkout'].products = products;
            if (key && value) {
                ecommerce['checkout'][key] = value
            }
            const dl: GtmDataLayer = {
                event: gtmEvent,
                userId: this.clientService.getConnectedClientId(),
                ecommerce
            };

            AnalyticsService.sanitizeDataLayer();
            AnalyticsService.pushWithPreviousDataLayer(dl);
        });
    }

    trackContinueShopping() {
        const dl: GtmDataLayer = {
            event: GtmEvent.CLICK,
            eventAction: 'click',
            eventLabel: this.translate.instant('cart.continueShopping'),
            eventCategory: 'cart',
            userId: this.clientService.getConnectedClientId()
        };
        AnalyticsService.safePushToDataLayer(dl);
    }

    trackLogout() {
        const dl: GtmDataLayer = {
            event: GtmEvent.CLICK,
            eventAction: 'click',
            eventLabel: this.translate.instant('title.logout'),
            eventCategory: 'logout',
            userId: this.clientService.getConnectedClientId()
        };
        AnalyticsService.safePushToDataLayer(dl);
    }

    trackCartView(pricesSelected: CartPrice[]) {
       // this.trackSimplePageView('view cart', 'ecommerce');
        this.trackCartPageViewEvent(this.cartPriceToTrackerPrice(pricesSelected), GtmEcommerceEvent.DETAIL, 'view_cart');
    }

    trackLoginView() {
        this.trackSimplePageView('login', 'account');
    }

    trackSignupView() {
        this.trackSimplePageView('signup', 'account');
    }

    trackForgotPasswordView() {
        this.trackSimplePageView('forgot password', 'account');
    }

    trackProfileView() {
        this.trackSimplePageView('profile', 'account');
    }

    trackInvoicesView() {
        this.trackSimplePageView('my invoices', 'account');
    }

    trackUpcomingTicketsView() {
        this.trackSimplePageView('tickets', 'account');
    }

    trackChangePasswordView() {
        this.trackSimplePageView('change password', 'account');
    }

    trackHomePage() {
        this.trackSimplePageView('home', 'general');
    }

    trackCartEvent(pricesSelected: TrackerPrice[], gtmEvent: GtmEvent, event: GtmEcommerceEvent, eventLabel: string) {
        const url = `v1/tracker/productforperformance?subdomain=${this.subdomainService.getSubdomain()}&isfullproducts=true`;
        this.http.post(url, pricesSelected).subscribe(products => {
            const ecommerce = {};
            ecommerce[event] = {};
            ecommerce[event].products = products;
            const dl: GtmDataLayer = {
                event: gtmEvent,
                eventAction: 'click',
                eventLabel: eventLabel,
                eventCategory: 'cart',
                userId: this.clientService.getConnectedClientId(),
                ecommerce
            };
            AnalyticsService.sanitizeDataLayer();
            AnalyticsService.pushWithPreviousDataLayer(dl);
        });
    }

    trackCartPageViewEvent(pricesSelected: TrackerPrice[], event: GtmEcommerceEvent, eventLabel: string) {
        const url = `v1/tracker/productforperformance?subdomain=${this.subdomainService.getSubdomain()}&isfullproducts=true`;
        this.http.post(url, pricesSelected).subscribe(products => {
            const ecommerce = {};
            ecommerce[event] = {};
            ecommerce[event].products = products;
            const dl: GtmDataLayer = {
                pageTitle: 'cart',
                event: GtmEvent.CART,
                eventAction: 'view',
                eventLabel: eventLabel,
                eventCategory: 'cart',
                userId: this.clientService.getConnectedClientId(),
                ecommerce
            };
            this.safePushProductDataLayer(dl);
        });
    }

    getTrackersFromApiForCurrentPage(saleId?) {
        try {
            let temp = btoa(window.location.href);
            temp = temp.split('/').join('_');
            temp = temp.split('+').join('-');
            let url = `v1/tracker/datalayer/${temp}?subdomain=${this.subdomainService.getSubdomain()}&accountId=${this.websiteSaleService.getLocalConfig().accountId}`;
            const dataForm = {};
            if (saleId) {
                url = `${url}&saleId=${saleId}`;
            }

            return this.http.get<GtmDataLayer>(url, dataForm);
        } catch (e) {

        }
    }

    trackSimplePageView(pageTitle: string, pageCategory: string) {
        const dl: GtmDataLayer = {
            pageTitle,
            pageCategory,
            pageLanguage: this.translate.currentLang,
            userId: this.clientService.getConnectedClientId(),
            event: GtmEvent.PAGE_VIEW
        };
        AnalyticsService.safePushToDataLayer(dl);
    }

    // Breaks the products into smaller payloads if in too large number because GTM has a size limit on their api calls
    private safePushProductDataLayer(dl: GtmDataLayer) {
        const ecommerceEvent = Object.keys(dl.ecommerce)[0];
        const products = dl.ecommerce[ecommerceEvent].products;
        if(products){
            const numberOfDataLayer = Math.ceil(products.length / PRODUCTS_PER_PAYLOAD);
            for (let page = 0; page < numberOfDataLayer; ++page) {
                dl.ecommerce[ecommerceEvent].products = products.slice(page * PRODUCTS_PER_PAYLOAD, (page + 1) * PRODUCTS_PER_PAYLOAD);
                //AnalyticsService.safePushToDataLayer(dl);
                AnalyticsService.sanitizeDataLayer();
                window['dataLayer'].push(dl);
            }
        }
    }

    private cartPriceToTrackerPrice(pricesSelected: CartPrice[]): TrackerPrice[] {
        return pricesSelected?.map(price => {
            return <TrackerPrice>{
                eventId: price.eventId,
                priceType: price.priceType,
                priceLevel: price.priceLevel,
                qty: price.cartDetailToken.length,
                price: price.price,
                promoId: price.promoId,
                parkingId: price.parkingId,
                priceId: price.id
            };
        });
    }

    private priceWebToTrackerPrice(pricesSelected: PriceWeb[]): TrackerPrice[] {
        return pricesSelected.map(price => {
            return <TrackerPrice>{
                eventId: price.eventId,
                priceType: price.priceType,
                priceLevel: price.priceLevel,
                qty: price.qty,
                price: price.price,
                promoId: price.promoId,
                parkingId: price.parkingId,
                priceId: price.id
            };
        });
    }

    private trackCartTimeOut(pricesSelected: TrackerPrice[]) {
        const url = `v1/tracker/productforperformance?subdomain=${this.subdomainService.getSubdomain()}&isfullproducts=true`;
        this.http.post(url, pricesSelected).subscribe(products => {
            const dl: GtmDataLayer = {
                event: GtmEvent.CART,
                eventAction: 'timeout',
                eventCategory: 'cart',
                userId: this.clientService.getConnectedClientId(),
                ecommerce: {
                    remove: {
                        products
                    }
                }
            };
            this.safePushProductDataLayer(dl);
        });
    }

    private trackProductView(pricesSelected: TrackerPrice[]) {
        const url = `v1/tracker/productforperformance?subdomain=${this.subdomainService.getSubdomain()}&isfullproducts=false`;
        this.http.post(url, pricesSelected).subscribe(products => {
            const dl: GtmDataLayer = {
                event: GtmEvent.PRODUCT_DETAILS,
                pageCategory: 'ecommerce',
                pageTitle: 'product view',
                userId: this.clientService.getConnectedClientId(),
                ecommerce: {
                    detail: {
                        products
                    }
                }
            };
            this.safePushProductDataLayer(dl);
        });
    }

    private trackRemoveFromCart(pricesSelected: TrackerPrice[]) {
        this.trackCartEvent(pricesSelected, GtmEvent.REMOVE_FROM_CART, GtmEcommerceEvent.REMOVE, 'removeFromCart');
    }

    trackViewPromotion(pricesSelected: TrackerPrice[] | CartPrice[] | PriceWeb[], promo : string) {
        const url = `v1/tracker/productforperformance?subdomain=${this.subdomainService.getSubdomain()}&isfullproducts=true`;
        this.http.post(url, pricesSelected).subscribe(products => {
            const ecommerce = {};
            ecommerce['detail'] = {};
            ecommerce['detail'].products = products;
            ecommerce['detail']['promotion_name'] = promo;
            const dl: GtmDataLayer = {
                event: GtmEvent.VIEW_PROMOTION,
                userId: this.clientService.getConnectedClientId(),
                ecommerce
            };
            AnalyticsService.sanitizeDataLayer();
            AnalyticsService.pushWithPreviousDataLayer(dl);
        });
    }

    trackItemSelection(pricesSelected: TrackerPrice[] | CartPrice[]) {
        let event = GtmEvent.SELECT_ITEM
        const url = `v1/tracker/productforperformance?subdomain=${this.subdomainService.getSubdomain()}&isfullproducts=true`;
        this.http.post(url, pricesSelected).subscribe(products => {
            const ecommerce = {};
            ecommerce['detail'] = {};
            ecommerce['detail'].products = products;
            if (products[0].coupon) {
                event = GtmEvent.SELECT_PROMOTION
                ecommerce['detail']['promotion_name'] = products[0].coupon;
            }
            const dl: GtmDataLayer = {
                event: event,
                userId: this.clientService.getConnectedClientId(),
                ecommerce
            };
            AnalyticsService.sanitizeDataLayer();
            AnalyticsService.pushWithPreviousDataLayer(dl);
        });
    }

    base62encode(paramToBeEncoded: number | string) {
        let integer = +paramToBeEncoded;
        if (integer === 0) {
            return 0;
        }
        let s = [];
        while (integer > 0) {
            s = [this.charset[integer % 62], ...s];
            integer = Math.floor(integer / 62);
        }
        return s.join('');
    }

    base62decode(chars: string | number) {
        return chars.toString().split('').reverse().reduce((prev, curr, i) =>
            prev + (this.charset.indexOf(curr) * (62 ** i)), 0);
    }

}
