@if (parking && parking.length >0) {
  <div class="tw-flex tw-column">
    @for (prk of parking; track prk; let i = $index) {
      <div class="tw-flex tw-column mat-elevation-z1" >
        <div class="tw-flex tw-row">

            @if (prk.logo_url) {
                <div style="flex-grow: 1; padding: 8px">
                    <img style="max-width: 66px" src="{{ prk.logo_url }}" />
                    <!--<ng-md-icon class="md-avatar"  icon="local_parking" style="fill:green" size="90"></ng-md-icon>-->
                </div>
            } @else {
                <div style="    flex-grow: 1;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;border-right: 1px rgba(128,128,128,0.38) solid">
                    <mat-icon>local_parking</mat-icon>
                </div>

            }
          <div class="tw-flex  tw-flex-flow-row-wrap" style="margin-top: 18px;flex-grow: 4 ">

              <div style="flex-grow: 4 ;margin: 5px;">
                <span>{{ prk.date | twDatePipe :'longDate'}}</span><br>
                <strong style="font-size: 20px">{{prk.name}} </strong>
                <p style="margin: 0;"> {{prk.address}}<br></p>
              </div>
              <div style="flex-grow: 6 ;margin: 5px;">
                <span> {{"parking.priceByDay" | translate}} </span><br>
                <span style="color: green; font-size: 23px "> {{prk.amount / 100 | currency :'':'symbol-narrow':'':translate.currentLang}} </span>
              </div>
          </div>
          @if (!isInCheckout) {
            <div style="padding: 26px;">
              <button class="tw-primary" mat-icon-button
                (click)="deleteParking(prk)">
                <mat-icon aria-label="info price">delete_forever</mat-icon>
              </button>
            </div>
          }
        </div>
      </div>
    }
  </div>
}
