import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ClientService } from '../../client/client.service';
import { XVerifyResponseContainer } from '../../models/client-xverify-response';
import { Observable, of } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';

const invalid = {
    validateEmail: {
        valid: false
    }
};

export function validateEmailFactory(email: string) {
    // const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    // tslint:disable-next-line:max-line-length
    const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return EMAIL_REGEXP.test(email) ? null : invalid;
}

@Directive({
    selector: '[twValidateEmail]',
    providers: [
        { provide: NG_ASYNC_VALIDATORS, useExisting: EmailValidatorDirective, multi: true }
    ]
})
export class EmailValidatorDirective implements AsyncValidator {
    ClientXVerifyResponse$: XVerifyResponseContainer;
    @Output() emailEmit = new EventEmitter<string[]>();
    @Input() moorValidation = true;
    emailTemp: string;

    constructor(private service: ClientService) {
    }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        const v = control.value;
        this.emailTemp = '';
        const result = validateEmailFactory(v);
        console.log(result);
        if (!this.moorValidation) {
            this.emailEmit.emit([]);
            return of(result);
        }
        /**  fra@gmail.com   result null **/
        if (result !== null) {
            if (this.emailTemp.search(v) === -1) {
                this.emailEmit.emit([]);
            }

            return of(result);
        }
        return this.service.mailcheckGet(btoa(v)).pipe(
            map((value) => {
                /**  fra@gmail.com   result null **/
               // console.log(value);
                if (value && value[0].email.status === 'valid') {
                    if (v === value[0].email.address) {
                        return null;
                    }//  this.email$.push(val);
                    this.emailTemp = value[0].email.address;
                    this.emailEmit.emit([this.emailTemp]);
                    return {
                        validateEmail: {
                            valid: false
                        }
                    };
                } else {
                   // console.log('invalid', value);
                    this.emailEmit.emit([]);
                    return {
                        validateEmail: {
                            valid: false
                        }
                    };
                }

                // return null;
            })
        );
    }
}
