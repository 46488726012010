<div style="margin: 0 auto;">
    @if (ticketPrice) {
        <div>
            @if (ticketPrice.upcomingTicketDetailTokens) {
                <div>
                    @if (ticketPrice.upcomingTicketDetailTokens) {
                        <div>
                            <!-- ***** GA and RS -->
                            <ng-container>
                                <div class="tw-ticket-line-container">
                                    <div class="tw-ticket-line-container-row" style="flex: 3 !important;">
                                        <div>
                                            {{ ticketPrice.priceLevel }} / {{ ticketPrice.priceType }}
                                        </div>
                                    </div>
                                    <div class="tw-ticket-line-container-row">
                                        <div style="text-align: right;padding-right: 10px;">
                                            {{ ticketPrice.upcomingTicketDetailTokens.length }} x
                                        </div>
                                    </div>
                                    <div class="tw-ticket-line-container-row" style="flex: 3;">
                                        <div style="text-align: right;">
                                            <div
                                                style="font-weight: bold;font-size: 16px">{{
                                                    (ticketPrice.serviceCharges + ticketPrice.price) / 100
                                                        | currency :'':'symbol-narrow':'':translate.currentLang
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tw-ticket-line-container-row"
                                         style=" flex: 0 !important; margin-top: -12px;position: relative">
                                        <div class="bi-tooltip">
                                            <template #viewInfoTaxPrice></template>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- ***** RS Only -->
                            @if (ticketPrice.rs) {
                                <div class="tw-flex tw-column ">
                                    <div class="tw-ticket-line-container" style="
                border-bottom: #0000000f 1px solid;
                margin: 0;
               max-width: 400px;
">
                                        <div class="tw-ticket-line-container-row tw-flex tw-row" style="gap: 10px;">
                                            @if (ticketPrice.upcomingTicketDetailTokens[0].seat.level) {
                                                <div class="tw-flex tw-column tw-seat"
                                                >
                                                    <div>
                                                        <strong>{{ "client.tickets.level" | translate }}</strong>
                                                    </div>
                                                </div>
                                            }
                                            @if (ticketPrice.upcomingTicketDetailTokens[0].seat.section) {
                                                <div class="tw-flex tw-column tw-seat"
                                                >
                                                    <div>
                                                        <strong>{{ "client.tickets.section" | translate }}</strong>
                                                    </div>
                                                </div>
                                            }
                                            @if (ticketPrice.upcomingTicketDetailTokens[0].seat.row) {
                                                <div class="tw-flex tw-column tw-seat"
                                                >
                                                    <div>
                                                        <strong>{{ "client.tickets.row" | translate }}</strong>
                                                    </div>
                                                </div>
                                            }
                                            @if (ticketPrice.upcomingTicketDetailTokens[0].seat.seat) {
                                                <div class="tw-flex tw-column tw-seat"
                                                >
                                                    <div>
                                                        <strong>{{ "client.tickets.seat" | translate }}</strong>
                                                    </div>
                                                </div>
                                            }

                                            @if (isPrintableCategory(ticketPrice.upcomingTicketDetailTokens[0].mailCategory)) {
                                                <div class="tw-flex tw-column tw-seat">
                                                    <div>
                                                        <strong>{{ "client.tickets.print" | translate }}</strong>
                                                    </div>
                                                </div>
                                            }
                                            @if (ticketPrice.giftable) {
                                                <div class="tw-flex tw-column tw-seat">
                                                    <div>
                                                        <strong>{{ "client.tickets.gift" | translate }}</strong>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    @for (rs of ticketPrice.upcomingTicketDetailTokens; track rs; let i = $index) {
                                        <div class="tw-ticket-line-container"
                                             style="margin: 0 4px; padding: 0;max-width: 400px;">
                                            <div class="tw-ticket-line-container-row tw-flex tw-row"
                                                 style="padding: 5px; gap: 10px;">
                                                @if (rs.seat.level) {
                                                    <div class="tw-flex tw-column tw-seat">
                                                        <div>
                                                            {{ rs.seat.level }}
                                                        </div>
                                                    </div>
                                                }
                                                @if (rs.seat.section) {
                                                    <div class="tw-flex tw-column tw-seat">
                                                        <div>
                                                            {{ rs.seat.section }}
                                                        </div>
                                                    </div>
                                                }
                                                @if (rs.seat.row) {
                                                    <div class="tw-flex tw-column tw-seat">
                                                        <div>
                                                            {{ rs.seat.row }}
                                                        </div>
                                                    </div>
                                                }
                                                @if (rs.seat.seat) {
                                                    <div class="tw-flex tw-column tw-seat">
                                                        <div>
                                                            {{ rs.seat.seat }}
                                                        </div>
                                                    </div>
                                                }
                                                @if (ticketPrice.printable) {
                                                    <div class="tw-flex tw-column tw-seat">
                                                        <button class="tw-primary" mat-icon-button color="primary"
                                                                (click)="printTicket(mail, i)">
                                                            <mat-icon aria-label="print tickets">print</mat-icon>
                                                        </button>
                                                    </div>
                                                }
                                                @if (ticketPrice.giftable) {
                                                    <div class="tw-flex tw-column tw-seat">
                                                        <button
                                                            class="{{ rs.giftRecipient ? 'tw-already-sent' : 'tw-gift-not-sent' }}"
                                                            mat-icon-button
                                                            (click)="openSendTicketDialog(rs.giftRecipient, rs.sodId)"
                                                        >
                                                            <mat-icon aria-label="gift ticket">redeem</mat-icon>
                                                            <span class="tooltip-text">
                                                                {{ rs.giftRecipient ? ("client.tickets.sendAnotherTicketHover" | translate : {email: rs.giftRecipient}) : ("client.tickets.sendTicketsHover" | translate) }}
                                                            </span>
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    }
</div>
