import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments';
import {AuthenticationRequestType, ChallengeWindowSize, getBrowserData, MethodUrlCompletion} from 'globalpayments-3ds';
import {CreditCard} from '../../models/credit-card';
import {ICheckVersionResponseData} from 'globalpayments-3ds/dist/types/interfaces';
import {lastValueFrom, Observable} from 'rxjs';
import {J} from '@angular/cdk/keycodes';
import {ClientService} from '../../client/client.service';
// import './globalpayments-3ds.js';


// import {MethodUrlCompletion} from 'globalpayments-3ds';

declare const paysafe;


// const {
//     checkVersion,
//     getBrowserData,
//     initiateAuthentication,
//     AuthenticationSource,
//     AuthenticationRequestType,
//     MessageCategory,
//     ChallengeRequestIndicator,
//     ChallengeWindowSize,
//     postToIframe,
//     handleInitiateAuthentication,
//     // @ts-ignore
// } = GlobalPayments.ThreeDSecure;
@Injectable({
    providedIn: 'root'
})
export class D3SecureService {
    methodNotificationUrl = environment.urlApi + 'http://127.0.0.1:9001/v1/threeDSecure/globalpayments/methodUrlResponse';
    merchantContactUrl = 'http://example.com/contact';
    challengeNotificationUrl = environment.urlApi + 'http://127.0.0.1:9001/v1/threeDSecure/globalpayments/challengeNotificationUrl';

    constructor(private http: HttpClient,
                private clientService: ClientService) {
    }

    start(cardBin: string, apikey: string, merchantAccountID: string): Promise<any> {
        const promise1 = new Promise((resolve, reject) => {
            const options = {
                environment: environment.production ? 'LIVE' : 'TEST',
                accountId: merchantAccountID,
                card: {
                    cardBin: cardBin
                }
            };
            console.log(options);
            paysafe.threedsecure.start(apikey, options, (deviceFingerprintingId, error) => {
                if (!error) {
                    resolve(deviceFingerprintingId);
                } else {
                    const error1 = {error: {error: {message: error}}};
                    reject(error);
                }
            });
        });
        return promise1;

    }

    challenge(sdkChallengePayload: string, apikey: string): Promise<any> {
        const promise1 = new Promise((resolve, reject) => {
            const options = {
                environment: environment.production ? 'LIVE' : 'TEST',
                sdkChallengePayload: sdkChallengePayload
            };
            paysafe.threedsecure.challenge(apikey, options, (id, error) => {
                if (!error) {
                    resolve(id);
                } else {
                    const error1 = {error: {error: {message: error}}};
                    reject(error);
                }
            });
        });
        return promise1;
    }

    async global(creditCard: CreditCard,amount) {

        creditCard.number = creditCard.number.replace(/\s/g, '');
        // @ts-ignore
        const versionCheckData = await GlobalPayments.ThreeDSecure.checkVersion(environment.urlApi + 'v1/threeDSecure/globalpayments/check3dsVersion', {
            methodNotificationUrl: this.methodNotificationUrl,
            card: {
                // @ts-ignore
                ...creditCard
            }
        });

        if (versionCheckData.status === 'NOT_ENROLLED' && versionCheckData.liabilityShift !== 'YES') {
            return  versionCheckData?.serverTransactionId;
        }
        if (versionCheckData.liabilityShift === 'YES') {
            // TODO: proceed with authorization
            return versionCheckData?.serverTransactionId;
        }

        console.log(versionCheckData);
        const authenticationData = await this.getInitiateAuthentication(creditCard, versionCheckData, amount);

        const serverTransactionId = authenticationData.serverTransactionId || authenticationData.challenge?.response?.data?.threeDSServerTransID || versionCheckData?.serverTransactionId;
        /*if(authenticationData && authenticationData.eci === "07"){

            const result = {
                code: 3333,
                error: authenticationData.result,
                serverTransactionId: versionCheckData?.serverTransactionId
            };
            const a= new Error(JSON.stringify(result))

            throw a;
        }*/
        return serverTransactionId;

    }

    async global2(creditCard: CreditCard) {
        try {
            const versionCheckData = await lastValueFrom(this.getCheckVersion({
                card: {
                    ...creditCard
                },
                amount: 100
            }));
            const ta = await lastValueFrom(this._threeDSSessionData(versionCheckData.methodUrl, versionCheckData.methodData));


            //  await this.getInitiateAuthentication(creditCard,versionCheckData)
            // const methodUrlResponse = await lastValueFrom(
            //     this.getMethodUrlResponseGlobalPayments( new HttpParams().set('threeDSMethodData' , versionCheckData.methodData)));
            //await postToIframe('',{name:'',value:''},{})
            // GlobalPayments.ThreeDSecure.handleMethodNotification(methodUrlResponse.result);
        } catch (e) {
            console.log(e);
        }


    }

    _threeDSSessionData(url, methodData: string) {
        return this.http.post<any>(url, {threeDSMethodData: methodData});
    }

    getAccessTokenGlobalPayments() {
        return this.http.get<any>(environment.urlApi + 'v1/threeDSecure/globalpayments/accesstoken');
    }

    getMethodUrlResponseGlobalPayments(val, params = new HttpParams()) {
        return this.http.post<any>(environment.urlApi + 'v1/threeDSecure/globalpayments/methodUrlResponse2', val, {
            params: params
        });
    }

    getCheckVersion(body): Observable<ICheckVersionResponseData> {
        return this.http.post<ICheckVersionResponseData>(environment.urlApi + 'v1/threeDSecure/globalpayments/check3dsVersion', body);
    }

    async getInitiateAuthentication(creditCard:CreditCard, versionCheckData, amount) {
        try {
            const client = await lastValueFrom(this.clientService.getClientSelf());
            // @ts-ignore
            const authenticateData = await GlobalPayments.ThreeDSecure.initiateAuthentication(environment.urlApi + 'v1/threeDSecure/globalpayments/initiateAuthentication', {
                browserData: getBrowserData(),
                merchantContactUrl: this.merchantContactUrl,
                challengeNotificationUrl: this.challengeNotificationUrl,
                challengeWindow: {
                    windowSize: ChallengeWindowSize.Windowed390x400,
                    displayMode: 'lightbox',
                },
                authenticationRequestType: AuthenticationRequestType.PaymentTransaction,
                serverTransactionId: versionCheckData.serverTransactionId,
                methodUrlComplete: MethodUrlCompletion.Yes,
                card: {
                    number: creditCard.number,
                    expMonth: creditCard.month,
                    expYear: creditCard.year,
                    cvn: creditCard.cvd,
                    cardHolderName: creditCard.nameOnCard,
                    ...creditCard
                },
                // @ts-ignore
                amount: amount,
                clientId: client.id
            });
            console.log(authenticateData);
            return authenticateData;
        } catch (e) {
            console.log(e);
        }
    }

}
