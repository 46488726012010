@if(totalToPay > 0 && typeModePayment){
    <div
        class=" tw-card-container  tw-padding-10 ">
        @if (typeModePayment && typeModePayment.giftcertificate && !hideGift) {
            <div style="flex-grow: 12;margin-left: 5px">
                <mat-form-field style="width: 100%;">
                    <mat-label>    {{""| libTranslateLang :"Gift Certificates":"Certificats cadeaux"}}</mat-label>
                    <input type="tel" matInput placeholder=""
                           [(ngModel)]="giftBarcode" #ctrl="ngModel"
                    >
                    <!--                        <mat-icon matSuffix>mode_edit</mat-icon>-->
                    <button matSuffix mat-raised-button color="primary" (click)="sendGift.emit(giftBarcode)">
                        <mat-icon matSuffix>send</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        }

        @if (typeModePayment.google_pay && !hideGoogleAnAppelPay) {
            <div>
                <lib-google-pay [cart]="cart" [totalShippingSelected]="totalShippingSelected"
                                (googlePay)="googleSend($event)"></lib-google-pay>
            </div>
        }
        @if (typeModePayment.apple_pay && !hideGoogleAnAppelPay) {
            <div>
                <lib-apple-pay [cart]="cart" [totalShippingSelected]="totalShippingSelected"
                               (applePay)="appleSend($event)"></lib-apple-pay>
            </div>
        }
        <mat-radio-group aria-label="Select an option" [(ngModel)]="choicePayment" (ngModelChange)="manageTypePayment()">

            <mat-radio-button color="primary" class="bt-radio-option card-radio-button"
                              [value]="typeModePaymentLabel.credit"><strong>{{ "title.carteCredit" | translate }}</strong>
            </mat-radio-button>
            @if (choicePayment === typeModePaymentLabel.credit) {
                <div class="scale-in-top tw-padding-10" [formGroup]="formGroupCard">
                    <div layout="row" style="margin-bottom: 12px;">
                        @if (typeModePayment.visa) {
                            <img class="wlt-svg-white svg"
                                 src="./assets/resources/svg/payment/visa.svg" style="height: 38px;padding: 2px" alt="visa">
                        }
                        @if (typeModePayment.mastercard) {
                            <img class="wlt-svg-white svg"
                                 src="./assets/resources/svg/payment/mastercard.svg" style="height: 38px;padding: 2px"
                                 alt="mastercard">
                        }
                        @if (typeModePayment.amex) {
                            <img class="wlt-svg-white svg"
                                 src="./assets/resources/svg/payment/amex.svg" style="height: 38px;padding: 2px" alt="amex">
                        }
                        @if (typeModePayment.discover) {
                            <img class="wlt-svg-white svg"
                                 src="./assets/resources/svg/payment/discover.svg" style="height: 38px;padding: 2px"
                                 alt="discover">
                        }
                    </div>
                    <mat-form-field class="tw-input-width" appearance="outline">
                        <mat-label>{{ "card.name_cardexp" | translate }}</mat-label>
                        <input matInput required formControlName="nameOnCard"
                               autocomplete='name'>
                        <mat-error>
                            <tw-form-error-message
                                [errors]="formGroupCard.controls['nameOnCard'].errors"></tw-form-error-message>
                        </mat-error>
                    </mat-form-field>
                    <div style="position: relative" class="tw-input-width" appearance="outline">
                        <mat-form-field class="tw-input-width" appearance="outline">
                            <mat-label>{{ "card.n_card" | translate }}</mat-label>
                            <input matInput placeholder='' required formControlName="number"
                                   [twCardValidation]="typeModePayment">
                            <mat-error>
                                <tw-form-error-message
                                    [errors]="formGroupCard.controls['number'].errors"></tw-form-error-message>
                            </mat-error>
                        </mat-form-field>
                        @if (urlSvg) {
                            <div style="position:absolute; top: 10px; right: 0;">
                                <img class="tw-animated-bounceIn wlt-svg-white svg" src="{{urlSvg}}"
                                     style="height: 38px;padding: 2px;opacity: 0" alt="visa">
                            </div>
                        }
                    </div>
                    <div class="tw-flex tw-flex-flow-row-wrap">
                        <mat-form-field style="width: 100px;margin-right: 5px;font-size: 12px" appearance="outline">
                            <mat-label>{{ "card.month" | translate }}</mat-label>
                            <mat-select placeholder='{{"card.month" | translate}}' formControlName="month">
                                @for (ct of getMonths; track ct) {
                                    <mat-option [value]="ct">{{ ct }}</mat-option>
                                }
                            </mat-select>
                            <mat-error>
                                <tw-form-error-message
                                    [errors]="formGroupCard.controls['month'].errors"></tw-form-error-message>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field style="width: 100px;margin-right: 20px;font-size: 12px" appearance="outline">
                            <mat-label>{{ "card.year" | translate }}</mat-label>
                            <mat-select formControlName="year">
                                @for (ct of getYears; track ct) {
                                    <mat-option [value]="ct">{{ ct }}</mat-option>
                                }
                            </mat-select>
                            <mat-error>
                                <tw-form-error-message
                                    [errors]="formGroupCard.controls['year'].errors"></tw-form-error-message>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field style="width: 150px;font-size: 12px" appearance="outline">
                            <mat-label>{{ "card.cvd" | translate }}</mat-label>
                            <input matInput formControlName="cvd" required>
                            <mat-error>
                                <tw-form-error-message
                                    [errors]="formGroupCard.controls['cvd'].errors"></tw-form-error-message>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <ng-container [ngTemplateOutlet]="templateRef"></ng-container>
                </div>
            }
            @if (typeModePayment.exact && subdomain !== 'villedequebec') {
                <mat-radio-button color="primary" class="bt-radio-option" [value]="typeModePaymentLabel.interac">
                    <strong class="chk-tl-temp">{{ "title.intercat" | translate }}</strong>
                </mat-radio-button>
            }
            @if (typeModePayment.exact && choicePayment === typeModePaymentLabel.interac) {
                <div class="scale-in-top tw-padding-10">
                    @if (translate.currentLang === 'fr') {
                        <div class="ckt-img-int">
                            <img src="https://d364ts6ehiutez.cloudfront.net/interac_images/TD.gif">
                        </div>
                    }
                    @if (translate.currentLang !== 'fr') {
                        <div class="ckt-img-int">
                            <img src="https://d364ts6ehiutez.cloudfront.net/interac_images/TD.gif">
                        </div>
                    }
                    @if (translate.currentLang === 'fr') {
                        <div>
                            <p>Paiement par Interac (Pour les clients de la TD ou de Credit Union)<br>
                                Une nouvelle fenêtre va s'ouvrir pour que vous puissiez procéder à votre paiement.
                                Assurez-vous d'avoir désactivé votre bloqueur de fenêtres popups. </p>
                        </div>
                    }
                    @if (translate.currentLang !== 'fr') {
                        <div>
                            <p>
                                Pay with Interac (Only for clients of TD or some Credit Union)<br>
                                A popup will open in order for you to process the payment on the Interac website. Please
                                make sure you have disabled any popup blockers.
                            </p>
                        </div>
                    }
                    <div style="text-align: left">
                        <button style="flex-grow: 1;margin: 1px" mat-raised-button color="primary" class="tw-primary"
                                (click)="sendInteract.emit('ok')">{{ "title.intercat" | translate }}
                        </button>
                    </div>
                </div>
            }
            @if (typeModePayment.paypal) {
                <mat-radio-button color="primary" class="bt-radio-option" [value]="typeModePaymentLabel.paypal">Paypal
                </mat-radio-button>
            }
            @if (typeModePayment.paypal && choicePayment === typeModePaymentLabel.paypal) {
                <div class="scale-in-top tw-padding-10">
                    <button style="flex-grow: 1;margin: 1px" mat-raised-button color="primary"
                            (click)="sendPaypal.emit('ok')">Interac
                    </button>
                </div>
            }


        </mat-radio-group>

        @if (false) {
            <div class="tw-flex tw-column">
                @if (true) {
                    @if (typeModePayment.exact || typeModePayment.paypal || typeModePayment.giftcertificate) {
                        <div>
                            <hr>
                        </div>
                    }
                    @if ((typeModePayment.exact || typeModePayment.paypal || typeModePayment.giftcertificate)) {
                        <div class="tw-flex tw-row" style="width: 100%">
                            @if (typeModePayment.exact && exactInteracFormResponse) {
                                <button style="flex-grow: 1;margin: 1px" mat-raised-button color="primary"
                                        (click)="sendInteract.emit('ok')">Interac
                                </button>
                            }
                            @if (typeModePayment.paypal) {
                                <button style="flex-grow: 1;margin: 1px" mat-raised-button color="primary"
                                        (click)="sendPaypal.emit('ok')">Paypal
                                </button>
                            }
                            <!-- il manque implimente le certificat cadeau-->
                            <!--            <button style="flex-grow: 1;margin: 1px" mat-raised-button color="primary" *ngIf=" typeModePayment.giftcertificate" (click)="sendGift.emit('ok')" >Gift certificate</button>-->
                        </div>
                    }
                }
            </div>
        }
        <!--    <pre>{{exactInteracFormResponse | json}}</pre>-->
        @if (postingInteract) {
            <form ngNoForm #InteracFormPost name="InteracFormPost"
                  action="{{exactInteracFormResponse.payment_url}}"
                  method="POST"
            >
                <input id="x_login" name="x_login" [value]="exactInteracFormResponse.x_login" type="hidden">
                <input id="x_amount" name="x_amount" [value]="exactInteracFormResponse.x_amount" type="hidden">
                <input id="x_fp_sequence" name="x_fp_sequence" [value]="exactInteracFormResponse.x_fp_sequence"
                       type="hidden">
                <input id="x_fp_timestamp" name="x_fp_timestamp" [value]="exactInteracFormResponse.x_fp_timestamp"
                       type="hidden">
                <input id="x_fp_hash" name="x_fp_hash" [value]="exactInteracFormResponse.x_fp_hash" type="hidden">
                <input id="x_receipt_link_url" name="x_receipt_link_url"
                       [value]="exactInteracFormResponse.x_receipt_link_url" type="hidden">
                <input id="x_test_request" name="x_test_request" [value]="exactInteracFormResponse.x_test_request"
                       type="hidden">
                <input id="x_po_num" name="x_po_num" [value]="exactInteracFormResponse.x_po_num" type="hidden">
                <input name="x_show_form" value="PAYMENT_FORM" type="hidden">
            </form>
        }


    </div>
} @else {
    <ng-container [ngTemplateOutlet]="templateRef"></ng-container>

}



<ng-template #templateRef>
    <ng-content></ng-content>
</ng-template>
