import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Pipe({
    name: 'twUrl'
})
export class TwUrlPipe implements PipeTransform {

    transform(value: string | any, args?: any): any {
        if (value && value.charAt(0) === '/') {
            value = value.substring(1);
        }
        //console.log(value);

        return environment.urlApi + value;
    }

}
