import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { TicketsService } from '../../../../tickets.service';
import { SendTicketAsGiftRequest } from '../../../../../../../projects/shared-lib/src/lib/models/send-ticket-as-gift-request';
import { SendTicketAsGiftResponse } from '../../../../../../../projects/shared-lib/src/lib/models/send-ticket-as-gift-response';
import {NgIf} from '@angular/common';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';

@Component({
    selector: 'tw-client-client-gift-ticket-dialog',
    templateUrl: './gift-ticket-dialog.component.html',
    standalone: true,
    imports: [
        FormsModule,
        TranslateModule,
        NgIf,
        ReactiveFormsModule,
        MatIconButton,
        MatIcon,
        MatButton
    ],
    styleUrls: ['./gift-ticket-dialog.component.css']
})
export class GiftTicketDialogComponent implements OnInit {
    giftForm: FormGroup;
    sendTicketAsGiftResponse: SendTicketAsGiftResponse;
    placeholder: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<GiftTicketDialogComponent>,
        private fb: FormBuilder,
        private ticketService: TicketsService,
        private translate: TranslateService,
    ) {}

    ngOnInit(): void {
        this.giftForm = this.fb.group({
            email: [this.data.email || '', [Validators.required, Validators.email]]
        });

        this.translate.get('client.tickets.recipientEmailPlaceholder').subscribe((placeholder: string) => {
            this.placeholder = this.data.email || placeholder;
        });
    }

    get email() {
        return this.giftForm.get('email');
    }

    closeDialog() {
        this.dialogRef.close();
    }

    sendGift() {
        if (this.giftForm.invalid) {
            return;
        }

        const request: SendTicketAsGiftRequest = {
            ticketId: this.data.sodId,
            email: this.email.value
        };

        this.ticketService.sendTicketAsGift(request)
            .subscribe({
                next: (response: SendTicketAsGiftResponse) => {
                    this.sendTicketAsGiftResponse = response;
                    console.log('Sent ticket as gift successful');
                    console.log('Response saved in variable:', this.sendTicketAsGiftResponse);
                    this.dialogRef.close(this.sendTicketAsGiftResponse);
                    this.reloadPage()
                },
                error: (e) => {
                    console.error('Failed to send ticket as gift', e);
                    this.dialogRef.close();
                },
                complete: () => {
                    console.info('Ticket sent as gift');
                }
            });
    }

    reloadPage() {
        setTimeout(() => {
            location.reload();
        }, 5000);
    }
}
