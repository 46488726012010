import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Cart} from '../../models/cart';
import {environment} from '../../../../../../environments/environment';
import {TypePayment} from '../../../../../../src/modules/models/config-website';
import {WebsiteSaleService} from '../../../../../../src/modules/shared/services/website-sale.service';
import {getAllowedCardNetworks} from '../../utils/utilsShared';
import {TranslateService} from '@ngx-translate/core';
import {lastValueFrom} from 'rxjs';
import {CartService} from '../../../../../../src/modules/shared/services/cart.service';
const trInfo={
    displayItems: [
        {
            label: "Subtotal",
            type: "SUBTOTAL",
            price: "11.00",
        },
        {
            label: "tvq",
            type: "TAX",
            price: "1.00",
        },
        {
            label: "tps",
            type: "TAX",
            price: "1.00",
        }
    ],
    countryCode: 'US',
    currencyCode: "USD",
    totalPriceStatus: "FINAL",
    totalPrice: "12.00",
    totalPriceLabel: "Total"
};



@Component({
    selector: 'lib-google-pay',
    templateUrl: './google-pay.component.html',
    styleUrls: ['./google-pay.component.css']
})
export class GooglePayComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() totalShippingSelected = 0;
    @Input() cart: Cart;
    @Output() googlePay = new EventEmitter<google.payments.api.PaymentData>();
    // environment = 'TEST';
    buttonColor = 'black';
    buttonType = 'buy';
    isCustomSize = false;
    buttonWidth = 240;
    buttonHeight = 40;
    isTop = window === window.top;
    paymentRequest: google.payments.api.PaymentDataRequest;
    paymentsClient = null;
    typeModePayment: TypePayment;

    constructor(
        private websiteSaleService: WebsiteSaleService,
        public translate:TranslateService,
        private cartService: CartService,
    ) {
        this.typeModePayment = this.websiteSaleService.getLocalConfig().account;

    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    async ngOnInit() {
        // HelpsService.injectScript('https://pay.google.com/gp/p/js/pay.js').then(() => {
        //     this.onGooglePayLoaded();
        // });
        this.typeModePayment = await lastValueFrom(this.cartService.getPaymentMethods());

        const paymentRequest: google.payments.api.PaymentDataRequest |any = {
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
                {
                    type: 'CARD',
                    parameters: {
                        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                        allowedCardNetworks: <google.payments.api.CardNetwork[]>  getAllowedCardNetworks(this.typeModePayment).map(value => value.toUpperCase())
                    },
                    tokenizationSpecification: {
                        type: 'PAYMENT_GATEWAY',
                        parameters: environment.googlePay
                    }
                }
            ],
            merchantInfo: {
                merchantId: 'BCR2DN6TU6LMB4CB',
                merchantName: 'Ticketpro.ca'
            }
        };
        paymentRequest.callbackIntents =['PAYMENT_AUTHORIZATION']
        paymentRequest.shippingAddressRequired = true;
        paymentRequest.transactionInfo = this.getGoogleTransactionInfo();
        //paymentRequest.transactionInfo = trInfo;
       console.log(paymentRequest);

        this.paymentRequest = paymentRequest;
    }


    onPaymentDataChanged($event: any) {
        console.log($event);
        return undefined;
    }


    cancelCallback($event: any) {
        console.log($event);
    }

    errorCallback($event: any) {
        console.log($event);
    }

    onPaymentAuthorized(paymentData: any) {
        return new Promise((resolve, reject)=>{
            console.log(paymentData)
            this.googlePay.emit(paymentData);
            resolve({transactionState: 'SUCCESS'});

        });
    }


    loadPaymentDataCallback(event: any) {
        console.log(event);
        this.googlePay.emit(event);
        return undefined;
    }

    getGoogleTransactionInfo(): google.payments.api.TransactionInfo {
        const cart: Cart = this.cart;
        const transactionInfo: google.payments.api.TransactionInfo | any = {
            totalPriceStatus: 'FINAL',
            totalPriceLabel: 'Total',
            currencyCode: 'CAD',
            countryCode: 'CA',
            checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE',

        };
        transactionInfo.displayItems = [];
        if (cart?.tax) {
            if (cart.tax.subtotal) {
                const a: google.payments.api.DisplayItem = {
                    label: 'Subtotal',
                    type: 'SUBTOTAL',
                    price: String(cart.tax.subtotal / 100),
                };
                transactionInfo.displayItems.push(a);
            }
            if (cart.tax.tax1) {
                const a: google.payments.api.DisplayItem = {
                    label: cart.tax.descriptionTax1,
                    type: 'TAX',
                    price: String(cart.tax.tax1 / 100),
                };
                transactionInfo.displayItems.push(a);
            }
            if (cart.tax.tax2) {
                const a: google.payments.api.DisplayItem = {
                    label: cart.tax.descriptionTax2,
                    type: 'TAX',
                    price: String(cart.tax.tax2 / 100),
                };
                transactionInfo.displayItems.push(a);
            }
            if (cart.tax.tax3) {
                const a: google.payments.api.DisplayItem = {
                    label: cart.tax.descriptionTax3,
                    type: 'TAX',
                    price: String(cart.tax.tax3 / 100),
                };
                transactionInfo.displayItems.push(a);
            }

            transactionInfo.totalPrice = String((cart.tax.totalAfterTaxes + this.totalShippingSelected) / 100);
        }
        // transactionInfo.displayItems = [
        //     {
        //         label: 'Subtotal',
        //         type: 'SUBTOTAL',
        //         price: '99.00',
        //     },
        //     {
        //         label: 'Tax',
        //         type: 'TAX',
        //         price: '1.00',
        //     }
        // ];
        //
        // transactionInfo.totalPrice = '100.00';
        return transactionInfo;

    }

    onGooglePayLoaded() {
        const paymentsClient = this.getGooglePaymentsClient();
        paymentsClient.isReadyToPay(this.paymentRequest)
            .then((response) => {
                if (response.result) {
                    this.addGooglePayButton();
                    // @todo prefetch payment data to improve performance after confirming site functionality
                    // prefetchGooglePaymentData();
                }
            })
            .catch(function (err) {
                // show error in developer console for debugging
                console.error(err);
            });
    }

    getGooglePaymentsClient() {
        if (this.paymentsClient === null) {
            this.paymentsClient = new google.payments.api.PaymentsClient({environment: 'TEST'});
        }
        return this.paymentsClient;
    }

    addGooglePayButton() {
        const paymentsClient = this.getGooglePaymentsClient();
        const button =
            paymentsClient.createButton({onClick: this.onGooglePaymentButtonClicked.bind(this)});
        document.getElementById('container').appendChild(button);
    }

    onGooglePaymentButtonClicked() {
        const paymentDataRequest = this.paymentRequest;
        console.log(paymentDataRequest)
        paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo();

        const paymentsClient = this.getGooglePaymentsClient();
        paymentsClient.loadPaymentData(paymentDataRequest)
            .then((paymentData) => {
                // handle the response
                // processPayment(paymentData);
            })
            .catch(function (err) {
                // show error in developer console for debugging
                console.error(err);
            });
    }


}
