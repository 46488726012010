<div  class="item-newsletters mat-elevation-z2" >
  @if (_newsletters && _newsletters.newsletters.length > 0) {
    <div>
      @for (newsletter of _newsletters.newsletters; track newsletter) {
        <div class="tw-padding-10">
          <p style="font-size: 20px; margin: 0">
            {{newsletter.newsletterText[translate.currentLang.toUpperCase()]}}
          </p>
          <div>
            <input type="checkbox" [checked]="newsletter.alreadySubscribed" (change)="updateSubscription(newsletter.id)">
            {{newsletter.title[translate.currentLang.toUpperCase()]}}
          </div>
        </div>
      }
    </div>
  }
</div>
