@if (!oldFlow) {
  <div style="margin: 0 auto ;max-width: 800px" class="cl  calendar-temp mat-elevation-z1">
    <!--    {{items.length}} callll-->
    <div class="tw-flex tw-column cl-month-container cl-month-container-temp ">
      <div class="tw-flex tw-row manage-bt-header-temp" style="justify-content: center;padding: 10px">
        <div (click)="previousMonth()" style="flex-grow: 1;text-align: right; cursor: pointer" class="cl-prv-temp">
          &#10094;
        </div>
        <div style="flex-grow: 4;text-align: center" class="cl-center-temp">
          <span
          style="font-size:23px;font-weight: bold"> {{daySelected | twDatePipe: 'MMMM'}} {{daySelected | twDatePipe :'yyyy'}}</span>
        </div>
        <div (click)="nextMonth()" style="flex-grow: 1;text-align: left; cursor: pointer" class="cl-next-temp">
          &#10095;
        </div>
      </div>

        @if (loading) {
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }

      <div class="tw-flex tw-row cl-month-header cl-month-header-temp">
        <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.sunday' | translate}}</div>
        <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.monday' | translate}}</div>
        <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.tuesday' | translate}}</div>
        <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.wednesday' | translate}}</div>
        <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.thursday' | translate}}</div>
        <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.friday' | translate}}</div>
        <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.saturday' | translate}}</div>
      </div>
      <!--        {{dayByItems | json}}-->
      @for (week of dayByItems; track week; let i = $index; let last = $last) {
        <div class="tw-flex tw-row cl-week cl-week-temp">
          @for (day of week ; track day; let indexDay = $index; let isLastDayInWeek = $last) {
            <div class="cl-day cl-day-temp tw-flex tw-column"
                         [ngClass]="[!day.day ? 'calendar-day-zero':'',
                      isLastDayInWeek ? 'cl-last-day-in-week':'',
                      last?'cl-last-week':'',
                      _eventSelected?.week.indexDay === indexDay && _eventSelected?.week.i===i ?'selectEvent':'',
                      day.items.length>0 ? '':'cl-day-not-active'
                      ]">
              <!--                    {{day | json}}-->
              <div class="cl-day-number cl-day-number-temp"
              [ngClass]=" day.items.length>0 ? 'cl-day-number-active':''"
              >
                {{ day.day | twDatePipe:'dd'}}
              </div>
              <tw-widget-select-event
                [showPoint]="showPoint"
                [items]="day.items" [indexWeek]="{i:i,indexDay:indexDay}"
                (openSelectionEvents)="getSelectCurrent($event)"
              (eventIdSelect)="eventIdSelect($event)"></tw-widget-select-event>
            </div>
          }
        </div>
        @if (_eventSelected?.week.i == i) {
          <div [@bounceDiv]="bounceDivState" style=""
            >
            <!--                {{i }}{{_eventSelected | json}}-->
            <div class="cl-item-hours" style="">
              @if (_eventSelected.event.imageUrl) {
                <div class="tw-page-container-image "
                  style="">
                  <img style="max-width: 100px;max-height: 100px"
                    [src]="_eventSelected.event.imageUrl | twUrl "
                    alt="{{_eventSelected.event.title}}">
                </div>
              }
              <div class="cl-item-hours-detail" style="">
                <h3>{{_eventSelected.event.title}}</h3>
                @if (_eventSelected.event.itemHours.length>1&& false) {
                  <mat-chip-listbox
                    >
                    @for (h of _eventSelected.event.itemHours; track h) {
                      <mat-chip-option style=""
                        matTooltip="{{h.saleState !== 'ONSALE' ? ('checkout.soldOut'| translate) :''}}"
                        [disabled]="h.saleState !== 'ONSALE'"
                        (click)="selectHours(_eventSelected.event,h)"
                        color="primary"
                        selected
                        >
                        {{h.formattedHour}}
                      </mat-chip-option>
                    }
                  </mat-chip-listbox>
                }
                @if (_eventSelected.event.itemHours.length>1) {
                  <div
                    style="display: flex;flex-wrap: wrap;flex-direction: row">
                    @for (h of _eventSelected.event.itemHours; track h) {
                      <button mat-raised-button color="primary"
                        style="margin:5px;    min-width: 92px;    border-radius: 17px;"
                        (click)="selectHours(_eventSelected.event,h)"
                        matTooltip="{{h.saleState !== 'ONSALE' ? ('checkout.soldOut'| translate) :''}}"
                        [disabled]="h.saleState !== 'ONSALE'"
                        >
                        <span>    {{h.formattedHour}}</span>
                      </button>
                    }
                  </div>
                }
                <div style="text-align: right">
                  <button mat-raised-button color="primary" (click)="goToEvent(_eventSelected.event)"
                    class="tw-primary">
                    <span>{{'title.next' | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      }
      <ng-container>
        @if (itemsSelectWj) {
          <div class="popover-events popover-events-temp mat-elevation-z1 tw-padding-10"
            [ngClass]="this.itemsSelectWj.showListEvents? 'animated bounceInLeft':'animated bounceOutLeft'"
            [ngStyle]="styleEventPopop">
            <button mat-icon-button aria-label="keyboard_backspace" (click)="goBack()">
              <mat-icon>keyboard_backspace</mat-icon>
            </button>
            <div class="tw-padding-10 cl-h-temp">
              @for (h of this.itemsSelectWj.items; track h) {
                @if (h.itemHours && h.itemHours.length>0) {
                  <div style="" class="mat-elevation-z0">
                    @if (h.saleState ==='ONSALE') {
                      <button
                        (click)="gotoPage(h.id)"
                        style="margin: 1px;white-space: pre-wrap !important;color: white;height: 51px;width: 100%"
                        mat-button
                        color="primary" class="tw-primary cl-bt-temp"
                        twColorBySaleState [saleState]="h.saleState">
                        <span class="cld-title-temp"> {{h.title}} </span><br>
                        <span class="cld-heure-temp">{{h.itemHours[0].formattedHour}}</span>
                      </button>
                    }
                    @if (h.saleState !=='ONSALE' && !h.note) {
                      <button
                        (click)="gotoPage(h.id)"
                        style="margin: 1px;white-space: pre-wrap !important;height: 51px;width: 100%"
                        [disabled]="h.saleState !=='ONSALE' "
                        mat-button
                        color="primary" class="tw-primary cl-bt-temp"
                        twColorBySaleState [saleState]="h.saleState">
                        <span class="cld-title-temp"> {{h.title}} </span><br>
                        <span class="cld-heure-temp">{{h.itemHours[0].formattedHour}}</span>
                        <span>
                          @switch (h.saleState) {
                            @case ('SOLDOUT') {
                              <span style="color: red">
                                {{ "checkout.soldOut" | translate }}
                              </span>
                            }
                          }
                        </span>
                      </button>
                    }
                    @if (h.saleState !=='ONSALE' && h.note) {
                      <button
                        (click)="showNote(h)"
                        style="margin: 1px;white-space: pre-wrap !important;height: 51px;width: 100%"
                        mat-button
                        color="primary" class="tw-primary cl-bt-temp"
                        twColorBySaleState [saleState]="h.saleState">
                        <span class="cld-title-temp"> {{h.title}}</span><br>
                        <span class="cld-heure-temp">{{h.itemHours[0].formattedHour}}</span><br>
                        <span>
                          @switch (h.saleState) {
                            @case ('SOLDOUT') {
                              <span style="color: red">
                                {{ "checkout.soldOut" | translate }}
                              </span>
                            }
                            @default {
                              <span style="color: red">
                                {{h.note}}
                              </span>
                            }
                          }
                        </span>
                      </button>
                    }
                  </div>
                }
              }
            </div>
          </div>
        }
      </ng-container>
    </div>
  </div>
}

@if (oldFlow) {
  <div class="calendar-container">
    <div class="calendar-month tw-primary">
      <ul>
        <li class="calendar-prev" (click)="previousMonth()">&#10094;</li>
        <li class="calendar-next" (click)="nextMonth()">&#10095;</li>
        <li>
          {{daySelected | twDatePipe: 'MMMM'}}<br>
          <span style="font-size:18px">{{daySelected | twDatePipe :'yyyy'}}</span>
        </li>
      </ul>
    </div>
    <div class="tw-flex tw-row cl-month-header cl-month-header-temp" style="border-bottom: 1px solid rgba(0,0,0,0.22)">
      <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.sunday' | translate}}</div>
      <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.monday' | translate}}</div>
      <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.tuesday' | translate}}</div>
      <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.wednesday' | translate}}</div>
      <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.thursday' | translate}}</div>
      <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.friday' | translate}}</div>
      <div class="cl-month-header-title cl-month-header-title-temp">{{'calendar.day.saturday' | translate}}</div>
    </div>
    <ul class="calendar-days">
      @for (week of month; track week; let i = $index) {
        @for (day of week; track day) {
          <li [ngClass]="[!day ? 'calendar-day-zero':'' ]" style="border-left: 1px solid rgba(0,0,0,0.05)">
                <div (click)="selectDate(day)" [ngClass]="[
                       (isActiveDay(day) ? 'calendar-day-active':''),
                       getClassCssForState(day)
               ]" class="calendar-day">
              @if (day != null) {
                <div>{{ day | twDatePipe:'dd'}}</div>
              }
              @if (day == null) {
                <div [ngClass]="[!day ? 'calendar-day-zero-p':'']">0</div>
              }
            </div>
          </li>
        }
      }
    </ul>
  </div>
}

<ng-template #dialog>
  <span style="font-size: 20px;font-weight: 900;" mat-dialog-title>{{_eventSelected.event.title}}</span>
  @if (hourSelect) {
    <span style="font-size: 15px;text-transform: uppercase" class="tw-primary-color" mat-dialog-title
    >  {{hourSelect.formattedDate}}</span>
  }
  <mat-dialog-content class="mat-typography">
    @if (translate.currentLang === 'fr') {
      <span>
        Vous avez sélectionné une date pour l'exposition alternative {{_eventSelected.event.title}}.
      </span>
    }
    @if (translate.currentLang !== 'fr') {
      <span>
        Only the alternative exhibit {{_eventSelected.event.title}} is available on this date.
      </span>
    }
  </mat-dialog-content>

  <div mat-dialog-actions [align]="'end'">
    <button mat-button mat-dialog-close>{{'title.Cancel'| translate}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="'ok'" cdkFocusInitial>
      @if (translate.currentLang === 'fr') {
        <span style="color: white"> J'ai compris  </span>
      }
      @if (translate.currentLang !== 'fr') {
        <span style="color: white">I understand   </span>
      }

    </button>
  </div>

</ng-template>


