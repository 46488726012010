import {Injectable} from '@angular/core';
import {SymplifyItem, SymplifyMetaData} from '../../../../projects/shared-lib/src/lib/models/symplify-item';
import {HelpsService} from './helps.service';
import {PriceWeb} from '../../../../projects/shared-lib/src/lib/models/price-web';
import {CartPrice} from '../../../../projects/shared-lib/src/lib/models/cart-price';
import {lastValueFrom} from 'rxjs';
import {Cart} from '../../../../projects/shared-lib/src/lib/models/cart';
import {environment} from '../../../../environments';
/* https://help.symplify.com/hc/en-us/articles/360011292099 */
/***
 {{serverUrl}}/{{customerId}}/abandonedcart/clients/www5_8mGXr/carts?order=NEWEST
 GET {{serverUrl}}/{{customerId}}/abandonedcart/clients/www5_8mGXr/carts?order=NEWEST


 https://nam-proxy.symplifymail.com/rest/10330/abandonedcart/clients/www5_8mGXr/carts?order=NEWEST
 Olivier Mineau11 h 26
 GET https://nam-proxy.symplifymail.com/rest/10330/abandonedcart/clients/www5_8mGXr/carts?order=NEWEST
 ***/

declare const carma: any;

@Injectable({
    providedIn: 'root'
})
export class SymplifyCartService {
    client;
    active = false;

    constructor() {

    }

    async init(lang, active = false) {
        this.active = active;
        if (!this.active) {
            return;
        }
        let id = 'www5_8mGXr';
        if (lang === 'fr') {
            id = 'www5_Sq4lB';
        }
        await lastValueFrom(HelpsService.injectScriptObservable('//mimgs.s3.amazonaws.com/scripts/ac.js',
            {id: 'carma_ac', 'data-cid': String(id)}));
        // HelpsService.injectScript('//mimgs.s3.amazonaws.com/scripts/ac.js',
        //     {id: 'carma_ac', 'data-cid': String(id)}).then(() => {
        // }).catch(error => {
        //     //console.error(error);
        // });
    }

    addAllCart(cart: Cart) {
        if (!this.active) {
            return;
        }
        try {
            if (cart) {
                cart?.cartElements.forEach(elm => {
                    this.addToCartPrice(elm.prices);
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    registerEmail(email) {
        if (!this.active) {
            return;
        }
        try {
            carma.ac.registerEmail(email);
        } catch (e) {
            console.log(e);
        }

    }

    addMetaData(metaData: SymplifyMetaData) {
        if (!this.active) {
            return;
        }
        try {
            //carma.ac.addMetaData(metaData);
            for (const [key, value] of Object.entries(metaData)) {
                const t = {};
                t[key] = value;
               // console.log(t);
                carma.ac.addMetaData(t);
            }
        } catch (e) {
            console.log(e);
        }

    }

    addToCart(item: SymplifyItem) {
        if (!this.client || !this.active) {
            return;
        }
        try {
            carma.ac.addToCart(item);
        } catch (e) {
            console.log(e);
        }

    }

    addToCartPrice(priceWebs: PriceWeb[] | CartPrice[] | any[] ) {
        if (!environment.production) {
            return;
        }
        if (!(environment.systemValues === 'tkp')) {
            return;
        }
        if (!this.client || !this.active) {
            return;
        }
        try {
            let metaData: SymplifyMetaData = {};
            priceWebs.forEach(value => {
                if (value.abandonedCartActive) {
                    if (value.pageUrl && value.pageUrl.length > 10 && value.title) {
                        const item: SymplifyItem = {
                            id: String(value.id),
                            amount: (value.price + value.serviceCharges)/100,
                            quantity: value.qty,
                            description: value.name,
                            name: value.name
                        };


                        this.addToCart(item);
                        metaData = {
                            image_url: value.imageUrl,
                            sale_url: value.cleanPageUrl ? value.cleanPageUrl : value.pageUrl,
                            lieu_nom: value.venue,
                            show_date: value.date,
                            show_title: value.title
                        };


                    }
                }
            });
            if (Object.keys(metaData).length > 0) {
                this.addMetaData(metaData);
                this.registerEmail(this.client.email);
            }

        } catch (e) {
            console.log(e);
        }
    }

    removeFromCart(item: SymplifyItem) {
        if (!this.active) {
            return;
        }
        try {
            carma.ac.removeFromCart(item);
        } catch (e) {
            console.log(e);
        }

    }

    removeFromCartPrice(cartPrices: [CartPrice]) {
        if (!this.active) {
            return;
        }
        try {
            cartPrices.forEach(value => {
                const item: SymplifyItem = {
                    id: String(value.id),
                    amount: (value.price + value.serviceCharges)/100,
                    quantity: value.qty,
                    description: value.name,
                    name: value.name
                };
                this.removeFromCart(item);
            });
        } catch (e) {
            console.log(e);
        }

    }

    setCart(cartData, totalAmount) {
        if (!this.active) {
            return;
        }
        try {
            carma.ac.setCart(cartData, totalAmount);
        } catch (e) {
            console.log(e);
        }

    }

    resetCart() {
        if (!this.active) {
            return;
        }
        try {
            carma.ac.resetCart();
        } catch (e) {
            console.log(e);
        }

    }

    purchaseCompleted() {
        if (!this.active) {
            return;
        }
        try {
            carma.ac.purchaseCompleted();
        } catch (e) {
            console.log(e);
        }

    }

}
