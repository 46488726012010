import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeSelectAdvancedComponent } from './date-range-select-advanced.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorMessageModule } from '../form-error-message/form-error-message.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMatTimepickerModule } from '../time-picker/ngx-mat-timepicker.module';
import { PipeModule } from './../../pipe/pipe.module';

@NgModule({
    declarations: [DateRangeSelectAdvancedComponent],
    exports: [DateRangeSelectAdvancedComponent],

    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FormErrorMessageModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule,
        MatSelectModule,
        MatNativeDateModule,
        NgxMatTimepickerModule,
        PipeModule,
    ],
})
export class DateRangeSelectAdvancedModule {}
