<div class="dialog-content">
    @if (this.data.email) {
        <div class="email-info">
            <h4>{{ 'client.tickets.alreadySent' | translate : ({email: this.data.email}) }}</h4>
            <span>{{ 'client.tickets.sendConfirmation' | translate }}</span>
        </div>
    } @else {
        <h3>{{ 'client.tickets.sendTickets' | translate }}</h3>
    }
    <form [formGroup]="giftForm">
        <input type="email" formControlName="email" [placeholder]="placeholder" class="form-control" >
        @if (email.invalid && (email.dirty || email.touched)) {
            <div class="error-message">
                <div *ngIf="email.errors?.required"><b>{{ 'client.tickets.error.emailRequired' | translate }}</b></div>
                <div *ngIf="email.errors?.email"><b>{{ 'client.tickets.error.invalidEmailFormat' | translate }}</b>
                </div>
            </div>
        }
        <div class="button-group">
            <button mat-button class="tw-primary" [disabled]="giftForm.invalid" (click)="sendGift()">{{ 'client.tickets.ok' | translate }}</button>
            <button mat-button class="tw-primary" (click)="closeDialog()">{{ 'client.tickets.cancel' | translate }}</button>
        </div>
    </form>
</div>
