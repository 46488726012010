import { Component, HostBinding, OnInit } from '@angular/core';
import { WebsiteSaleService } from '../modules/shared/services/website-sale.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { OverlayContainer } from '@angular/cdk/overlay';
import { environment } from '../../environments';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BreakpointsLib } from '../../projects/shared-lib/src/lib/utils/const-system';
import { SeatMapService } from '../../projects/shared-lib/src/lib/component/seats-map/seat-map.service';
import { subBreakpointObserver } from '../../projects/shared-lib/src/lib/utils/action';
import { IconEnregistreService } from '../../projects/shared-lib/src/lib/services/icon-enregistre.service';

@Component({
    selector: 'tw-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    showApp = false;
    errorPage: any;
    response = false;
    @HostBinding('class') componentCssClass;

    constructor(
        public websiteSaleService: WebsiteSaleService,
        public overlayContainer: OverlayContainer,
        public translate: TranslateService,
        public l: Location,
        public breakpointObserver: BreakpointObserver,
        public seatMapService: SeatMapService,
        public IconEnregistreService: IconEnregistreService
    ) {}

    async ngOnInit() {
        //console.log('entre ------------------------- 1211212');
        await this.IconEnregistreService.registerAllSvgIcons();
        if (environment.systemValues.search('feq') != -1) {
            this.onSetTheme('feq-theme');
        }
        if (environment.systemValues.search('tkp') != -1) {
            this.onSetTheme('tkp-theme');
        }
        if (environment.systemValues.search('mta') != -1) {
            this.onSetTheme('mta-theme');
        }

        try {
            await this.websiteSaleService.getConfigWeb();
            this.showApp = true;
            this.response = true;
        } catch (error1) {
            this.showApp = false;
            this.errorPage = error1;
            this.response = true;
        }
        const layoutChanges = this.breakpointObserver.observe([
            Breakpoints.XSmall,
            BreakpointsLib.MediumMobile,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge,
        ]);
        const e = this.seatMapService.watchSeatSelected().subscribe(value => {
            if (!value) {

            }
        });

        layoutChanges.subscribe((result) => {
            console.log(result);
            subBreakpointObserver.next(result);
        });

    }

    onSetTheme(theme) {
        // console.log(theme);
        this.overlayContainer.getContainerElement().classList.add(theme);
        this.componentCssClass = theme;
    }
}
