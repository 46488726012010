import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
} from '@angular/core';

@Directive({
    selector: '[libSvgIconResize]',
    standalone: true,
})
export class LibSvgIconResizeDirective implements OnChanges {
    @Input('libSvgIconResize') size: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnChanges() {
        console.log('size', this.size);
        if (this.size) {
            const [width, height] = this.size.split('x').map((s) => s + 'px');
            this.renderer.setStyle(this.el.nativeElement, 'width', width);
            this.renderer.setStyle(this.el.nativeElement, 'height', height);
            this.renderer.setStyle(this.el.nativeElement, 'font-size', height);
        }
    }
}
