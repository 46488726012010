import { DatePipe } from '@angular/common';
import { Sta } from '../models/address';
import { constSystem } from './const-system';

import { SupportedNetworksEnum } from '../models/supported-networks-enum';

import { RangeDates } from '../component/date-range-select-advanced/date-range-select-advanced.component';
import { ThemePalette } from '../models/config-website';
import { InjectionToken } from '@angular/core';
import { Configurations } from '../models/configurations';
import { PriceWeb } from '../models/price-web';
import { SyosSeat } from '../models/syos-seat';
import { se } from 'suneditor/src/lang';
import { TranslateService } from '@ngx-translate/core';

export const APP_CONFIG = new InjectionToken<Configurations>(
    'config-environment',
);

export const PROJECT_NAME = 'PROJECT_NAME';

export enum ProjectNameEnum {
    ADMIN,
    POS,
    SALE,
    REPORT,
}

export const delayTemp = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

export const datePipeAction = (
    value: string | number | Date,
    format?: string,
    translate?: any,
) => {
    const datePipe = new DatePipe(translate.currentLang);
    return datePipe.transform(value, format, '', translate.currentLang);
};
export const getAbrCountry = (val: string): string => {
    const country = <Sta[]>constSystem.world.countries;

    if (!val) {
        return 'CA';
    }
    val = val.toLowerCase();
    const temp = country.find(
        (value) =>
            value.name.toLowerCase() === val || value.ab.toLowerCase() === val,
    );
    if (temp) {
        return temp.ab;
    }
    return '';
};

export const getAbrProvince = (val: string, isUsa?: boolean): string => {
    let temp: Sta = <Sta>{};
    const statesUS = <Sta[]>constSystem.world.states;
    const statesCAD = <Sta[]>constSystem.world.provinces;
    temp.ab = '';
    if (!val) {
        return temp.ab;
    }
    val = val.toLowerCase();

    if (isUsa) {
        temp = statesUS.find(
            (value) =>
                value.nameEn.toLowerCase() === val ||
                value.ab.toLowerCase() === val,
        );
    } else {
        temp = statesCAD.find(
            (value) =>
                value.nameEn.toLowerCase() === val ||
                value.nameFr.toLowerCase() === val ||
                value.ab.toLowerCase() === val,
        );
    }
    if (temp) {
        return temp.ab;
    } else {
        return '';
    }
};
export const getRangesDate = (type: string): RangeDates => {
    const someDate = new Date();
    let rangeDates: RangeDates = {
        startDate: new Date(),
        endDate: new Date(),
    };
    switch (type) {
        case 'today':
            rangeDates.startDate = new Date(
                someDate.getFullYear(),
                someDate.getMonth(),
                someDate.getDate(),
                0,
                0,
            );
            rangeDates.endDate = new Date(
                someDate.getFullYear(),
                someDate.getMonth(),
                someDate.getDate() + 1,
                23,
                59,
            );

            break;
        case 'nextWeek':
            someDate.setDate(someDate.getDate() + 7);
            rangeDates.startDate = new Date();
            rangeDates.endDate = someDate;

            break;
        case 'nextMonth':
            someDate.setDate(someDate.getDate() + 31);
            rangeDates.startDate = new Date();
            rangeDates.endDate = someDate;

            break;
        case 'previousWeek':
            someDate.setDate(someDate.getDate() - 7);
            rangeDates.startDate = someDate;
            rangeDates.endDate = new Date();

            break;
        case 'previousMonth':
            someDate.setDate(someDate.getDate() - 31);
            rangeDates.startDate = someDate;
            rangeDates.endDate = new Date();

            break;
        case 'all':
            rangeDates.startDate = null;
            rangeDates.endDate = null;
            break;
        case 'thisWeek':
            rangeDates.startDate = getWeek()[0];
            rangeDates.endDate = getWeek()[6];

            break;
        case 'thisMonth':
            rangeDates.startDate = new Date();
            rangeDates.endDate = new Date(
                rangeDates.startDate.getFullYear(),
                rangeDates.startDate.getMonth() + 1,
                0,
            );

            break;
        case 'nextYear':
            rangeDates.startDate = new Date();
            rangeDates.endDate = new Date(
                rangeDates.startDate.getFullYear() + 1,
                rangeDates.startDate.getMonth(),
                rangeDates.startDate.getDate(),
            );

            break;
        case 'previousYear':
            rangeDates.endDate = new Date();
            rangeDates.startDate = new Date(
                rangeDates.endDate.getFullYear() - 1,
                rangeDates.endDate.getMonth(),
                rangeDates.endDate.getDate(),
            );

            break;
        case 'next2Year':
            rangeDates.startDate = new Date();
            rangeDates.endDate = new Date(
                rangeDates.startDate.getFullYear() + 2,
                rangeDates.startDate.getMonth(),
                rangeDates.startDate.getDate(),
            );

            break;
        case 'previous2Year':
            rangeDates.endDate = new Date();
            rangeDates.startDate = new Date(
                rangeDates.endDate.getFullYear() - 2,
                rangeDates.endDate.getMonth(),
                rangeDates.endDate.getDate(),
            );

            break;
        default:
            rangeDates = null;
            break;
    }
    return rangeDates;
};
export const getFirstAndLastDayOfMonth = (
    date: Date,
): { firstDay: Date; lastDay: Date } => {
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return { firstDay, lastDay };
};
export const getWeek = (): Date[] => {
    const curr = new Date();
    const week = [];

    for (let i = 1; i <= 7; i++) {
        const first = curr.getDate() - curr.getDay() + i;
        const day = new Date(curr.setDate(first));
        week.push(day);
    }
    //console.log(week);
    return week;
};
export const injectCss = (src: string) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('link');
        script.href = src;
        script.rel = 'stylesheet';
        script.addEventListener('load', resolve);
        script.addEventListener('error', () => reject('Error loading script.'));
        script.addEventListener('abort', () =>
            reject('Script loading aborted.'),
        );
        document.head.appendChild(script);
    });
};
export const injectScript = (src: string) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        script.addEventListener('load', resolve);
        script.addEventListener('error', () => reject('Error loading script.'));
        script.addEventListener('abort', () =>
            reject('Script loading aborted.'),
        );
        document.head.appendChild(script);
    });
};

export const getGeolocation = (): Promise<GeolocationPosition> => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    if (position) {
                        resolve(position);
                    }
                },
                (error: GeolocationPositionError) => reject(error),
            );
        } else {
            reject('Geolocation is not supported by this browser.');
        }
    });
};
export const ToNetworkType = (val: string) => {
    if (val.toLowerCase() === 'visa') {
        return SupportedNetworksEnum.VISA;
    }
    if (val.toLowerCase() === 'amex') {
        return SupportedNetworksEnum.AMEX;
    }
    if (val.toLowerCase() === 'discover') {
        return SupportedNetworksEnum.DISCOVER;
    }
    if (val.toLowerCase() === 'mastercard') {
        return SupportedNetworksEnum.MASTERCARD;
    }
    if (val.toLowerCase() === 'interac') {
        return SupportedNetworksEnum.INTERAC;
    }
    return null;
};

export const getAllowedCardNetworks = (
    typeModePayment: ThemePalette | any,
): string[] => {
    const types: string[] = [];
    if (typeModePayment.amex) {
        types.push('amex');
    }
    if (typeModePayment.mastercard) {
        types.push('mastercard');
    }
    if (typeModePayment.visa) {
        types.push('visa');
    }
    if (typeModePayment.discover) {
        types.push('discover');
    }
    if (typeModePayment.interac) {
        types.push('interac');
    }
    return types;
};

export type Coordinate = {
    lat: number;
    lon: number;
};
export const getDistanceBetweenTwoPoints = (
    cord1: Coordinate,
    cord2: Coordinate,
) => {
    if (cord1.lat == cord2.lat && cord1.lon == cord2.lon) {
        return 0;
    }

    const radLat1 = (Math.PI * cord1.lat) / 180;
    const radLat2 = (Math.PI * cord2.lat) / 180;

    const theta = cord1.lon - cord2.lon;
    const radTheta = (Math.PI * theta) / 180;

    let dist =
        Math.sin(radLat1) * Math.sin(radLat2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);

    if (dist > 1) {
        dist = 1;
    }

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344; //convert miles to km

    return dist;
};
export const sendToBackendDevice = (actionsConnect: {
    name: string;
    device?: string;
    value?: any;
}) => {
    // @ts-ignore
    if (window?.ReactNativeWebView) {
        // @ts-ignore
        window?.ReactNativeWebView.postMessage(JSON.stringify(actionsConnect));
    }
};
export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            const r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        },
    );
};
export const supprimerParametreURL = (
    url: string | URL,
    parametreASupprimer: string,
) => {
    const urlObj = new URL(url);
    const searchParams = urlObj.searchParams;

    searchParams.delete(parametreASupprimer);

    // Reconstruire l'URL avec les paramètres mis à jour
    urlObj.search = searchParams.toString();

    return urlObj.toString();
};

export const cloneObjectHelps = (val: any) => {
    return JSON.parse(JSON.stringify(val));
};
export const getType = (
    obj: any,
): 'Array' | 'String' | 'Number' | 'Boolean' | 'unknown' | any => {
    if (obj === null) {
        return 'null';
    } else if (Array.isArray(obj)) {
        return 'Array';
    } else if (typeof obj === 'string') {
        return 'String';
    } else if (typeof obj === 'number') {
        return 'Number';
    } else if (typeof obj === 'boolean') {
        return 'Boolean';
    } else if (typeof obj === 'object') {
        // Vous pouvez affiner le typage des objets ici en fonction de vos besoins
        if (obj.constructor) {
            return obj.constructor.name;
        } else {
            return 'Object';
        }
    } else {
        return 'unknown';
    }
};

export const findSeatAndIndex = (
    seats: SyosSeat[] | any,
    seatId: any,
    filter: any = 'reserve',
    type: 'TABLE' | 'SEAT' | 'ROW' = 'SEAT',
): { index: number; list: SyosSeat[]; isCompleteTable: boolean } => {
    // Initialiser le total des sièges actifs à 0
    let totalSeats = 0;
    const seatSelectionne = seats.find(
        (seat: { id: any }) => seat.id === seatId,
    );
    const filtreZSeatByPlv = seats.filter(
        (seat: { priceLevelId: any }) =>
            seat.priceLevelId === seatSelectionne.priceLevelId ||
            !seat.priceLevelId,
    );
    // Créer des groupes de sièges actifs en utilisant la méthode reduce
    let activeSeatGroups: SyosSeat[][] = filtreZSeatByPlv.reduce(
        (groups: any, seat: any, index: any) => {
            // Si le siège n'est pas réservé
            if (!seat[filter]) {
                // Incrémenter le total des sièges
                totalSeats++;
                // Si l'index est supérieur à 0 et que le siège précédent n'est pas réservé
                if (index > 0 && !filtreZSeatByPlv[index - 1][filter]) {
                    // Ajouter ce siège au dernier groupe
                    groups[groups.length - 1].push(seat);
                } else {
                    // Sinon, créer un nouveau groupe pour ce siège
                    groups.push([seat]);
                }
            }

            return groups;
        },
        [],
    );
    //console.log(activeSeatGroups);
    // Initialiser la réponse
    let response: any = { index: -1, list: [], isCompleteTable: false };

    try {
        for (let i = 0; i < filtreZSeatByPlv.length; i++) {
            // Trouver l'index du siège dans le groupe actif
            const index = activeSeatGroups[i].findIndex(
                (seat: { id: any }) => seat.id === seatId,
            );
            // Si l'index est trouvé
            if (index !== -1) {
                // Mettre à jour la réponse
                response = {
                    index,
                    list: activeSeatGroups[i],
                    isCompleteTable: false,
                };
                break;
            }
        }
        // Vérifier si tous les sièges forment une table complète
        response.isCompleteTable =
            type === 'TABLE' && totalSeats === filtreZSeatByPlv.length;

        // Si le type est 'TABLE' et que ce n'est pas une table complète
        if (type === 'TABLE' && !response.isCompleteTable) {
            // Obtenir le premier et le dernier siège
            const firstSeat = filtreZSeatByPlv[0];
            const lastSeat = filtreZSeatByPlv[seats.length - 1];
            // Si le premier et le dernier siège ne sont pas réservés
            if (!firstSeat.reserve && !lastSeat.reserve) {
                // Trouver les groupes qui contiennent le premier et le dernier siège
                const l1 =
                    activeSeatGroups.find((value) =>
                        value.find(
                            (seat: { id: any }) => seat.id === firstSeat.id,
                        ),
                    ) || [];
                const l2 =
                    activeSeatGroups.find((value) =>
                        value.find(
                            (seat: { id: any }) => seat.id === lastSeat.id,
                        ),
                    ) || [];
                // Mettre à jour la liste de la réponse avec les deux groupes trouvés
                response.list = [...l2, ...l1];
            }
        }
    } catch (e) {}

    return response;
};
/**
 * @param {SyosSeat[]} row - Un tableau de sièges (SyosSeat) représentant une rangée de sièges.
 * @param {number} seatId - L'identifiant unique du siège.
 * @param {number} [nbStepQty=1] - La sélectionner des sièges par groupe La quantité de sièges à valider. Par défaut, elle est définie sur 1 .
 * @param {'TABLE' | 'SEAT' | 'ROW'} [type='SEAT'] - Le type de siège. Les valeurs possibles sont 'TABLE', 'SEAT' et 'ROW'. Par défaut, elle est définie sur 'SEAT'.
 * @param {string} [filter='reserve'] - Le filtre à appliquer lors de la recherche de sièges. Par défaut, il est défini sur 'reserve'.
 * @param {boolean} [avoidSingle=true] - Ce paramètre détermine si la fonction doit éviter de laisser un seul siège entre deux sièges réservés. Par défaut, il est défini sur true.
 * @param {boolean} [algorithmEnable=false] - Pouvoir acheter 3 billets à une table de 4.
 * @param {boolean} [validQty=false] - IL faut retourner la quantité de siège voulu.
 * @returns {SyosSeat[]} - La fonction retourne un tableau de sièges (SyosSeat[]) qui sont valides selon les critères spécifiés par les paramètres.
 */
export const getValidSeats = (
    row: SyosSeat[],
    seatId: number,
    nbStepQty: number = 1,
    type: 'TABLE' | 'SEAT' | 'ROW' = 'SEAT',
    filter: string = 'reserve',
    avoidSingle: boolean = true,
    algorithmEnable: boolean = false,
    validQty: boolean = false,
): SyosSeat[] => {
    const seatData = findSeatAndIndex(row, seatId, filter, type);
    //console.log(seatData);

    if (seatData.index !== -1) {
        const seatSelected = seatData.list[seatData.index];

        nbStepQty = getReelNbQtyStep(nbStepQty, seatSelected.minTicket);
        // Si la quantité est égale à 1 et que l'algorithme est activé
        if (seatData.list.length <= nbStepQty) {
            if ( nbStepQty !== seatData.list.length) {
                return [];
            }
            return seatData.list;
        }

        // Si l'index du siège plus la quantité est inférieur à la longueur de la liste
        if (seatData.index + nbStepQty - 1 < seatData.list.length) {
            // Si on veut éviter de laisser un seul siège
            if (avoidSingle) {
                // Si la longueur de la liste est égale à 2, retourner la liste
                if (seatData.list.length === 2) {
                    if (
                        algorithmEnable &&
                        type === 'TABLE' &&
                        nbStepQty === 1
                    ) {
                        return [
                            seatData.list.find(
                                (seat: SyosSeat) => seat.id === seatId,
                            ),
                        ];
                    }
                    if (validQty && nbStepQty === 1) {
                        return [];
                    }
                    return seatData.list;
                }
                /* On valide quand on ne laisse pas un siège seul au début ou à la fin de la rangée */
                // Si l'index est égal à 1 ou l'index plus la quantité plus 1 est égal à la longueur de la liste et que ce n'est pas une table complète
                if (
                    (seatData.index === 1 ||
                        seatData.index + nbStepQty + 1 ===
                            seatData.list.length) &&
                    !seatData.isCompleteTable
                ) {
                   if(!validQty){
                       // seatData.list.forEach((value, index) => {
                       //       console.log(index)
                       //     console.log(seatData[])
                       // })
                       if (seatData.list.length>3){
                           if(seatData.list[seatData.index+1] && !seatData.list[seatData.index+2]){
                               return seatData.list.slice(
                                   seatData.index,
                                   seatData.index + 2,
                               );
                           }else if(seatData.list[seatData.index-1] && !seatData.list[seatData.index-2]){
                               return seatData.list.slice(
                                   seatData.index-1,
                                   seatData.index + 1,
                               );

                           }
                       }else {
                           return seatData.list;
                       }

                   }
                    return [];
                }
                // Récupérer les sièges à partir de l'index jusqu'à l'index plus la quantité
                const results = seatData.list.slice(
                    seatData.index,
                    seatData.index + nbStepQty,
                );
                // Calculer le nombre de sièges restants
                const remainingSeats = seatData.list.length - results.length;
                // Si c'est une table complète et qu'il reste moins de 2 sièges
                if (seatData.isCompleteTable && remainingSeats < 2) {
                    // Si l'algorithme est activé et qu'il reste un siège, retourner les résultats
                    if (algorithmEnable && remainingSeats === 1) {
                        return results;
                    }
                    return [];
                }
                return results;
            } else {
                // Si on ne veut pas éviter de laisser un seul siège, retourner les sièges à partir de l'index jusqu'à l'index plus la quantité
                return seatData.list.slice(
                    seatData.index,
                    seatData.index + nbStepQty,
                );
            }
        } else {
            // Si l'index du siège plus la quantité est supérieur ou égal à la longueur de la liste
            // Récupérer les sièges à partir de l'index jusqu'à la fin de la liste
            const list1 = seatData.list.slice(
                seatData.index,
                seatData.list.length,
            );

            const end = seatData.index; /* n'es pas inclus*/
            const start = end - (nbStepQty - list1.length);
            // Si on veut éviter de laisser un seul siège

            if (avoidSingle) {
                // Si le début est égal à 1, retourner une liste vide
                if (start === 1 && !algorithmEnable) {
                    return [];
                }
                // Si la longueur de la liste est égale à 2, retourner la liste
                if (seatData.list.length === 2) {
                    if (
                        algorithmEnable &&
                        type === 'TABLE' &&
                        nbStepQty === 1
                    ) {
                        return [
                            seatData.list.find(
                                (seat: SyosSeat) => seat.id === seatId,
                            ),
                        ];
                    }
                    if (validQty && nbStepQty === 1) {
                        return [];
                    }
                    return seatData.list;
                }
            }
            // Récupérer les sièges à partir du début jusqu'à la fin
            const seatSlice = seatData.list.slice(start, end);
            return [...seatSlice, ...list1];
        }
    }
    return [];
};
export const deleteSeatsSelected = (
    sLocal: SyosSeat[],
    st: SyosSeat,
    qtySelected: number = 1,
) => {
    let rowSeats: any[] = [];
    let nfilter = [];
    sLocal = sLocal.filter((value) => value.rowId === st.rowId);
    sLocal = sLocal.sort((a, b) => {
        return a.order - b.order;
    });
    const seatIndex = sLocal.findIndex((value1) => value1.id === st.id);

    sLocal.forEach((value1, index) => {
        if (st.rowId === value1.rowId) {
            if (seatIndex + 1 > sLocal.length / 2) {
                if (index + 1 >= seatIndex + 1) {
                    rowSeats.push(value1);
                }
            } else {
                if (index + 1 <= seatIndex + 1) {
                    rowSeats.push(value1);
                }
            }
        }
    });

    const remainingSeats: SyosSeat[] = sLocal.filter(
        (seat) => !rowSeats.includes(seat),
    );
    console.log(remainingSeats, rowSeats);
    if (remainingSeats.length >= rowSeats.length) {
        if (
            (seatIndex === sLocal.length - 1 || seatIndex === 0) &&
            qtySelected === 1 &&
            rowSeats.length === 1
        ) {
            nfilter = rowSeats;
            const idx = seatIndex === 0 ? 0 : remainingSeats.length - 1;
            nfilter.push(remainingSeats[idx]);
        } else {
            nfilter = rowSeats;
        }
    } else {
        if (!remainingSeats.find((value1) => value1.id === st.id)) {
            remainingSeats.push(st);
        }
        nfilter = remainingSeats;
    }
    if (nfilter.length > 3) {
        const indexFilter = nfilter.findIndex((value1) => value1.id === st.id);
        if (
            nfilter.length - 1 === indexFilter ||
            (nfilter.length - 1 > indexFilter && indexFilter > 0)
        ) {
            nfilter = [nfilter[indexFilter - 1], nfilter[indexFilter]];
        } else {
            nfilter = [nfilter[indexFilter], nfilter[indexFilter + 1]];
        }
    }
    return nfilter;
};
export const isEmpty = (value: any): boolean => {
    if (typeof value === 'string') {
        value = value.trim();
    }
    return value === null || value === undefined || value === '';
};
//
export const changeValueIfIsEmpty = (value: any, value2 = null):any => {
    if (isEmpty(value)) {
        return value2;
    }
    return value;
};
export const generateRandomStringWithShortTimestamp = () => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digits = '0123456789';

    let randomString = '';
    for (let i = 0; i < 3; i++) {
        randomString += letters.charAt(
            Math.floor(Math.random() * letters.length),
        );
    }
    for (let i = 0; i < 3; i++) {
        randomString += digits.charAt(
            Math.floor(Math.random() * digits.length),
        );
    }

    const timestamp = Date.now();
    const lastThreeDigitsOfTimestamp = timestamp.toString().slice(-3);
    return `${randomString}-${lastThreeDigitsOfTimestamp}`;
};
export const getReelNbQtyStep = (nbStepQty: number, minTicket: number) => {
    if (minTicket > 0) {
        return minTicket;
    }
    return nbStepQty;
};
export const convertNumberTo = (numericValue: any, translateService: TranslateService) => {
    if(translateService.currentLang === 'fr'){
        numericValue = numericValue.replace(/,/g, '.');

    }
    return numericValue;
}
