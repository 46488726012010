import { Pipe, PipeTransform } from '@angular/core';
import { LocationPage } from '../../models/location-page';


@Pipe({
  name: 'twGoogleMaps'
})
export class TwGoogleMaps implements PipeTransform {

  transform(loc: LocationPage, args?: any): any {
      if (loc.latitude && loc.longitude) {
          return 'https://www.google.com/maps/search/?api=1&query=' + loc.latitude + ',' + loc.longitude;
      }
      let result = loc.name;
      result = this.addIfNotNull(result, loc.addressLine1);
      result = this.addIfNotNull(result, loc.addressLine2);
      result = this.addIfNotNull(result, loc.city);
      result = this.addIfNotNull(result, loc.provinceState);
      result = this.addIfNotNull(result, loc.postalCode);
      return 'https://www.google.com/maps/search/?api=1&query=' + result;
  }

  addIfNotNull(result: string, s: string) {
    if (s) {
      return result + ',+' + s;
    }
    return result;
  }

}
