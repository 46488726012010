export const constSystem = {
    paramsUrl: {
        startDate: 'startDate',
        endDate: 'endDate',
    },
    filter: {
        date: 'yyyy-MM-ddTHH:mm:ss',
        dateyyyy_mm_dd: 'yyyy-MM-dd',
    },
    AddressType: {
        OLD: 'OLD',
        BILLING: 'BILLING',
        COMPANY: 'COMPANY',
    },
    PhonesType: {
        MAISON: 'MAISON',
    },
    ROLE: {
        CLIENT: 'CLIENT',
        ADMIN: 'ADMIN',
    },
    permission: {
        ACHATRAPIDE: 2,
        ACHATPRODUITS: 3,
        ACHATPOSTES: 4,
        ADMIN: 28,
        SCAN_GODMODE: 70,
        SCAN_DEBUG: 77,
        REPORT_WEB_ACCESS: 79,
        REPORT_LOG_AS_PRODUCER: 82,
        SCAN_BARCODE_IMPORT: 85,
        ADMIN_FULL: 100,
        ADMIN_SCAN: 139,
        ADMIN_WRITE_PROFILE: 150,
        GOD_MODE: 251,
    },
    currency: [
        {
            name: 'CAD',
        },
        {
            name: 'USD',
        },
        {
            name: 'EUR',
        },
        {
            name: 'CZK',
        },
        {
            name: 'GBP',
        },
    ],
    world: {
        states: [
            {
                ab: 'AL',
                nameEn: 'Alabama',
            },
            {
                ab: 'AK',
                nameEn: 'Alaska',
            },
            {
                ab: 'AZ',
                nameEn: 'Arizona',
            },
            {
                ab: 'AR',
                nameEn: 'Arkansas',
            },
            {
                ab: 'CA',
                nameEn: 'California',
            },
            {
                ab: 'CO',
                nameEn: 'Colorado',
            },
            {
                ab: 'CT',
                nameEn: 'Connecticut',
            },
            {
                ab: 'DE',
                nameEn: 'Delaware',
            },
            {
                ab: 'DC',
                nameEn: 'Dist. of Columbia',
            },
            {
                ab: 'FL',
                nameEn: 'Florida',
            },
            {
                ab: 'GA',
                nameEn: 'Georgia',
            },
            {
                ab: 'GU',
                nameEn: 'Guam',
            },
            {
                ab: 'HI',
                nameEn: 'Hawaii',
            },
            {
                ab: 'ID',
                nameEn: 'Idaho',
            },
            {
                ab: 'IL',
                nameEn: 'Illinois',
            },
            {
                ab: 'IN',
                nameEn: 'Indiana',
            },
            {
                ab: 'IA',
                nameEn: 'Iowa',
            },
            {
                ab: 'KS',
                nameEn: 'Kansas',
            },
            {
                ab: 'KY',
                nameEn: 'Kentucky',
            },
            {
                ab: 'LA',
                nameEn: 'Louisiana',
            },
            {
                ab: 'ME',
                nameEn: 'Maine',
            },
            {
                ab: 'MD',
                nameEn: 'Maryland',
            },
            {
                ab: 'MA',
                nameEn: 'Massachusetts',
            },
            {
                ab: 'MI',
                nameEn: 'Michigan',
            },
            {
                ab: 'MN',
                nameEn: 'Minnesota',
            },
            {
                ab: 'MS',
                nameEn: 'Mississippi',
            },
            {
                ab: 'MO',
                nameEn: 'Missouri',
            },
            {
                ab: 'MT',
                nameEn: 'Montana',
            },
            {
                ab: 'NE',
                nameEn: 'Nebraska',
            },
            {
                ab: 'NV',
                nameEn: 'Nevada',
            },
            {
                ab: 'NH',
                nameEn: 'New Hampshire',
            },
            {
                ab: 'NJ',
                nameEn: 'New Jersey',
            },
            {
                ab: 'NM',
                nameEn: 'New Mexico',
            },
            {
                ab: 'NY',
                nameEn: 'New York',
            },
            {
                ab: 'NC',
                nameEn: 'North Carolina',
            },
            {
                ab: 'ND',
                nameEn: 'North_Dakota',
            },
            {
                ab: 'OH',
                nameEn: 'Ohio',
            },
            {
                ab: 'OK',
                nameEn: 'Oklahoma',
            },
            {
                ab: 'OR',
                nameEn: 'Oregon',
            },
            {
                ab: 'PA',
                nameEn: 'Pennsylvania',
            },
            {
                ab: 'PR',
                nameEn: 'Puerto Rico',
            },
            {
                ab: 'RI',
                nameEn: 'Rhode Island',
            },
            {
                ab: 'SC',
                nameEn: 'South Carolina',
            },
            {
                ab: 'SD',
                nameEn: 'South Dakota',
            },
            {
                ab: 'TN',
                nameEn: 'Tennessee',
            },
            {
                ab: 'TX',
                nameEn: 'Texas',
            },
            {
                ab: 'UT',
                nameEn: 'Utah',
            },
            {
                ab: 'VT',
                nameEn: 'Vermont',
            },
            {
                ab: 'VA',
                nameEn: 'Virginia',
            },
            {
                ab: 'VI',
                nameEn: 'Virgin Islands',
            },
            {
                ab: 'WA',
                nameEn: 'Washington',
            },
            {
                ab: 'WV',
                nameEn: 'West Virginia',
            },
            {
                ab: 'WI',
                nameEn: 'Wisconsin',
            },
            {
                ab: 'WY',
                nameEn: 'Wyoming',
            },
        ],
        provinces: [
            {
                ab: 'QC',
                nameEn: 'Quebec',
                nameFr: 'Québec',
            },
            {
                ab: 'ON',
                nameEn: 'Ontario',
                nameFr: 'Ontario',
            },
            {
                ab: 'AB',
                nameEn: 'Alberta',
                nameFr: 'Alberta',
            },
            {
                ab: 'BC',
                nameEn: 'British Columbia',
                nameFr: 'Colombie-Britanique',
            },
            {
                ab: 'PE',
                nameEn: 'Prince Edward Island',
                nameFr: 'Ile du Prince Edouard',
            },
            {
                ab: 'MB',
                nameEn: 'Manitoba',
                nameFr: 'Manitoba',
            },
            {
                ab: 'NB',
                nameEn: 'New Brunswick',
                nameFr: 'Nouveau Brunswick',
            },
            {
                ab: 'NS',
                nameEn: 'Nova Scotia',
                nameFr: 'Nouvelle Écosse',
            },
            {
                ab: 'SK',
                nameEn: 'Saskatchewan',
                nameFr: 'Saskatchewan',
            },
            {
                ab: 'NL',
                nameEn: 'Newfoundland and Labrador',
                nameFr: 'Terre-Neuve et Labrador',
            },
            {
                ab: 'NU',
                nameEn: 'Nunavut',
                nameFr: 'Nunavut',
            },
            {
                ab: 'NT',
                nameEn: 'Northwest Territories',
                nameFr: 'Territoires du Nord-Ouest',
            },
            {
                ab: 'YT',
                nameEn: 'Yukon',
                nameFr: 'Yukon',
            },
        ],
        countries: [
            {
                ab: 'CA',
                name: 'Canada',
            },
            {
                ab: 'US',
                name: 'United States',
            },
            {
                ab: 'AF',
                name: 'Afghanistan',
            },
            {
                ab: 'AL',
                name: 'Albania',
            },
            {
                ab: 'DZ',
                name: 'Algeria',
            },
            {
                ab: 'AS',
                name: 'American Samoa',
            },
            {
                ab: 'AD',
                name: 'Andorra',
            },
            {
                ab: 'AO',
                name: 'Angola',
            },
            {
                ab: 'AI',
                name: 'Anguilla',
            },
            {
                ab: 'AQ',
                name: 'Antarctica',
            },
            {
                ab: 'AG',
                name: 'Antigua and Barbuda',
            },
            {
                ab: 'AR',
                name: 'Argentina',
            },
            {
                ab: 'AM',
                name: 'Armenia',
            },
            {
                ab: 'AW',
                name: 'Aruba',
            },
            {
                ab: 'AU',
                name: 'Australia',
            },
            {
                ab: 'AT',
                name: 'Austria',
            },
            {
                ab: 'AZ',
                name: 'Azerbaijan',
            },
            {
                ab: 'BS',
                name: 'Bahamas',
            },
            {
                ab: 'BH',
                name: 'Bahrain',
            },
            {
                ab: 'BD',
                name: 'Bangladesh',
            },
            {
                ab: 'BB',
                name: 'Barbados',
            },
            {
                ab: 'BY',
                name: 'Belarus',
            },
            {
                ab: 'BE',
                name: 'Belgium',
            },
            {
                ab: 'BZ',
                name: 'Belize',
            },
            {
                ab: 'BJ',
                name: 'Benin',
            },
            {
                ab: 'BM',
                name: 'Bermuda',
            },
            {
                ab: 'BT',
                name: 'Bhutan',
            },
            {
                ab: 'BO',
                name: 'Bolivia',
            },
            {
                ab: 'BA',
                name: 'Bosnia and Herzegowina',
            },
            {
                ab: 'BW',
                name: 'Botswana',
            },
            {
                ab: 'BV',
                name: 'Bouvet Island',
            },
            {
                ab: 'BR',
                name: 'Brazil',
            },
            {
                ab: 'IO',
                name: 'British Indian Ocean Terr.',
            },
            {
                ab: 'BN',
                name: 'Brunei Darussalam',
            },
            {
                ab: 'BG',
                name: 'Bulgaria',
            },
            {
                ab: 'BF',
                name: 'Burkina Faso',
            },
            {
                ab: 'BI',
                name: 'Burundi',
            },
            {
                ab: 'KH',
                name: 'Cambodia',
            },
            {
                ab: 'CM',
                name: 'Cameroon',
            },
            {
                ab: 'CV',
                name: 'Cape Verde',
            },
            {
                ab: 'KY',
                name: 'Cayman Islands',
            },
            {
                ab: 'CF',
                name: 'Central African Republic',
            },
            {
                ab: 'TD',
                name: 'Chad',
            },
            {
                ab: 'CL',
                name: 'Chile',
            },
            {
                ab: 'CN',
                name: 'China',
            },
            {
                ab: 'CX',
                name: 'Christmas Island',
            },
            {
                ab: 'CC',
                name: 'Cocos (Keeling) Islands',
            },
            {
                ab: 'CO',
                name: 'Columbia',
            },
            {
                ab: 'KM',
                name: 'Comoros',
            },
            {
                ab: 'CG',
                name: 'Congo',
            },
            {
                ab: 'CK',
                name: 'Cook Islands',
            },
            {
                ab: 'CR',
                name: 'Costa Rica',
            },
            {
                ab: 'CI',
                name: "Cote D'Ivoire",
            },
            {
                ab: 'HR',
                name: 'Croatia (Hrvatska)',
            },
            {
                ab: 'CU',
                name: 'Cuba',
            },
            {
                ab: 'CY',
                name: 'Cyprus',
            },
            {
                ab: 'CZ',
                name: 'Czech Republic',
            },
            {
                ab: 'DK',
                name: 'Denmark',
            },
            {
                ab: 'DJ',
                name: 'Djibouti',
            },
            {
                ab: 'DM',
                name: 'Dominica',
            },
            {
                ab: 'DO',
                name: 'Dominican Republic',
            },
            {
                ab: 'TP',
                name: 'East Timor',
            },
            {
                ab: 'EC',
                name: 'Ecuador',
            },
            {
                ab: 'EG',
                name: 'Egypt',
            },
            {
                ab: 'SV',
                name: 'El Salvador',
            },
            {
                ab: 'GQ',
                name: 'Equatorial Guinea',
            },
            {
                ab: 'ER',
                name: 'Eritrea',
            },
            {
                ab: 'EE',
                name: 'Estonia',
            },
            {
                ab: 'ET',
                name: 'Ethiopia',
            },
            {
                ab: 'FK',
                name: 'Falkland Islands (Malvinas)',
            },
            {
                ab: 'FO',
                name: 'Faroe Islands',
            },
            {
                ab: 'FJ',
                name: 'Fiji',
            },
            {
                ab: 'FI',
                name: 'Finland',
            },
            {
                ab: 'FR',
                name: 'France',
            },
            {
                ab: 'FX',
                name: 'France, Metropolitan',
            },
            {
                ab: 'GF',
                name: 'French Guiana',
            },
            {
                ab: 'PF',
                name: 'French Polynesia',
            },
            {
                ab: 'TF',
                name: 'French Southern Territories',
            },
            {
                ab: 'GA',
                name: 'Gabon',
            },
            {
                ab: 'GM',
                name: 'Gambia',
            },
            {
                ab: 'GE',
                name: 'Georgia',
            },
            {
                ab: 'DE',
                name: 'Germany',
            },
            {
                ab: 'GH',
                name: 'Ghana',
            },
            {
                ab: 'GI',
                name: 'Gibraltar',
            },
            {
                ab: 'GR',
                name: 'Greece',
            },
            {
                ab: 'GL',
                name: 'Greenland',
            },
            {
                ab: 'GD',
                name: 'Grenada',
            },
            {
                ab: 'GP',
                name: 'Guadeloupe',
            },
            {
                ab: 'GU',
                name: 'Guam',
            },
            {
                ab: 'GT',
                name: 'Guatemala',
            },
            {
                ab: 'GN',
                name: 'Guinea',
            },
            {
                ab: 'GW',
                name: 'Guinea-Bissau',
            },
            {
                ab: 'GY',
                name: 'Guyana',
            },
            {
                ab: 'HT',
                name: 'Haiti',
            },
            {
                ab: 'HM',
                name: 'Heard and McDonald Isls',
            },
            {
                ab: 'HN',
                name: 'Honduras',
            },
            {
                ab: 'HK',
                name: 'Hong Kong',
            },
            {
                ab: 'HU',
                name: 'Hungary',
            },
            {
                ab: 'IS',
                name: 'Iceland',
            },
            {
                ab: 'IN',
                name: 'India',
            },
            {
                ab: 'ID',
                name: 'Indonesia',
            },
            {
                ab: 'IR',
                name: 'Iran (Islamic Republic of)',
            },
            {
                ab: 'IQ',
                name: 'Iraq',
            },
            {
                ab: 'IE',
                name: 'Ireland',
            },
            {
                ab: 'IL',
                name: 'Israel',
            },
            {
                ab: 'IT',
                name: 'Italy',
            },
            {
                ab: 'JM',
                name: 'Jamacia',
            },
            {
                ab: 'JP',
                name: 'Japan',
            },
            {
                ab: 'JO',
                name: 'Jordan',
            },
            {
                ab: 'KZ',
                name: 'Kazakhstan',
            },
            {
                ab: 'KE',
                name: 'Kenya',
            },
            {
                ab: 'KI',
                name: 'Kiribati',
            },
            {
                ab: 'KP',
                name: "Korea, Democratic People's Republic of",
            },
            {
                ab: 'KR',
                name: 'Korea, Republic of',
            },
            {
                ab: 'KW',
                name: 'Kuwait',
            },
            {
                ab: 'KG',
                name: 'Kyrgyzstan',
            },
            {
                ab: 'LA',
                name: "Lao People's Democratic Republic",
            },
            {
                ab: 'LV',
                name: 'Latvia',
            },
            {
                ab: 'LB',
                name: 'Lebanon',
            },
            {
                ab: 'LS',
                name: 'Lesotho',
            },
            {
                ab: 'LR',
                name: 'Liberia',
            },
            {
                ab: 'LY',
                name: 'Libyan Arab Jamahiriya',
            },
            {
                ab: 'LI',
                name: 'Liechtenstein',
            },
            {
                ab: 'LT',
                name: 'Lithuania',
            },
            {
                ab: 'LU',
                name: 'Luxembourg',
            },
            {
                ab: 'MO',
                name: 'Macau',
            },
            {
                ab: 'MK',
                name: 'Macedonia',
            },
            {
                ab: 'MG',
                name: 'Madagascar',
            },
            {
                ab: 'MW',
                name: 'Malawi',
            },
            {
                ab: 'MY',
                name: 'Malaysia',
            },
            {
                ab: 'MV',
                name: 'Maldives',
            },
            {
                ab: 'ML',
                name: 'Mali',
            },
            {
                ab: 'MT',
                name: 'Malta',
            },
            {
                ab: 'MH',
                name: 'Marshall Islands',
            },
            {
                ab: 'MQ',
                name: 'Martinique',
            },
            {
                ab: 'MR',
                name: 'Mauritania',
            },
            {
                ab: 'MU',
                name: 'Mauritius',
            },
            {
                ab: 'YT',
                name: 'Mayotte',
            },
            {
                ab: 'MX',
                name: 'Mexico',
            },
            {
                ab: 'FM',
                name: 'Micronesia',
            },
            {
                ab: 'MD',
                name: 'Moldova, Republic of',
            },
            {
                ab: 'MC',
                name: 'Monaco',
            },
            {
                ab: 'MN',
                name: 'Mongolia',
            },
            {
                ab: 'MS',
                name: 'Montserrat',
            },
            {
                ab: 'MA',
                name: 'Morocco',
            },
            {
                ab: 'MZ',
                name: 'Mozambique',
            },
            {
                ab: 'MM',
                name: 'Myanmar (Burma)',
            },
            {
                ab: 'NA',
                name: 'Namibia',
            },
            {
                ab: 'NR',
                name: 'Nauru',
            },
            {
                ab: 'NP',
                name: 'Nepal',
            },
            {
                ab: 'NL',
                name: 'Netherlands',
            },
            {
                ab: 'AN',
                name: 'Netherlands Antilles',
            },
            {
                ab: 'NC',
                name: 'New Caledonia',
            },
            {
                ab: 'NZ',
                name: 'New Zealand',
            },
            {
                ab: 'NI',
                name: 'Nicaragua',
            },
            {
                ab: 'NE',
                name: 'Niger',
            },
            {
                ab: 'NG',
                name: 'Nigeria',
            },
            {
                ab: 'NU',
                name: 'Niue',
            },
            {
                ab: 'NF',
                name: 'Norfolk Island',
            },
            {
                ab: 'MP',
                name: 'Northern Mariana Islands',
            },
            {
                ab: 'NO',
                name: 'Norway',
            },
            {
                ab: 'OM',
                name: 'Oman',
            },
            {
                ab: 'PK',
                name: 'Pakistan',
            },
            {
                ab: 'PW',
                name: 'Palau',
            },
            {
                ab: 'PA',
                name: 'Panama',
            },
            {
                ab: 'PG',
                name: 'Papua New Guinea',
            },
            {
                ab: 'PY',
                name: 'Paraguay',
            },
            {
                ab: 'PE',
                name: 'Peru',
            },
            {
                ab: 'PH',
                name: 'Philippines',
            },
            {
                ab: 'PN',
                name: 'Pitcairn',
            },
            {
                ab: 'PL',
                name: 'Poland',
            },
            {
                ab: 'PT',
                name: 'Portugal',
            },
            {
                ab: 'QA',
                name: 'Qatar',
            },
            {
                ab: 'RE',
                name: 'Reunion',
            },
            {
                ab: 'RO',
                name: 'Romania',
            },
            {
                ab: 'RU',
                name: 'Russian Federation',
            },
            {
                ab: 'RW',
                name: 'Rwanda',
            },
            {
                ab: 'KN',
                name: 'Saint Ketts And Nevis',
            },
            {
                ab: 'LC',
                name: 'Saint Lucia',
            },
            {
                ab: 'VC',
                name: 'Saint Vincent and the Grenadines',
            },
            {
                ab: 'WS',
                name: 'Samoa',
            },
            {
                ab: 'SM',
                name: 'San Marino',
            },
            {
                ab: 'ST',
                name: 'Sao Tome and Principe',
            },
            {
                ab: 'SA',
                name: 'Saudi Arabia',
            },
            {
                ab: 'SN',
                name: 'Senegal',
            },
            {
                ab: 'RS',
                name: 'Serbia',
            },
            {
                ab: 'SC',
                name: 'Seychelles',
            },
            {
                ab: 'SL',
                name: 'Sierra Leone',
            },
            {
                ab: 'SG',
                name: 'Singapore',
            },
            {
                ab: 'SK',
                name: 'Solvakia (Solvak Republic)',
            },
            {
                ab: 'SI',
                name: 'Slovenia',
            },
            {
                ab: 'SB',
                name: 'Soloman Islands',
            },
            {
                ab: 'SO',
                name: 'Somalia',
            },
            {
                ab: 'ZA',
                name: 'South Africa',
            },
            {
                ab: 'GS',
                name: 'South Georgia and South Sandwich Isls',
            },
            {
                ab: 'ES',
                name: 'Spain',
            },
            {
                ab: 'LK',
                name: 'Sri Lanka',
            },
            {
                ab: 'SH',
                name: 'St. Helena',
            },
            {
                ab: 'PM',
                name: 'St. Pierra and Miquelon',
            },
            {
                ab: 'SD',
                name: 'Sudan',
            },
            {
                ab: 'SR',
                name: 'Suriname',
            },
            {
                ab: 'SJ',
                name: 'Svalbard and Jan Mayen Isls',
            },
            {
                ab: 'SZ',
                name: 'Swaziland',
            },
            {
                ab: 'SE',
                name: 'Sweden',
            },
            {
                ab: 'CH',
                name: 'Switzerland',
            },
            {
                ab: 'SY',
                name: 'Syrian Arab Republic',
            },
            {
                ab: 'TW',
                name: 'Taiwan, Republic of',
            },
            {
                ab: 'TJ',
                name: 'Tajikistan',
            },
            {
                ab: 'TZ',
                name: 'Tanzania, United Republic of',
            },
            {
                ab: 'TH',
                name: 'Thailand',
            },
            {
                ab: 'TG',
                name: 'Togo',
            },
            {
                ab: 'TK',
                name: 'Tokelau',
            },
            {
                ab: 'TO',
                name: 'Tonga',
            },
            {
                ab: 'TT',
                name: 'Trinidad and Tobago',
            },
            {
                ab: 'TN',
                name: 'Tunisia',
            },
            {
                ab: 'TR',
                name: 'Turkey',
            },
            {
                ab: 'TM',
                name: 'Turmenistan',
            },
            {
                ab: 'TC',
                name: 'Turks and Caicos Islands',
            },
            {
                ab: 'TV',
                name: 'Tuvalu',
            },
            {
                ab: 'UG',
                name: 'Uganda',
            },
            {
                ab: 'UA',
                name: 'Ukraine',
            },
            {
                ab: 'AE',
                name: 'United Arab Emirates',
            },
            {
                ab: 'GB',
                name: 'United Kingdom',
            },
            {
                ab: 'UM',
                name: 'United States Outlying Isls',
            },
            {
                ab: 'UY',
                name: 'Uruguay',
            },
            {
                ab: 'UZ',
                name: 'Uzbekistan',
            },
            {
                ab: 'VU',
                name: 'Vanuatu',
            },
            {
                ab: 'VA',
                name: 'Vatican City State (Holy See)',
            },
            {
                ab: 'VE',
                name: 'Venezuela',
            },
            {
                ab: 'VN',
                name: 'Viet nam',
            },
            {
                ab: 'VG',
                name: 'Virgin Islands (British)',
            },
            {
                ab: 'VI',
                name: 'Virgin Islands (U.S.)',
            },
            {
                ab: 'WF',
                name: 'Wallis and Futuna Islands',
            },
            {
                ab: 'EH',
                name: 'Western Sahara',
            },
            {
                ab: 'YE',
                name: 'Yemen',
            },
            {
                ab: 'YU',
                name: 'Yugoslavia',
            },
            {
                ab: 'ZR',
                name: 'Zaire',
            },
            {
                ab: 'ZM',
                name: 'Zambia',
            },
            {
                ab: 'ZW',
                name: 'Zimbabwe',
            },
        ],
    },
};
export enum BreakpointsLib {
    SmallMobile = '(max-width: 599.98px)',
    MediumMobile = '(max-width: 800px)',
    SmallTablet = '(min-width: 600px) and (max-width: 959.98px)',
    LargeTablet = '(min-width: 960px) and (max-width: 1279.98px)',
    SmallDesktop = '(min-width: 1280px) and (max-width: 1919.98px)',
    LargeDesktop = '(min-width: 1920px)',
}
