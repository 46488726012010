import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {PageLoadService} from '../page-load.service';
import {environment} from '../../../../../../../environments/environment';

@Component({
    selector: 'lib-page-load',
    templateUrl: './page-load.component.html',
    styleUrls: ['./page-load.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PageLoadComponent implements OnInit {
    progress = true;
    ev = environment
    @Input() website:any | {styleTkp:boolean} = {styleTkp:false};
    constructor(public pageLoadService: PageLoadService) {
    }




    ngOnInit(): void {
        this.pageLoadService._load.subscribe(value => {
            console.log();
            this.progress = value;
        });
    }


}
