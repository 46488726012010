import {Injectable} from '@angular/core';
import {forkJoin, lastValueFrom, Observable, of} from 'rxjs';

import {HttpClient, HttpParams} from '@angular/common/http';
import {SubdomainService} from './subdomain.service';
import {ConfigWebsite} from '../../models/config-website';
import {SessionRedisService} from './session-redis.service';
import {ExactInteracFormResponse} from '../../models/exact-interac-form-response';
import {map, mergeMap} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class WebsiteSaleService {
    configWebsite: ConfigWebsite;
    surveyWebsiteActivate=['sepaq']
    constructor(private http: HttpClient, private subdomainService: SubdomainService,
                private sessionRedisService: SessionRedisService) {
    }

    /**
     * @deprecated use getConfigWeb function
     * **/
    getConfigWebsite(): Observable<any> {
        //  return this.http.get<any>('backend/v1/websites/' + this.subdomainService.getSubdomain())

        return this.http.get<any>('v1/websites/config')
            .pipe(
                map(ws => {
                    // ws.clientAccount = 'NO';
                    this.configWebsite = ws;
                    if (this.subdomainService.getSubdomain() === 'sepaq') {
                        this.configWebsite.uniqueHolderSurvey = true;
                    }
                    try {
                        if( this.subdomainService.getSubdomain()?.search('tpro') !== -1){
                            this.configWebsite.styleTkp = true;

                        }
                    }catch (e) {
                        console.log('error', e);
                    }


                    return ws;
                })
            );
    }

    async getConfigWeb() {
        await lastValueFrom(this.getConfigWebsite());
        await lastValueFrom(this.sessionRedisService.getXSessionToken());
        return 'ok';
    }

    getLocalConfig() {
        return this.configWebsite;
    }

    getInteracConfig(client, amount): Observable<ExactInteracFormResponse> {
        //  return this.http.get<any>('backend/v1/websites/' + this.subdomainService.getSubdomain())
        const ht = new HttpParams().set('clientId', client).set('amount', String(amount)).set('cache', '30000');
        return this.http.get<ExactInteracFormResponse>('v1/interac/exact-url', {params: ht});

    }

    utf8_to_b64(str) {
        return window.btoa(unescape(encodeURIComponent(str)));
    }

    b64_to_utf8(str) {
        if (!str) {
            return null;
        }
        return decodeURIComponent(escape(window.atob(str)));
    }

    getLang() {
        const url = window.location.pathname;
        if (url.search('/fr/') !== -1) {
            return 'fr';
        }
        return 'en';
    }

}
