import {
    Component,
    ContentChild,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatCard, MatCardContent } from '@angular/material/card';
import {
    CurrencyPipe,
    JsonPipe,
    NgClass, NgOptimizedImage,
    NgStyle,
    NgTemplateOutlet
} from '@angular/common';
import { SeatsSelected } from '../../../../../../projects/shared-lib/src/lib/models/seats-selected';
import { SeatMapService } from '../../../../../../projects/shared-lib/src/lib/component/seats-map/seat-map.service';
import { MatFormField } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { PipeModule } from '../../../../../../projects/shared-lib/src/lib/pipe/pipe.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';
import { SyosSeat } from '../../../../../../projects/shared-lib/src/lib/models/syos-seat';
import { MatDialog } from '@angular/material/dialog';
import videojs from 'video.js';
import any = videojs.any;
import { ItemHour } from '../../../../models/item-hour';
import {
    MatButton,
    MatIconButton,
    MatMiniFabButton,
} from '@angular/material/button';
import {
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatBadge } from '@angular/material/badge';
import {
    changeValueIfIsEmpty,
    cloneObjectHelps,
    isEmpty,
} from '../../../../../../projects/shared-lib/src/lib/utils/utilsShared';
import { TemplatePriceComponent } from '../../../../templates/template-price/template-price.component';

@Component({
    selector: 'tw-seats-selectes-plv',
    standalone: true,
    imports: [
        MatDivider,
        MatIcon,
        MatCard,
        NgStyle,
        JsonPipe,
        NgTemplateOutlet,
        MatFormField,
        MatOption,
        MatSelect,
        FormsModule,
        PipeModule,
        CurrencyPipe,
        TranslateModule,
        MatTooltip,
        NgClass,
        MatCardContent,
        MatMiniFabButton,
        MatIconButton,
        MatButton,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelDescription,
        MatExpansionPanelTitle,
        MatExpansionPanelHeader,
        MatBadge,
        TemplatePriceComponent,
        NgOptimizedImage,
    ],
    templateUrl: './seats-selectes-plv.component.html',
    styleUrl: './seats-selectes-plv.component.css',
    encapsulation: ViewEncapsulation.None,
})
export class SeatsSelectesPlvComponent implements OnInit, OnDestroy {
    @Input() nbSeatGroup: SeatsSelected[] = [];
    nbSeat: SyosSeat[];
    @Input() templateName: 'seatsSelected' | 'stepSelect' | '' =
        'seatsSelected';
    nbStep = 2;
    _sub = [];
    @Input() itemHour: ItemHour;
    @Input() eventId: any;
    @Input() splitPrices = true;
    @Input() bestsSelect = true;
    @Input() showListPrice = true;
    @Input() showStepper = true;
    @Input() showSeatsSelected = true;
    @Input() seatService: SeatMapService;
    @Input() showDropDownMultiSelectSeat = false;
    @ViewChild('dialogTemplateHelpSelectQty', { read: TemplateRef })
    dialogTemplateHelpSelectQty: TemplateRef<any>;
    id: any;
    @Input() showDivider = true;
    // @ContentChild('removeSeats') refRemoveSeats: any;
    // hasRemoveSeatsContent = false;
    @Input() hasOptionRemoveSeats = true;
    miniNbStep = 0;

    constructor(
        public _seatService: SeatMapService,
        public translate: TranslateService,
        public dialog: MatDialog,
        public seatMapService: SeatMapService,
    ) {
        this.seatService = _seatService;
    }

    hoverSeatChange(sg: any) {}

    hoverLeveSeatChange(sg: any) {}

    removeSeats($event: MouseEvent, sg: any) {
        $event.stopPropagation();
        // this.seatService.removeSubSeatSelected(sg, this.itemHour.eventId);
        console.log(sg);
        this.seatService.subSeatSelectedToRemove.next({
            eventdId: this.itemHour.eventId,
            seats: [sg],
        });
    }

    ngOnInit(): void {
        //this.hasRemoveSeatsContent = !!this.removeSeats;
        this.id = this.itemHour?.eventId || this.eventId;
        this.nbStep = this.seatService.subQtyBySelecte.value;

        const b = this.seatService.subQtyBySelecte.subscribe((value) => {
            this.nbStep = value;
        });
        if (this.nbSeatGroup?.length == 0) {
            this.nbSeat = this.seatService.getSubSeatSelected();

            this.nbSeatGroup = this.seatService.getGroupSeatSelected();
            console.log(this.nbSeatGroup);
            const c = this.seatService.subSeatSelected
                .asObservable()
                .subscribe((value: SeatsSelected[]) => {
                    this.nbSeat = this.seatService.getSubSeatSelected();
                    this.nbSeatGroup = this.seatService.getGroupSeatSelected();
                    this.formatNgGroupSeat();
                });

            this._sub.push(c);
            this._sub.push(b);
        }
        this.formatNgGroupSeat();
    }

    ngStepChange($event: any) {
        this.seatService.subQtyBySelecte.next(this.nbStep);
    }
    formatNgGroupSeat() {
        if (this.nbSeatGroup?.length > 0) {
            this.nbSeatGroup.forEach((value) => {
                value.data.forEach((value1) => {
                    value1.levelName = changeValueIfIsEmpty(value1.levelName);
                    value1.sectionName = changeValueIfIsEmpty(
                        value1.sectionName,
                    );
                    value1.rowName = changeValueIfIsEmpty(value1.rowName);
                    value1.name = changeValueIfIsEmpty(value1.name);
                });
            });
            const temp = cloneObjectHelps(this.nbSeatGroup);
            console.log(temp);
            this.nbSeatGroup = [];
            setTimeout(() => {
                this.nbSeatGroup = temp;
            }, 200);
        }
    }

    ngOnDestroy(): void {
        this._sub.forEach((value) => {
            value.unsubscribe();
        });
    }

    getPageId() {
        return '';
    }

    shoHelps() {
        this.dialog.open(this.dialogTemplateHelpSelectQty, {
            width: '400px',
        });
    }
}
